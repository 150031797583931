import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  constructor( private renderer: Renderer2, public loaderService: LoaderService) {}

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'overflow-hidden');
  }
  ngOnDestroy(): void{
    this.renderer.removeClass(document.body, 'overflow-hidden');
  }
}

