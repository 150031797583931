import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public isLoading = new BehaviorSubject<boolean | false>(false);
  private message = new BehaviorSubject<string>('Cargando...');
  constructor() { }
  loadingStatus(value: boolean){
    return this.isLoading.next(value);
  }
  setLoader(value: boolean, message?: string){
    if (message) this.message.next(message);
    else this.message.next("Cargando...")
    return this.isLoading.next(value);
  }
  get getMessage(){
    return this.message.getValue()
  }
}
