import { Component, OnInit, ɵbypassSanitizationTrustStyle } from '@angular/core';
import { Router,NavigationStart, Event as NavigationEvent } from '@angular/router';
import { CAT_FRAGMENT_ID } from 'src/app/constants/search-word.const';
import { LoaderService } from 'src/app/services/loader/loader.service';
import SwiperCore, { Navigation, Autoplay } from 'swiper';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {
  readonly IDS = CAT_FRAGMENT_ID;

marginAboutUs: any;

  constructor(private router: Router,
      private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.loaderService.isLoading.next(true);
    setTimeout(() => {
      this.loaderService.isLoading.next(false);
    }, 1000);
  
   }
   swiperAux?: SwiperCore;
  swiperConfig: any = {
    allowTouchMove: true,
    initialSlide: 0,
    loopAdditionalSlides: 0,
    slideToClickedSlide: true,
    centeredSlides: true,
    slidesPerView: 1.2,
    loop: false,
    breakpoints: {
      992: {
        slidesPerView: 2
      },
      768: {
        slidesPerView: 2
      },
      576: {
        slidesPerView: 1.3
      },
      375: {
        slidesPerView: 1.3
      }
    }
  }

  misions = [
    {
      title: 'Objetivo',
      texto: 'Impulsar a nuestros clientes y asociados para que logren sus metas estratégicas de negocio, incrementando su competitividad tecnológica.',
      img: '../../../assets/mp4/Objetivo2.mp4'
    },
    {
      title: 'Misión',
      texto: 'Brindar soluciones tecnológicas y metas alineadas a los objetivos estratégicos de nuestros clientes.',
      img: '../../../assets/mp4/Mision.mp4'
    },
    {
      title: 'Visión',
      texto: 'Ser un grupo de negocio reconocido a nivel mundial por ayudar a lograr los planes de negocio de todos nuestros clientes mediante soluciones de valor.',
      img: '../../../assets/mp4/Vision2.mp4'
    },
    {
      title: 'Ética',
      texto: 'A través de nuestra ética profesional, reconocemos, valoramos, fomentamos y compartimos las mejores prácticas en nuestros servicios.',
      img: '../../../assets/mp4/Etica.mp4'
    }
  ]


  icons = [
    {
      icon: '../../../assets/images/gif/aboutUs/Empatizar.gif',
      color: 'color: #1a54d8',
      text: 'Empatizar',
      classIcon: 'icon cssToolTip', 
      showAnimation: false,
      texTool: 'Investiga sobre las necesidades de los usuarios.' 
    },
    {
      icon: '../../../assets/images/gif/aboutUs/Definir-con-alfa.gif',
      color: 'color: #7028ce',
      text: 'Definir',
      classIcon: 'icon-1 cssToolTip',
      showAnimation: false,
      texTool: 'Define las necesidades  y problemas de los usuarios.'
    },
    {
      icon: '../../../assets/images/gif/aboutUs/idear-con-alfa.gif',
      color: 'color: #cb0d6d',
      text: 'Idear',
      classIcon: 'icon-2 cssToolTip',
      showAnimation: false,
      texTool: 'Cuestiona las suposiciones y crea ideas.'
    },
    {
      icon: '../../../assets/images/gif/aboutUs/Prototipar-con-alfa.gif',
      color: 'color: #eb5239',
      text: 'Prototipar',
      classIcon: 'icon-3  cssToolTip',
      showAnimation: false,
      texTool: 'Crea soluciones mediante prototipos.'
    },
    {
      icon: '../../../assets/images/gif/aboutUs/DevOps_1.gif',
      color: ' color:#7cca62',
      text: 'DevOps',
      classIcon: ' cssToolTip-dev widthDev',
      texTool: 'Prácticas agrupadas de desarrollo de software y operaciones de  TI.'
    },
    {
      icon: '../../../assets/images/gif/aboutUs/test-and-learn-con-alfa.gif',
      color: 'color: #ffad00',
      text: 'Test and Learn',
      classIcon: 'icon-4 cssToolTip',
      showAnimation: false,
      texTool: 'Prueba tus soluciones para redefinir, o no, el problema.'
    }
  ]

 goToHome(){
    this.router.navigate(['/inicio'])
 }

}
