// Identificador de las diferentes secciones del sitio. ej. id="#customers"
export enum TOWER_SERVICES_ID {
    SOLUTIONS_DEVELOPMENT = 'SOLUTIONS_DEVELOPMENT',
    SPECIALIZED_SERVICES = 'SPECIALIZED_SERVICES',
    NORMALIZATTION_PROCESS = 'NORMALIZATTION_PROCESS',
    ENTERPRISE_SOLUTIONS = 'ENTERPRISE_SOLUTIONS',
    INSTITUTE = 'INSTITUTE',
    TALENTS = 'TALENTS',
}

export const TOWER_SERVICES = [
    {
        title: 'Solutions​ Development​',
        description: 'Vamos más allá de una fábrica de software, buscamos el diseño de nuevas experiencias disruptivas de negocio.',
        redirectTo: 'SOLUTIONS_DEVELOPMENT',
        icon: '<span class="icon-Solutions-Development"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>'
    },
    {
        title: 'Specialized​ Services​',
        description: 'Brindamos el mejor soporte mediante el análisis, diseño y desarrollo, ya sea en un enfoque ágil, en cascada o hibrido asignando especialistas a sus equipos de trabajo interno, por un tiempo determinado definido por nuestros clientes.',
        redirectTo: 'SPECIALIZED_SERVICES',
        icon: '<span class="icon-Specialized-Services"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>'
    },
    {
        title: 'Normativity & Processes',
        description: 'Organizamos y estructuramos toda la información interna y externa de la empresa mediante procesos necesarios para una mejor ejecución.',
        redirectTo: 'NORMALIZATTION_PROCESS',
        icon: '<span class="icon-Normalization--Processes"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>'
    },
    {
        title: 'Enterprise Solutions',
        description: 'En esta torre de servicio se centralizan las alianzas con nuestros asociados tecnológicos y de procesos, ofreciendo un abanico amplio de soluciones necesarias en una transformación digital.',
        redirectTo: 'ENTERPRISE_SOLUTIONS',
        icon: '<span class="icon-Enterprise-Solutions"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>'
    },
    {
        title: '2BInstitute',
        description: 'Nos encargamos de capacitar, incubar y desarrollartalento humano en las disciplinas relacionadas con la transformación tigital y el crecimiento profesional.',
        redirectTo: 'INSTITUTE',
        icon: '<span class="icon-BInstitute"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>'
    },
    {
        title: '2BTalents',
        description: 'Contamos con la mejor plataforma digital para administrar y gestionar tus procesos RRHH de manera más eficiente.',
        redirectTo: 'TALENTS',
        icon: '<span class="icon-BTalents"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>'
    }
];


export const SERVICES_DESCRIPTION = {
    ENTERPRISE_SOLUTIONS: {
        title: 'Enterprise Solutions',
        description: 'En esta torre de servicio se centralizan las alianzas con nuestros asociados tecnológicos y de procesos, ofreciendo un abanico amplio de soluciones necesarias en una transformación digital, como son: Soluciones de Nube, Virtualización, Automatización, Inteligencia Artificial y Cognitiva, IoT, B2B, CRM, ERP, Big Data, Inteligencia de Negocios, Seguridad Informática, Soluciones Móviles, Tecnologías API, Microservicios, Estandarización y Procesos.',
        services: [
            {
               active: false,
                name: 'Salesforce Solutions​',
                description: 'Funciona como una excelente solución para la gestión de relaciones con clientes que logra unir a empresas y clientes potenciales. Es una plataforma CRM integrada que brinda un gran apoyo entre el cliente y todos los departamentos internos, incluidos marketing, ventas, comercio y servicios, presentando una vista única y compartida para cada cliente.'
            },
            {
               active: false,
                name: 'SAP Services​​',
                description: 'Implementación como herramienta de gran utilidad para empresas y organizaciones de todos los tamaños e industrias SAP, que nos ayuda a gestionar sus negocios de manera rentable, logrando adaptarse continuamente y a crecer de manera sostenible, dándoles a los empleados de diferentes departamentos un acceso fácil a la información de manera concreta y una buena comunicación interna en las empresas.'
            },
            {
               active: false,
                name: 'Business Solutions​',
                description: 'Logramos potencializar tu empresa mediante el desarrollo y diseño de soluciones en tecnología y transformación digital, ya que contamos con el mejor equipo de especialistas altamente capacitados, que ayudarán a controlar y automatizar cada proceso necesario para cumplir las necesidades internas de la empresa, mediante alguna plataforma digital, logrando facilitar y gestionar la relación y satisfacción con los clientes brindándote la mejor solución digital empresarial.'
            },
            {
               active: false,
                name: 'Cloud Solutions​',
                description: 'Contamos con la mayor tecnología en tendencia global para la implementación y funcionalidad en gran capacidad en almacenamiento de información empresarial, brindando soluciones de Salesforce y MYPE para Front Office y Back Office con un software estandarizado. Beneficiando y obteniendo el máximo rendimiento y capacidades según las necesidades de la empresa. Garantizando la confiabilidad de la información mediante protocolos de alta seguridad que contemplan la privacidad de datos e información de la empresa.'
            }
        ]
    },
    SOLUTIONS_DEVELOPMENT: {
        title: 'Solutions​ Development​​',
        description: 'Cuando nuestros clientes requieren una solución de software web, móvil, de escritorio o una integración con APIs o microservicios diseñada y desarrollada a la medida, puede entrar en acción esta torre de servicio, gracias a nuestra metodología de diseño y desarrollo de soluciones en un modelo ágil considerando desde la experiencia UX/UI para el diseño de la solución hasta el desarrollo de soluciones con una cultura ágil y automatizada por medio de prácticas de integración continua CI y entrega continua CD.',
        services: [
            {
               active: false,
                name: 'CX & UX Services',
                description: 'Para nosotros la experiencia del cliente (Customer Experience)  y la experiencia del usuario (User Experience) son de gran importancia, es por ello que diseñamos y desarrollamos todas las técnicas, herramientas digitales, procesos o acciones que están orientadas a proporcionar experiencias diferenciadas a la implementación de estrategias que favorezcan las interacciones con los clientes en los sitios web y apps, así como una excelente experiencia de navegación que permita cumplir las expectativas en nuestros clientes y en consecuencia, aumentar su satisfacción y fidelidad.'
            },
            {
               active: false,
                name: 'Web Portal/Mobile solutions​',
                description: '  <span>Innovamos soluciones web y móviles, integrando, simplificando y optimizando las necesidades tecnológicas de cada empresa, para gestionar los diferentes tipos de dispositivos utilizados para uso personal o corporativos, simplificando la administración de los dispositivos mientras están en movimiento, permitiendo controlar las aplicaciones que se instalan o eliminan, logrando configurar los ajustes básicos en los dispositivos que se utilizarán para fines específicos. <br> <br> Es posible incorporar rápida y fácilmente a los dispositivos masivamente, sin necesidad de que el usuario realice alguna acción. La movilidad ayuda a las empresas a aprovechar los datos contextuales y gráficos, para llegar a sus clientes y empleados con mayor eficacia.</span>'
            },
            {
               active: false,
                name: 'Robotics & IA Solutions​​',
                description: '<span>Actualmente, la inteligencia artificial forma parte de nuestra vida cotidiana. El desarrollo y disponibilidad de sistemas informáticos capaces de procesar todos esos datos de una manera más rápida y precisa de lo que un humano sería capaz, nos permite crear experiencias más personalizadas para los clientes, para elegir las decisiones más adecuadas e inteligentes basadas en insights de alto valor generados a partir de los datos. <br><br> Gracias a la transformación digital desarrollada e integrada, permite a nuestros ingenieros altamente capacitados, desarrollar los sistemas robóticos más inteligentes y sofisticados. Los avances en IA impulsan el uso del Big Data, debido a su habilidad para procesar grandes cantidades de datos y proporcionar ventajas comunicacionales, comerciales y empresariales que la han llevado a posicionarse como la tecnología más esencial del futuro.</span>'
            }
        ]
    },
    SPECIALIZED_SERVICES: {
        title: 'Specialized​ Services​​',
        description: 'Ofrecemos funciones de acceso a internet que están optimizadas para contenidos, aplicaciones o servicios específicos, o una combinación de estos, generando la optimización necesaria para cumplir los requisitos de los contenidos, aplicación o servicios para un nivel de alta calidad en un área en específico.',
        services: [
            {
               active: false,
                name: 'Specialized IT Services​​',
                description: '<span>Nuestros servicios en transformación digital ofrecen soluciones especializadas orientadas a la tecnología, combinando procesos y funciones de software, hardware, redes, telecomunicaciones y electrónica, así como consultoría especializada en el sector bancario y financiero, consultoría en productos IBM®, Oracle®, SAP® y Productos Microsoft®. <br><br> Con esto, permitimos a las organizaciones la creación, gestión y optimización de la información y los procesos empresariales o el acceso a ello, mediante el diseño, construcción y ejecución. El objetivo es mejorar la comunicación y la colaboración dentro de una organización.<br><br> La gestión de estos servicios es un desafío continuo y los clientes esperan que las empresas estén a la altura de las nuevas tecnologías. Las empresas dependen de estos servicios ofrecidos en tecnología para coordinar de manera efectiva estas innumerables tareas y procesos, al tiempo que se aseguran de entregar un valor real al cliente.</span>'
            },
            {
               active: false,
                name: 'Staff Augmentation',
                description: '<span>Una de las tendencias más significativas de las empresas hoy en día, es el espectacular aumento del uso de colaboradores eventuales en las organizaciones de todo el mundo. <br><br> En 2BCORE, nos caracterizamos por contar con especialistas de gran experiencia para la selección de personal a detalle, con habilidades desarrolladas y especializadas, conectando a empresas nacionales e internacionales a distancia, y así, reducir los costos de funcionamiento, manteniendo la productividad al máximo.</span>'
            }
        ]
        },
    NORMALIZATTION_PROCESS: {
        title: 'Normativity & Processes​​',
        description: 'El primer objetivo, durante la normalización y de los procesos de la información empresarial, es detectar y eliminar todas las anomalías y generar un mayor rendimiento. Nuestro propósito es organizar los datos e información dentro de una empresa de manera estructurada y sistematizada. Es importante porque los datos se visualizan con mayor facilidad, y es posible extraer y actualizar información de manera rápida y eficiente. De igual manera, se reduce el riesgo de producir errores y duplicidades, y que se desorganice aún más la información delicada interna.',
        services: [
            {
               active: false,
                name: 'Process Analysis & Improvement​',
                description: 'La mejora de los procesos y desarrollo empresarial implica la identificación, análisis y mejora de los procesos existentes, para optimizar su rendimiento. Nosotros ayudamos a mejorar los métodos, integrando buenas prácticas y mejorando la calidad de los procesos en su organización, enfocándolos en mejorar la experiencia y rentabilidad de los clientes y usuarios finales. El dominio y perfeccionamiento del análisis y mejora de los procesos, potencia el desarrollo empresarial en ámbitos como la gestión de procesos, la transformación digital y el cumplimiento de las necesidades más importantes de su empresa.'
            },
            {
               active: false,
                name: 'ISO Certification​​',
                description: 'La ISO (Organización Internacional de Normalización) es una organización internacional independiente y no gubernamental, que desarrolla normas para sistemas de gestión que permiten la adopción de buenas prácticas en ámbitos como la calidad, la seguridad de la información, el medioambiente, la seguridad y salud en el trabajo, y la inocuidad alimentaria, entre otros. <br><br> Las Normas ISO son requeridas para participar como proveedor en diferentes cadenas de suministro en el ámbito público y privado debido a que garantizan la adopción de buenas prácticas que luego son validadas por un organismo evaluador independiente. Debido a lo anterior, facilitan el comercio de productos y servicios entre empresas, y otorga ventajas competitivas a las empresas que certifican su adopción al convertirse en proveedores más confiables para sus clientes actuales y potenciales, lo que les permite participar en nuevos mercados y crecer en los que ya atienden. <br><br> Contamos con la experiencia, estrategias y herramientas, para implementar que los sistemas de gestión con nuestros clientes se realicen de forma sencilla y efectiva, garantizando el cumplimiento de los requisitos de la norma bajo la cual se certifica. '
            },
            {
               active: false,
                name: 'Compliance Consulting',
                description: 'Somos consultores expertos. Trabajamos para asegurarnos de que una empresa u organización, cumpla con las normas o reglamentos gubernamentales. Nuestras responsabilidades pueden incluir la identificación de problemas de cumplimiento, el desarrollo de métodos para reducir el riesgo, la formación de los empleados y la realización de trámites o informes para una agencia reguladora.'
            }
        ]
        },
   
    INSTITUTE: {
        title: '2BInstitute',
        description: 'En 2BCORE, estamos enfocados principalmente en la transformación digital, teniendo como objetivo, desarrollar talento y esfuerzo humano en las disciplinas relacionadas con el crecimiento profesional. Esto lo logramos mediante la capacitación especializada, crecimiento e incubación de profesionistas, logrando así, el éxito de grandes especialistas con amplios conocimientos, para potencializar y optimizar el rendimiento máximo de tu compañía.',
        services: [
            {
               active: false,
                name: 'LMS Platform',
                description: 'Contamos con una plataforma de aprendizaje E-Learning, donde encontrarás cursos y certificaciones impartidos por profesionales, así como la personalización, diseño, digitalización e implementación de contenido para la formación del personal.'
            },
            {
               active: false,
                name: 'Training and​ incubation​​',
                description: 'Apoyamos a nuestros socios comerciales con la capacitación e incubación de talento especializado, ayudando a desarrollar las habilidades técnicas y personales que necesitan para su negocio.'
            }, 
            {
               active: false,
                name: 'Certifications and diplomas​​',
                description: 'Nos anticipamos a las nuevas tendencias, certificando y capacitando al personal a través de nuestra plataforma educativa, donde podrán impulsar su crecimiento y desempeño continuo, mejorando su competitividad y eficiencia laboral.'
            },
            {
               active: false,
                name: 'Coaching​',
                description: 'Impulsamos el desarrollo del talento, incrementamos sus habilidades personales y profesionales, brindándoles capacitación y orientación para mejorar su rendimiento.'
            }
        ]
         },
    TALENTS: {
        title: '2BTalents',
        description: 'Contamos con una plataforma digital óptima e interactiva, para lograr administrar y gestionar tus procesos de RH de manera más sencilla, brindándote un mayor rendimiento en el desempeño de las tareas relacionadas con el departamento de RH. Obtendrás tu propio canal de comunicación interna, medición de clima laboral, aplicación de las guías de la NOM-035 y su análisis, buzón de quejas y sugerencias, y un módulo especializado para gestionar los eventos de tu empresa.',
        services: [
            {
               active: false,
                name: 'Recruitment and selection​​',
                description: 'Tenemos la responsabilidad con nuestros socios comerciales de proporcionarles las mejores estrategias para encontrar, validar y verificar las cualidades de los candidatos durante el proceso de reclutamiento y selección, asegurando que cuenten con el personal ideal con base en sus necesidades.'
            },
            {
               active: false,
                name: 'Psychometry',
                description: 'Implementamos las pruebas necesarias para el reclutamiento y selección de personal, útiles para definir planes de carrera, detectar necesidades de capacitación o identificar áreas de oportunidad.'
            },
            {
               active: false,
                name: 'Socioeconomic',
                description: 'Llevamos a cabo pruebas inteligentes y claras diseñadas para verificar a detalle el entorno de los candidatos, asegurándonos de la autenticidad de la información proporcionada, con investigaciones profundas y sólidas.'
            },
            {
               active: false,
                name: 'Career plan​​',
                description: 'Impulsamos el desarrollo profesional, a través de diferentes planes de carrera elaborados a partir del análisis de conocimientos, habilidades, características personales y experiencia para que el talento crezca dentro de la organización.'
            },
            {
               active: false,
                name: 'Labor climate and risk',
                description: 'Contamos con una serie de pruebas de acuerdo al perfil del candidato a seleccionar, con la finalidad de elegir al que cubra con las competencias que los socios comerciales requieran.'
            }
        ]
     }
};


export const TOWER_SERVICES_ENG = [
    {
        title: 'Solutions​ Development​',
        description: 'We go beyond a software factory, we seek to design new disruptive business experiences.',
        redirectTo: 'SOLUTIONS_DEVELOPMENT',
        icon: '<span class="icon-Solutions-Development"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>'
    },
    {
        title: 'Specialized​ Services​',
        description: 'We provide the greatest support through analysis, design and development, either in an agile, waterfall or hybrid approach, assigning specialists to their internal work teams, for a determined time defined by our clients.',
        redirectTo: 'SPECIALIZED_SERVICES',
        icon: '<span class="icon-Specialized-Services"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>'
    },
    {
        title: 'Normativity & Processes​',
        description: 'We organize and structure all the internal and external information of the company through the necessary processes for a faster operation.',
        redirectTo: 'NORMALIZATTION_PROCESS',
        icon: '<span class="icon-Normalization--Processes"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>'
    },
    {
        title: 'Enterprise Solutions',
        description: 'We focus our alliances with our technology and process partners in this service tower, offering a wide range of solutions required in a digital transformation.',
        redirectTo: 'ENTERPRISE_SOLUTIONS',
        icon: '<span class="icon-Enterprise-Solutions"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>'
    },
    {
        title: '2BInstitute',
        description: 'We develop, incubate and prepare human talent in disciplines related to digital transformation and professional growth.',
        redirectTo: 'INSTITUTE',
        icon: '<span class="icon-BInstitute"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>'
    },
    {
        title: '2BTalents',
        description: 'We have the best digital platform to administer and manage your HR processes more efficiently.',
        redirectTo: 'TALENTS',
        icon: '<span class="icon-BTalents"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>'
    }
];


export const SERVICES_DESCRIPTION_ENG = {
    ENTERPRISE_SOLUTIONS: {
        title: 'Enterprise Solutions',
        description: 'This service tower centralizes alliances with our technology and process partners, offering a wide range of solutions needed in a digital transformation, such as: Cloud Solutions, Virtualization, Automation, Artificial and Cognitive Intelligence, IoT, B2B, CRM, ERP, Big Data, Business Intelligence, IT Security, Mobile Solutions, API Technologies, Microservices, Standardization and Processes.',
        services: [
            {
               active: false,
                name: 'Salesforce Solutions​',
                description: 'Works as an excellent customer relationship management solution that brings companies and customers closer. It is an integrated CRM platform that provides great support between the customer and all internal departments, including marketing, sales, commerce and service, presenting a single, shared view for each customer.'
            },
            {
               active: false,
                name: 'SAP Services​​',
                description: 'Implementation as a very useful tool for companies and organizations of all sizes and industries SAP, which helps  to manage their business in a profitable way, achieving continuous adaptation and sustainable growth, giving employees of different departments easy access to information in a concrete way and good internal communication in companies.'
            },
            {
               active: false,
                name: 'Business Solutions​',
                description: 'We manage to potentialize your company through the development and design of solutions of technology and digital transformation, we have the best team of highly trained level of specialists, who will help to control and automate each process necessary to meet the internal needs of the company, through a digital platform, achieving to facilitate and manage the relationship and satisfaction with customers by providing the best digital business solution.'
            },
            {
               active: false,
                name: 'Cloud Solutions​',
                description: 'Our company has the best technology in global trend for the implementation and functionality in large capacity in business information storage, providing Salesforce and MYPE solutions for Front Office and Back Office with a standardized software. Benefiting and obtaining the maximum performance and capabilities according to the needs of the company. We guarantee the reliability of the information by means of high security protocols that contemplate the privacy of data and information of the company.'
            }
        ]
    },
    SOLUTIONS_DEVELOPMENT: {
        title: 'Solutions​ Development​​',
        description: 'When our customers require a solution designed and developed for web, mobile, desktop software or an integration with APIs or microservices, this service tower can come into action, thanks to our methodology of design and development of solutions in an agile model considering from the UX/UI experience for the design of the solution to the development of solutions with an agile and automated culture through CI Continuous Integration and CD Continuous Delivery practices.',
        services: [
            {
               active: false,
                name: 'CX & UX Services',
                description: 'For us the customer experience (CX) and user experience (UX) are of great importance, which is why we design and develop all techniques, digital tools, processes or actions that are designed to provide differentiated experiences to the implementation of strategies that facilitate interactions with customers on websites and apps, as well as an excellent browsing experience that allows to meet the expectations of our customers and consequently increase their satisfaction and loyalty.'
            },
            {
               active: false,
                name: 'Web Portal/Mobile solutions​',
                description: '  <span>We innovate mobile and web solutions, integrating, simplifying and optimizing the technological needs of each company, to manage the different types of devices used for personal or corporate use, simplifying the administration of the devices while they are on the move, allowing to control the applications that are installed or deleted, managing to configure the basic settings on the devices to be used for specific purposes. Devices can be quickly and easily onboarded in mass, without requiring any user action. Mobility helps companies leverage contextual and graphical data to reach their customers and employees more effectively.</span>'
            },
            {
               active: false,
                name: 'Robotics & IA Solutions​​',
                description: '<span>Today, artificial intelligence is part of our daily lives. The development and availability of computer systems capable of processing all this data faster and more accurately than a human would be able to, allows us to create more personalized experiences for customers, to choose the most appropriate and intelligent decisions based on high-value insights generated from the data. Due to the developed and integrated digital transformation, it allows our highly skilled engineers to develop the most intelligent and sophisticated robotic systems. Advances in AI drive the use of Big Data, given the ability to process large amounts of data and provide communication, commercial and business advantages that have led it to position itself as the most essential technology of the future.</span>'
            }
        ]
    },
    SPECIALIZED_SERVICES: {
        title: 'Specialized​ Services​​',
        description: 'We offer Internet access functions that are optimized for specific content, applications or services, or a combination of these, generating the necessary optimization to meet the requirements of the content, application or services for a high quality level in a specific area.',
        services: [
            {
               active: false,
                name: 'Specialized IT Services​​',
                description: '<span>Our services in digital transformation offer specialized technology-oriented solutions, combining processes and functions of software, hardware, networks, telecommunications and electronics, as well as specialized consulting in the banking and financial sector, consulting in IBM®, Oracle®, SAP® and Microsoft® products. By doing this, we enable organizations to create, manage and optimize information and business processes or access to it, through design, construction and implementation. The goal is to improve communication and collaboration across the organization. The management of these services is an ongoing challenge and customers expect companies to keep up with new technologies. Companies depend on these technology-enabled services to effectively coordinate these myriad tasks and processes, while ensuring that they deliver real value to the customer.</span>'
            },
            {
               active: false,
                name: 'Staff Augmentation',
                description: '<span>One of the most significant trends in business today is the dramatic increase in the use of temporary employees in organizations around the world. 2BCORE, is known for having highly experienced specialists for the selection of personnel in detail, with developed and specialized skills, connecting national and international companies remotely, and thus, reducing operating costs, keeping productivity to the maximum.</span>'
            }
        ]
        },
    NORMALIZATTION_PROCESS: {
        title: 'Normativity & Processes',
        description: 'The first objective, during the normalization and standardization of business information processes, is to detect and eliminate all anomalies and generate higher performance. Our purpose is to organize data and information within a company in a structured and systematized way. It is important because data is more easily visualized, and it is possible to extract and update information quickly and efficiently. It also reduces the risk of errors and duplication, and further disorganization of sensitive internal information.',
        services: [
            {
               active: false,
                name: 'Process Analysis & Improvement​',
                description: 'Process improvement and business development involves identifying, analyzing and improving existing processes to optimize their performance. We help to improve methods, integrating best practices and improving the quality of processes in your organization, focusing on improving the experience and profitability of customers and end users. The mastery and improvement of process analysis and improvement, enhances business development in areas such as process management, digital transformation and satisfying the most important needs for your company.'
            },
            {
               active: false,
                name: 'ISO Certification​​',
                description: 'ISO (International Organization for Standardization) is an independent, non-governmental international organization that develops standards for management systems that enable the adoption of good practices in areas such as quality, information security, environment, occupational health and safety, and food safety, among others. <br><br> ISO standards are required to participate as a supplier in different public and private supply chains because they guarantee the adoption of good practices that are then validated by an independent evaluating body. Due to the above, they facilitate the trade of products and services between companies, and grant competitive advantages to the companies that certify their adoption by becoming more reliable suppliers for their current and potential customers, which allows them to participate in new markets and grow in the ones they already serve. <br><br> We have the experience, strategies and tools to implement management systems with our clients in a simple and effective way, ensuring compliance with the requirements of the standard under which it is certified.'
            },
            {
               active: false,
                name: 'Compliance Consulting',
                description: 'We are expert consultants. Our job is to ensure that a company, or organization, complies with government rules or regulations. Our responsibilities may include identifying compliance issues, developing methods to reduce risk, training employees, and completing paperwork or reports for a regulatory agency.'
            }
        ]
        },
   
    INSTITUTE: {
        title: '2BInstitute',
        description: '2BCORE, is mainly focused on digital transformation, with the objective of developing talent and human effort in the professional growth related disciplines. We achieve this through specialized training, growth and incubation of professionals, thereby achieving the success of great specialists with extensive knowledge, to enhance and optimize the maximum performance of your company.',
        services: [
            {
               active: false,
                name: 'LMS Platform',
                description: 'We have an E-Learning platform, where you will find courses and certifications provided by professionals, as well as the customization, design, digitization and implementation of content for staff training.'
            },
            {
               active: false,
                name: 'Training and​ incubation​​',
                description: 'We support our business partners with specialized talent with training and incubation, helping them develop the technical and personal skills they need for their business.'
            }, 
            {
               active: false,
                name: 'Certifications and diplomas​​',
                description: 'We stay one step ahead of new trends, certifying and training our personnel through our educational platform, where they can boost their continuous growth and performance, improving their competitiveness and work efficiency.'
            },
            {
               active: false,
                name: 'Coaching​',
                description: 'We promote the development of talent, increase their personal and professional skills, providing training and guidance to improve their performance.'
            }
        ]
         },
    TALENTS: {
        title: '2BTalents',
        description: 'We have a great and innovative digital platform to manage and administer your HR processes in a simpler way, giving you a better performance in the performance of the tasks related to the HR department. You will get your own internal communication channel, work environment measurement, application of NOM-035 guidelines and their analysis, complaints and suggestions mailbox, and a specialized module to manage your company`s events.',
        services: [
            {
               active: false,
                name: 'Recruitment and selection​​',
                description: 'We take full responsibility with our business partners to provide them with the best strategies to find, validate and verify the qualities of candidates during the recruitment and selection process, ensuring that they have the ideal personnel based on their needs.'
            },
            {
               active: false,
                name: 'Psychometry',
                description: 'We provide the necessary tests for recruitment and selection of personnel, useful for defining career plans, detecting training needs or identifying areas of opportunity.'
            },
            {
               active: false,
                name: 'Socioeconomic',
                description: 'We perform intelligent and clear tests designed to verify in detail the environment of the candidates, ensuring the authenticity of the information provided, with in-depth and solid research.'
            },
            {
               active: false,
                name: 'Career plan​​',
                description: 'We encourage professional development through different career plans based on the analysis of knowledge, skills, personal characteristics and experience so that talent can grow within the organization.'
            },
            {
               active: false,
                name: 'Labor climate and risk',
                description: 'We rely on a series of tests according to the profile of the candidate to be selected, in order to choose the one that meets the competencies required by the business partners.'
            }
        ]
     }
};









