<div class="container padding-mobile ps-md-0" style="margin-top: 90px" id="{{IDS.aboutUs}}">
  <div class="row">
    <div class="me-auto separate-back">
      <div class="d-flex align-items-center" (click)="goToHome()" style="cursor: pointer; width: fit-content">
        <span class="icon-Regresar me-2 fs-18" style="cursor: pointer"></span>
       <span class="d-none d-md-block fw-regular" style="line-height: 24px;"> {{ "back.btn" | translate }}</span>
      </div>
    </div>
  </div>
  <div class="row m-0">
   
    <div class="col-md-6 d-flex px-0 flex-column align-items-center">
      <div class="nosotros margin-section">
        <h1 class="body-title">{{ "aboutus.us.title" | translate }}</h1>
        <p class="body-text fw-regular">{{ "aboutus.us.description" | translate }}</p>
      </div>
    </div>
    <div class="col-md-6 nosotros-img d-flex justify-content-center m-auto">
      <video loop autoplay muted playsinline oncanplay="this.play()" onloadedmetadata="this.muted = true"
        class="nosotros-img">
        <source src="/assets/mp4/Nosotros (1).mp4" type="video/mp4" />
      </video>
    </div>
  </div>
</div>

<div class="container-fluid bg-gray">
  <div class="row">
    <div class="col-12">
      <div class="container">
        <div class="objectives">
          <div class="col-md-12 col-12 d-block d-lg-none">
            <div class="d-flex">
              <swiper [config]="swiperConfig" class="width-carrusel">
                <ng-template *ngFor="let mision of ('aboutus.misions' | translate)" swiperSlide>
                  <div class="d-flex justify-content-center">
                    <div class="card-objectives">
                      <div class="card-body h-100">
                        <div class="circle">
                          <video loop autoplay muted playsinline oncanplay="this.play()"
                            onloadedmetadata="this.muted = true" class="img-program">
                            <source [src]="mision.img" type="video/mp4" />
                          </video>
                        </div>
                        <h5 class="card-title">{{ mision.title }}</h5>
                        <p class="card-text">{{ mision.texto }}</p>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </swiper>
            </div>
          </div>
          <div class="row d-none d-lg-flex">
            <div class="col-3 padding-cards" *ngFor="let mision of ('aboutus.misions' | translate)" >
              <div class="d-flex justify-content-center">
                <div class="card-objectives">
                  <div class="card-body h-100">
                    <div class="circle">
                      <video loop autoplay muted playsinline oncanplay="this.play()"
                        onloadedmetadata="this.muted = true" class="img-program">
                        <source [src]="mision.img" type="video/mp4" />
                      </video>
                    </div>
                    <h5 class="card-title">{{ mision.title }}</h5>
                    <p class="card-text">{{ mision.texto }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" id="{{IDS.metodology}}">
  <div class="metodologia" id="metodologia">
    <div class="row path">
      <div class="col-md-12 col-12">
        <div class="header-metodologia">
          <h2 class="body-subtitle margin-custom">

            {{ "aboutus.metodology.title" | translate }}
          </h2>
          <p class="body-text text-custom">{{ "aboutus.metodology.text" | translate }}</p>
        </div>
      </div>
      <div class="col-md-12 col-12">
        <div class="icons">
          <div *ngFor="let icon of 'header.icons' | translate; let i = index">
            <div>
              <div class="box-icon d-flex justify-content-center align-items-center w-100">
                <div class="{{ icon.classIcon }}">
                  <img src="{{ icon.icon }}" class="{{ icon.classIcon }}" alt="icons-header" [ngStyle]="{
                      width: i !== 4 && i !== 0 ? '55px' : '',
                      height: i !== 4 ? '55px' : ''
                    }" />
                  <!-- <span class="{{icon.icon}}" [innerHTML]="i == 4 ? icon.icon : '' " ></span> -->
                  <div class="content-tool">
                    <div class="tool">
                      <p [style]="icon.color" class="letters">
                        {{ icon.text }}
                      </p>
                      <p class="text">{{ icon.texTool }}</p>
                    </div>
                  </div>
                </div>
                <div class="content-circle" [ngClass]="i === 5 ? 'circleYellow' : ''" *ngIf="i !== 4">
                  <div [class]="'blob circle-element' + i"></div>
                </div>
              </div>
              <p [style]="icon.color" class="letters">{{ icon.text }}</p>
              <div class="text-responsive">
                <p class="text-tool">{{ icon.texTool }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid bg-gray mb-0" id="{{IDS.certifications}}">
  <div class="row">
    <div class="col-12">
      <div class="container">
        <div class="certificaciones">
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="d-flex-column header-certificacion">
                <h2 class="body-subtitle mobile-margin">
                  {{ "aboutus.certifications.title" | translate }}
                </h2>
                <p class="body-text fw-bold" style="margin-bottom: 5px;">
                  {{ "aboutus.certifications.subtitle" | translate }}
                </p>
                <p class="body-text px-md-5">
                  {{ "aboutus.certifications.p1" | translate }}
                </p>
                <div>
                  <img class="vpcertificacion margin-iso" src="../../../assets/images/png/about-us/VP360.png" alt="VP 360">
                </div>
                <p class="body-text">
                  {{ "aboutus.certifications.p2" | translate }}
                </p>
                <div class="certificacion margin-iso">
                  <span class="icon-IQNet" style="margin-right: 48px"></span>
                  <span class="icon-ISO-27001"><span class="path1"></span><span class="path2"></span><span
                      class="path3"></span><span class="path4"></span><span class="path5"></span><span
                      class="path6"></span><span class="path7"></span><span class="path8"></span><span
                      class="path9"></span><span class="path10"></span><span class="path11"></span><span
                      class="path12"></span><span class="path13"></span><span class="path14"></span><span
                      class="path15"></span><span class="path16"></span><span class="path17"></span><span
                      class="path18"></span><span class="path19"></span><span class="path20"></span><span
                      class="path21"></span><span class="path22"></span><span class="path23"></span><span
                      class="path24"></span><span class="path25"></span><span class="path26"></span><span
                      class="path27"></span><span class="path28"></span><span class="path29"></span><span
                      class="path30"></span><span class="path31"></span><span class="path32"></span><span
                      class="path33"></span><span class="path34"></span><span class="path35"></span><span
                      class="path36"></span><span class="path37"></span><span class="path38"></span><span
                      class="path39"></span><span class="path40"></span><span class="path41"></span><span
                      class="path42"></span><span class="path43"></span><span class="path44"></span><span
                      class="path45"></span><span class="path46"></span><span class="path47"></span><span
                      class="path48"></span><span class="path49"></span><span class="path50"></span><span
                      class="path51"></span><span class="path52"></span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>