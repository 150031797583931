<div class="page-content">
    <!-- content chatbot-->
    <div class="conteent-general-chatbot" *ngIf="displayChatbot">
        <div class="header-card">
            <div style="display: flex; align-items: center;">
                <div class="shadow-avatar">
                    <div class="circle-avatar">
                        <img src="https://2bcore-site.s3.us-east-2.amazonaws.com/public/Avatar+animado_Real+Size.gif"
                            class="img-avatar" alt="">
                    </div>
                </div>
                <div class="active-circle">
                </div>
                <span class="text-header">2BChat</span>
            </div>

            <div>
                <input type="file" (change)="showPreview($event)"
                    accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg ,image/png, application/pdf"
                    style="display: none;" id="imageUpload" />
                <img src="{{soundImg}}" style="margin-right: 20px; cursor: pointer;" (click)="silence()"
                    alt="Silenciar">


                <label for="imageUpload" *ngIf="enableFiles">
                    <img src="https://d3aiotmb6js9zr.cloudfront.net/assets/clip@3x.svg"
                        style="margin-right: 15px; cursor: pointer;" alt="">
                </label>
                <img src="../../../assets/images/svg/header/cerrar.svg" class="button-dismiss" (click)="toggleChatBot()"
                    alt="button-close">
            </div>
        </div>

        <div class="body-chatbot">
            <input *ngIf="enableFiles" type="file" class="input-upload" style="display: none;"
                (change)="showPreview($event)" id="imageUpload">
            <div class="alert" *ngIf="showUpload">
                <div class="contentAlert">
                    <p class="text" *ngIf="lang=='es'">
                        <img src="https://d3aiotmb6js9zr.cloudfront.net/assets/ok_sing@3x.svg" class="svg" alt="">
                        Carga Exitosa
                    </p>
                    <p class="text" *ngIf="lang=='en'">
                        <img src="https://d3aiotmb6js9zr.cloudfront.net/assets/ok_sing@3x.svg" class="svg" alt="">
                        Successful upload
                    </p>
                </div>
            </div>

            <div class="alert" *ngIf="showAlert">
                <div class="contentAlert">
                    <p class="text">
                        <img src="https://d3aiotmb6js9zr.cloudfront.net/assets/warning_sing.gif" class="gif" alt="">
                        Emoji no permitido
                    </p>
                </div>
            </div>

            <div #scrollMeBottom [scrollTop]="scrollMe.scrollHeight" id="scrollMeBottom"
                class="messages-cont align-items-center pt-2">
                <div class="time" *ngIf="lang==='es'">
                    Hoy a las {{time}}
                </div>
                <div class="time" *ngIf="lang==='en'">
                    Today at {{time}}
                </div>

                <ng-container #scrollMe>
                    <div class="contain-box mb-2" *ngFor="let message of messagesHistory;index as indexOfelement;"
                        [ngClass]="message.typeUser === 'bot' ? ' align-left' : ' align-right'">
                        <!-- <div *ngIf="message.sentBy === 'bot'" class="decoratorL"><img
                        src="../assets/img/Chatbot 1.png" width="25px" class="mt-2 me-2" alt=""></div> -->
                        <div>
                            <div *ngIf="indexOfelement == 0 || (messagesHistory[indexOfelement-1]?.typeDescription !== message.typeDescription)"
                                class="send-by " [ngClass]="message.typeUser === 'bot' ? 'from' : 'to'">
                                {{message.typeDescription}}
                            </div>
                            <div class="message from {{message.typeUser}}"
                                *ngIf="message.typeData !== 'file' && message.typeData !== 'image' && message.typeData !== 'audio' && message.typeData !== 'card' ">
                                <span [innerHTML]="message.message"></span>
                                <div style="display: flex; justify-content: center;">
                                    <label for="imageUpload"
                                        *ngIf="enableFiles && (message.message === 'Por favor, adjunta tu CV en un archivo PDF o DOC no mayor a 10 MB.' 
                      || message.message === 'Please attach your CV in a PDF or DOC file no larger than 10 MB'  
                      || message.message === 'Oops! Lo siento, algo no salió bien. Por favor, verifica el archivo que has adjuntado e inténtalo de nuevo'
                      || message.message === 'Oops, sorry, something went wrong. Please check the file you have attached and try again'
                      || message.message === 'Oops! Lo siento, algo no salió bien. Por favor, verifica el archivo que has adjuntado e inténtalo de nuevo.')"
                                        class="button-charge-pdf" style="margin-top: 10px;">{{uploadText}}</label>
                                </div>
                            </div>
                            <div
                                *ngIf="(message.typeData === 'image' || message.typeData === 'file') && message.succesFile == true">
                                <div class="meter">
                                    <span style="width:{{progressFile}}%">{{message.typeData}} {{kbFile}}Kb</span>
                                </div>
                            </div>
                            <div
                                *ngIf="(message.typeData === 'image' || message.typeData === 'file') && message.succesFile == false">
                                <img src="https://d3aiotmb6js9zr.cloudfront.net/assets/carga_incorrecta@3x.svg" alt="">
                            </div>

                            <div class="message from"
                                *ngIf="message.typeData === 'image' && message.succesFile == true">
                                <img [src]="message.filePath" style="width: 100%;
                    height: 100%;">
                            </div>

                            <div class="message from d-flex " style="display: flex;"
                                *ngIf="message.typeData === 'file' && message.succesFile == true">
                                <img src="https://d3aiotmb6js9zr.cloudfront.net/assets/documento@3x.svg" alt="" style="width: 25px;
                  height: 35px; margin-right: 16px;"><span>{{nameFile}}</span>
                            </div>

                            <div *ngIf="message.typeData === 'audio' && audioRecorded == true ">
                                <audio controls>
                                    <source [src]="sanitize(message.url)">
                                </audio>
                            </div>

                            <div class="message from" style="display: flex;"
                                *ngIf="message.typeData === 'file' && message.filename">
                                <img src="https://d3aiotmb6js9zr.cloudfront.net/assets/documento@3x.svg"
                                    style="margin-right: 15px;" alt=""><span>{{nameFile}}</span>
                            </div>

                            <div *ngIf="message.typeData == 'card'">
                                <div class="card">
                                    <ng-container *ngIf="message.typeProgram == 'Enterprise Solutions'">
                                        <img src="https://2bcore-site.s3.us-east-2.amazonaws.com/public/torres/Torre1.gif"
                                            class="enterprises-img">
                                    </ng-container>
                                    <ng-container *ngIf="message.typeProgram == 'Solutions Development'">
                                        <img src="https://2bcore-site.s3.us-east-2.amazonaws.com/public/torres/Torre2.gif"
                                            class="enterprises-img">
                                    </ng-container>
                                    <ng-container *ngIf="message.typeProgram == 'Specialized Services'">
                                        <img src="https://2bcore-site.s3.us-east-2.amazonaws.com/public/torres/Torre3.gif"
                                            class="enterprises-img">
                                    </ng-container>
                                    <ng-container *ngIf="message.typeProgram == 'Normativity & Processes'">
                                        <img src="https://2bcore-site.s3.us-east-2.amazonaws.com/public/torres/Torre4.gif"
                                            class="enterprises-img">
                                    </ng-container>
                                    <ng-container *ngIf="message.typeProgram == '2BInstitute'">
                                        <img src="https://2bcore-site.s3.us-east-2.amazonaws.com/public/torres/Torre5.gif"
                                            class="enterprises-img">
                                    </ng-container>
                                    <ng-container *ngIf="message.typeProgram == '2BTalents'">
                                        <img src="https://2bcore-site.s3.us-east-2.amazonaws.com/public/torres/Torre6.gif"
                                            class="enterprises-img">
                                    </ng-container>
                                    <ng-container *ngIf="message.typeProgram == 'becarios'">
                                        <img src="https://2bcore-site.s3.us-east-2.amazonaws.com/public/carreras/Becarios_Tama%C3%B1o+real.gif"
                                            class="enterprises-img">
                                    </ng-container>
                                    <ng-container *ngIf="message.typeProgram == 'ss'">
                                        <img src="https://2bcore-site.s3.us-east-2.amazonaws.com/public/carreras/Servicio_social_Tama%C3%B1o+real.gif"
                                            class="enterprises-img">
                                    </ng-container>
                                    <ng-container *ngIf="message.typeProgram == 'practicas'">
                                        <img src="https://2bcore-site.s3.us-east-2.amazonaws.com/public/carreras/Servicio_social_Tama%C3%B1o+real.gif"
                                            class="enterprises-img">
                                    </ng-container>
                                    <ng-container *ngIf="message.typeProgram == 'egresados'">
                                        <img src="https://2bcore-site.s3.us-east-2.amazonaws.com/public/carreras/Reci%C3%A9n+egresados_Tama%C3%B1o+real.gif"
                                            class="enterprises-img">
                                    </ng-container>
                                    <ng-container *ngIf="message.typeProgram == 'profesionales'">
                                        <img src="https://2bcore-site.s3.us-east-2.amazonaws.com/public/carreras/Profesionales_Tama%C3%B1o+real.gif"
                                            class="enterprises-img">
                                    </ng-container>

                                    <div class="container">
                                        <ng-container>
                                            <div class="message-card" *ngIf="message.typeData == 'card'">
                                                <div [innerHTML]="message.message">
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngFor="let suggestion of message.suggestionsProgram">
                                            <div class="btn-card">
                                                <button (click)="suggestionResponse(suggestion);"
                                                    aria-label="Responder sugerencia" class="px-1 my-2 btn-secondary"
                                                    *ngIf="suggestion !== 'Más información' && suggestion !== 'More information'">{{suggestion}}
                                                </button>
                                                <a *ngIf="suggestion === 'Más información' || suggestion === 'More information'"
                                                    href="{{message.url}}" target="_blank" aria-label="Más información">
                                                    <button class="px-1 my-2 btn-secondary" aria-label="Más info">
                                                        {{suggestion}}
                                                    </button>
                                                </a>
                                            </div>
                                        </ng-container>
                                    </div>

                                </div>
                            </div>

                            <div *ngIf="indexOfelement == 1 || messagesHistory[indexOfelement+1]?.typeDescription !== message.typeDescription"
                                class="time-message" [ngClass]="message.typeUser === 'bot' ? 'from' : 'to'">
                                {{message.time}}
                            </div>
                        </div>

                        <!-- <div *ngIf="message.sentBy === 'user'" class="decoratorR"><img
                src="../assets/icons/face.svg" width="30px" class="mt-2 ms-2" alt=""></div> -->
                    </div>
                </ng-container>

                <div class="ng-star-inserted" *ngIf="suggestionsBol && !programCard && !programValue">
                    <ng-container *ngFor="let suggestion of suggestionsValues">
                        <button (click)="suggestionResponse(suggestion)" aria-label="Responder sugerencia"
                            class="fast-button px-1 my-2 btn primary-btn">{{suggestion}}</button>
                    </ng-container>
                </div>




                <div class="typing-box typing" *ngIf="userTyping && !typing">
                    <img src="https://d3aiotmb6js9zr.cloudfront.net/assets/loader.gif" width="45px"
                        class="mx-auto typing-img" alt="" />
                </div>

                <div class="typing-box " *ngIf="typing">
                    <img src="https://d3aiotmb6js9zr.cloudfront.net/assets/loader.gif" width="45px"
                        class="mx-auto typing-img" alt="" />
                </div>

                <!-- <div class="ng-star-inserted" >
            <ng-container>
              <button
              class="fast-button px-1 btn-primary"></button>
            </ng-container>
          </div> -->
                <!-- <audio controls="" *ngIf="url">
            <source [src]="sanitize(url)" type="audio/wav">
        </audio> -->

            </div>
        </div>

        <form class="message-chatbot">

            <div *ngIf="!recording" class="d-flex">
                <img src="https://d3aiotmb6js9zr.cloudfront.net/assets/emoji@3x.svg"
                    (click)="!suggestionsBol ? toggleEmojis() : ''" class="icon-emojis" alt="">
                <input type="text" class="input-message" placeholder="{{messageInput}}" (keyup)="handleKeyDown($event)"
                    [disabled]="suggestionsBol" name="textEmoji" [(ngModel)]="textEmoji" recording="true"
                    autocomplete="off" (click)="inputTextClick()" maxlength="300">
                <!-- Commentado por salida a prod, se deja como deuda técnica -->
                <!-- <img src="https://d3aiotmb6js9zr.cloudfront.net/assets/micrófono.png" (click)="initiateRecording()"
            class="icon-emojis" alt="micro"> -->
                <img src="https://d3aiotmb6js9zr.cloudfront.net/assets/enviar_line@3x.svg" class="icon-emojis"
                    style="margin-left: 34px;" (click)="sendMessage($event)" alt="enviar"
                    [ngClass]="(typing && !userTyping) || suggestionsBol || textEmoji === '' || textEmoji === ' ' ? 'disabled' : ''">
                <emoji-mart *ngIf="displayEmojis" title="Selecciona tu emoji…" class="content-emojis"
                    emoji="grinning_face" (emojiClick)="addEmoji($event)"></emoji-mart>
            </div>

            <div *ngIf="recording" class="m-2 audio-gif">
                <img src="https://d3aiotmb6js9zr.cloudfront.net/assets/audio.gif" class=" mx-auto d-block "
                    height="30px">
                <div class="d-flex recording-audio">
                    <img src="https://d3aiotmb6js9zr.cloudfront.net/assets/basura@3x.svg" alt=""
                        (click)="erraseRecording()">
                    <img src="https://d3aiotmb6js9zr.cloudfront.net/assets/pausa@3x.svg" alt=""
                        (click)="stopRecording()">
                    <img src="https://d3aiotmb6js9zr.cloudfront.net/assets/enviar_line@3x.svg" alt=""
                        (click)="sendAudio($event)">
                </div>
            </div>
            <!-- <img src="https://d3aiotmb6js9zr.cloudfront.net/assets/Texto_rico@3x.svg" class="icon-emojis" alt="texto-rico"> -->
        </form>

    </div>
    <div class="content-geeneral-chatbot" *ngIf="!displayChatbot">
        <div class="benefits__upgrade" (click)="toggleChatBot()">
            <div class="benefits__upgrade-ripple">
                <div class="benefits__upgrade-ripple-circle">
                    <div>
                        <img src="https://2bcore-site.s3.us-east-2.amazonaws.com/public/Avatar+animado_Real+Size.gif"
                            class="image_fullsize" alt="">
                    </div>
                    <div>
                        <img src="https://2bcore-site.s3.us-east-2.amazonaws.com/public/Avatar+animado_Real+Size.gif"
                            class="image_mobile" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div>
      <button id="myBtn" class="btn primary-btn" (click)="openModal()">Modal</button>
    </div> -->
    <!-- Modal -->
    <div id="myModal" class="modal">
        <!-- Modal content -->
        <div class="modal-content text-center">
            <div class="modal-header">
                <h2>¡Hey!</h2>
            </div>
            <div class="modal-body">
                <p class="m-0">Tienes una conversación activa con <br>el Asistente Virtual, si abandonas la <br> página
                    se
                    perderá.</p>

                <div>
                    <button class="btn modal-btn" (click)="closeModal()" aria-label="Cerrar modal">Permanecer</button>
                    <button class="btn modal-btn m-0" aria-label="Abandonar">Abandonar</button>
                </div>
            </div>
        </div>
    </div>
</div>