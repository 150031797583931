<div class="container space px-md-0" style="margin-top: 90px;" id="{{IDS.alliances}}">
    <div class="row separate-back m-0">
            <div class="d-flex body-text align-items-center px-0" (click)="goToHome()" style="cursor: pointer;width: fit-content;">
                <span class="icon-Regresar me-2 fs-18" style="cursor: pointer;"></span> <span class="d-none d-lg-block" (click)="goToHome()"> {{ 'back.btn' | translate }}</span>
            </div>
    </div>
    <div class="row m-0" style="margin-bottom: 57px;">
        <div class="col-lg-6 px-0">
            
            <div class="nosotros  margin-section">
                <h1 class="title-text text-center text-lg-start">{{ 'alliances.title' | translate }}</h1>
                <p class="body-text text-center text-lg-start" style="margin-bottom: 32px;">{{ 'alliances.text' | translate }}
                </p>
            </div>
            <a  href="mailto:contacto@2bcore.com" style="width: fit-content;" class=" align-items-sm-center d-none d-lg-flex">
                <button class="btn-bubbleWhite" aria-label="Ver alianza">
                    {{ 'alliances.btn' | translate }}
                </button>
            </a>
        </div>

        <div class="col-lg-6 alliances-img-re d-flex mt-4 mt-lg-0 justify-content-center m-auto">
            <video loop autoplay muted playsinline oncanplay="this.play()" onloadedmetadata="this.muted = true"
                class="alliances-img-re">
                <source src="https://2bcore-site.s3.us-east-2.amazonaws.com/public/siteAnimations/alianzas.mp4" type="video/mp4" />
            </video>
        </div>
       <div class="col-12 d-lg-none d-flex justify-content-center">
        <a  href="mailto:contacto@2bcore.com" style="width: fit-content;" class="d-flex justify-content-center mt-5 d-lg-none">
            <button class="btn-bubbleWhite" aria-label="Ver alianzas">
                {{ 'alliances.btn' | translate }}
            </button>
        </a>
       </div>
    </div>
    <div class="row mb-5"  style="min-height: 800px;">
        <div class="col-1 d-flex justify-content-center align-items-center flex-column d-none d-md-flex "
            style="height: 400px;">
            <span class="icon-up fs-32 "style="color: black; cursor: pointer; margin-bottom: 77px;"
                ></span>
            <span class="icon-down fs-32" style=" color: black; cursor: pointer;" ></span>

        </div>
        <div class="col-12 col-md-4">
            <ng-container *ngFor="let allianceId of alliancesIds">
                <span [id]="allianceId.toString()"></span>
            </ng-container>

            <swiper [config]="swiperConfig" (slideChange)="onSlideChange($event)" class="heightCarousel d-none d-md-block" *ngIf="isDesktop">

                <ng-template *ngFor="let slide of carouselData; index as i"
                    class="d-flex align-items-center justify-content-center justify-content-md-start" swiperSlide
                    let-data>
                    <div class="swiper slide-container m-auto  ms-md-0">
                        <span id="slide{{i}}" [innerHTML]="data.isActive ? slide.icon : slide.icon_gray"
                             [ngClass]="[data.isActive  ? slide.classActive: slide.classInactive]"></span>
                    </div>
                </ng-template>

            </swiper>
            <swiper [config]="swiperConfigMobile" (slideChange)="onSlideChange($event)" class="heightCarousel d-md-none" *ngIf="!isDesktop">

                <ng-template *ngFor="let slide of carouselData; index as i"
                    class="d-flex align-items-center justify-content-center justify-content-md-start" swiperSlide
                    let-data>
                    <div class="swiper slide-container m-auto  ms-md-0">
                        <span id="slide{{i}}" [innerHTML]="data.isActive ? slide.icon : slide.icon_gray"
                             [ngClass]="[data.isActive  ? slide.classActive: slide.classInactive]"></span>
                    </div>
                </ng-template>

            </swiper>
        </div>
        <div class="col-12 d-flex py-4 m-auto d-flex d-md-none justify-content-center justify-content-md-start">
            <div class="icon-up up-mobile fs-32 me-4"  >
            </div>
            <div class="icon-down down-mobile fs-32" >
            </div>
        </div>
        <div class="col-12 col-md-7" id="allianceBlock">
            <span class="icon-search-active"></span>
            <p class="title-text">{{activeAlliance.name}}</p>
            <p class="body-text">
                {{activeAlliance.description}}
            </p>
            <br>
            <p class="subtitle-text">
                {{ 'alliances.services' | translate }}
            </p>
            <div *ngIf="activeAlliance.services.length === 1">
                <p class="body-text">
                    {{activeAlliance.services[0].name}}
                </p>
                <p *ngFor="let service of activeAlliance.services[0]?.description" class="body-text-service d-flex">
                    <span class="icon-bullet" style="margin-right: 10px;margin-top: 4px;"><span class="path1"></span><span
                            class="path2"></span></span> <span>{{service}}</span>
                </p>

            </div>
            <div class="accordion" id="accordionAlliances" *ngIf="activeAlliance.services.length !== 1">
                <div class="accordion-item mb-4" *ngFor="let service of activeAlliance.services; index as i">
                    <h2 class="accordion-header" id="headingOne{{i}}">
                        <a class="body-text d-flex" type="button" href="#collapseOne{{i}}" data-bs-toggle="collapse" aria-label="Cambiar servicio active"
                            (click)="serviceAtcive(i)" aria-expanded="false">
                            <div class="transition" [ngClass]="activeAlliance.services[i].active ? 'active-collapse' : ''"><span
                                    class="icon-list_expanded fs-11 fs-md-16 "></span> </div> <span
                                class="ms-1">{{service.name}}</span>
                        </a>
                    </h2>
                    <div id="collapseOne{{i}}" class="accordion-collapse collapse" data-bs-parent="#accordionAlliances">
                        <div class="accordion-body px-0">
                            <p *ngFor="let service of service.description" class="d-flex body-text-service ">
                                <span class="icon-bullet fs-16" style="margin-right: 10px;margin-top: 4px;"><span class="path1"></span><span
                                        class="path2"></span></span> <span>{{service}}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>