import { CAT_SECTION } from "./search-word.const";

export const ALLIANCES_SECTIONS = [
    CAT_SECTION.AWS,
    CAT_SECTION.SAP,
    CAT_SECTION.Salesforce,
    CAT_SECTION.IBMSterling,
    CAT_SECTION.RedHat,
    CAT_SECTION.MicrosoftAzure,
    CAT_SECTION.Terraform,
    CAT_SECTION.Redis,
    CAT_SECTION.NYCE,
    CAT_SECTION.Ingram
  ];

export enum ALLIANCES_ID {
    AWS = 'AWS',
    SAP = 'SAP',
    SALESFORCE = 'SALESFORCE',
    IBM = 'IBM',
    REDHAT = 'REDHAT',
    AZURE = 'AZURE',
    TERRAFORM = 'TERRAFORM',
    REDIS = 'REDIS',
    NYCE = 'NYCE',
    INGRAM = 'INGRAM',
    TECHDATA = 'TECHDATA',

}

export const ALLIANCES = {
    AWS: {
        _id: 0,
        name: 'AWS',
        description: 'Amazon Web Services (AWS) ofrece más de 200 servicios integrales de centros de datos a nivel global que están enfocados para autónomos, como pequeñas y medianas empresas o grandes corporaciones, le ayuda a evitar arduas tareas como el aprovisionamiento, el mantenimiento y la planificación de capacidad implementando los modelos de informática en la nube: IaaS, PaaS y SaaS. ',
        services: [
            {
                name: '',
                active: false,
                description: [
                    'Cloud Computing.',
                    'Bases de datos.',
                    'Redes virtuales.',
                    'Aplicaciones empresariales.',
                    'Almacenamiento y gestores de contenido.',
                    'Inteligencia de negocio.',
                    'Gestión de aplicaciones móviles.',
                    'Internet de las cosas.',
                    'Herramientas para desarrolladores.',
                    'Seguridad y control de acceso.'
                ]
            },
        ]
    },
    AZURE: {
        _id: 1,
        name: 'Microsoft Azure',
        description: 'Servicio de computación en la nube para construir, probar, desplegar y administrar aplicaciones y servicios, mediante el uso de sus centros de datos como servicio (SaaS), plataforma como servicio (PaaS) e infraestructura como servicio (IaaS).',
        services: [
            {
                name: '',
                active: false,
                description: [
                    'Azure Virtual Machines Linux.',
                    'Azure Virtual Machines Windows.',
                    'Azure SQL Database.',
                    'Azure Blob Storage.',
                    'Azure Cosmos DB.',
                    'Azure Fuctions.',
                    'Azure Event Grid.'
                ]
            },
        ]
    },
    IBM: {
        _id: 2,
        name: 'IBM Sterling',
        description: 'Proporciona a los clientes soluciones para la mejora de sus procesos de negocio. Facilitando métodos para hacer frente a los problemas empresariales mediante una adecuada utilización de las tecnologías de la información.',
        services: [
            {
                name: 'Suite IBM Sterling',
                active: false,
                description: [
                    'Diseño de arquitectura.',
                    'Implementación de productos.',
                    'Desarrollo de artefactos a la medida sobre la solución.',
                    'IT Assessment.',
                    'HealthCheck y afinación.',
                    'Soporte en producción.',
                    'Transferencia de conocimientos y acompañamiento.'
                ]
            },
        ]
    },
    NYCE: {
        _id: 3,
        name: 'NYCE',
        description: 'Organismo Nacional de Normalización (ONN) en la industria electrónica, telecomunicaciones y tecnologías de información, como un aliado estratégico en el mercado, brindando seguridad y confianza a la industria y a los consumidores.',
        services: [
            {
                name: '',
                active: false,
                description: [
                    'NYCE Laboratorios.',
                    'NYCE Certificación e inspección.',
                    'NYCE Asia.',
                ]
            },
        ]
    },
    REDIS: {
        _id: 4,
        name: 'Redis',
        description: 'Logra que las aplicaciones sean más rápidas y eficientes, creando una capa de datos mediante un código abierto. Redis cuenta con una gran variedad de aplicaciones que se adaptan para cada necesidad o requerimiento.',
        services: [
            {
                name: '',
                active: false,
                description: [
                    'TCO atractivo.',
                    'Simplicidad arquitectónica y escalable.',
                    'Diseños híbridos cuando esté listo para migrar a la nube.',
                    'Productividad mejorada de los desarrolladores y las operaciones.',
                    'Azure Cosmos DBS servicios profesionales.',
                ]
            },
        ]
    },
    REDHAT: {
        _id: 5,
        name: 'Red Hat',
        description: 'Ofrecemos soluciones Open Source, las cuales permiten que las empresas trabajen distintas plataformas y entornos con mayor facilidad, desde el centro de datos principal hasta el extremo de la red, ayudando a diseñar soluciones de infraestructura de TI flexibles y sólidas.',
        services: [
            {
                name: '',
                active: false,
                description: [
                    'Consultoría.',
                    'Desarrollo.',
                    'Transformación digital.',
                    'Infraestructura.',
                    'Soporte.'
                ]
            },
        ]
    },
    SALESFORCE: {
        _id: 6,
        name: 'Salesforce',
        description: 'Solución de gestión de relaciones con clientes una a una empresa y clientes, mediante una plataforma CRM integrada que brinda a todos los departamentos, incluidos marketing, ventas, comercio y servicios, en una vista única compartida para cada cliente.',
        services: [
            {
                name: 'Servicios especializados en TI',
                active: false,
                description: [
                    'Implementación desde 0.',
                    'Personalización, corrección y mejoras a tu organización de Salesforce por proyecto.'
                ]
            },
            {
                name: 'Soporte y AMS​',
                active: false,
                description: [
                    'Personalización, corrección y mejoras a tu organización de Salesforce por requerimiento.',
                    'Planes de adopción y capacitación para tu equipo.',
                    'Carga y migración de datos.',
                    'Evoluciona tu plataforma de Salesforce.'
                ]
            },
            {
                name: 'Assesment​',
                active: false,
                description: [
                    'Identifica áreas de oportunidad en tus procesos e implementación.',
                    'Capacitación de expertos​ Salesforce.',
                    'Capacita a tu equipo como desarrollador Salesforce.'
                ]
            }
        ]
    },
    SAP : {
        _id: 7,
        name: 'SAP',
        description: 'Ayuda a las empresas y organizaciones, de todos los tamaños e industrias, a gestionar sus negocios de manera rentable, dándoles a los empleados de diferentes departamentos un acceso fácil a la información en tiempo real en toda la empresa.',
        services: [
            {
                name: 'Desarrollo ABAP',
                active: false,
                description: [
                    'Objetos ABAP y ABAP OO Custom (Transacciones, Reportes, Módulos de funciones, Clases).',
                    'Integraciones con sistemas SAP y No-SAP (RFCs, Idocs, SOAP, Webservices, PI Proxies, REST Services, Odata services con SAP Gateway).',
                    'Customizaciones de código (User exits, BAdIs, BTEs, Enhancements Explícitos e implícitos, screen exits).',
                    'Forms (SAPScripts, Smartforms, Adobe Forms).',
                    'Tecnologías UI (Dynpros, WebDynpros, ITS, SAPUI5).'
                ]
            },
            {
                name: 'ABAP S/4 HANA',
                active: false,
                description: [
                    'Apoyo en remediación de código para-S/4HANA.',
                    'Ajuste de ampliaciones para la actualización.',
                    'Desarrolladores ABAP con experiencia en ABAP 7.40+ y manejo de Eclipse IDE para-ABAP.',
                    'Refactorización utilizando la filosofía Code Push-down para aprovechar la nueva arquitectura del sistema.',
                    'Desarrollo de objetos ABAP propios de S/4HANA.'
                ]
            }
        ]
    
        
    },
   
   
   
   
    TERRAFORM: {
        _id: 8,
        name: 'Terraform',
        description: 'Herramienta Open Source de infraestructura como código por HashiCorp. Impulsa a las organizaciones a mejorar la productividad, reducir el riesgo y aumentar la velocidad del negocio a medida que integran la nube en sus entornos de TI.',
        services: [
            {
                name: '',
                active: false,
                description: [
                    'Despliegues de infraestructura como código.',
                    'Despliegue de infraestructura como código en ambientes multi-nube.',
                    'Administración de Kubernetes.',
                    'Automatize infraestructura de red.',
                    'Inserte credenciales con Terraform.',
                ]
            },
        ]
    },
   
  
   
    }

export const ALLIANCES_ENG = {
    AWS: {
        _id: 0,
        name: 'AWS',
        description: 'Amazon Web Services (AWS) offers more than 200 end-to-end data center services globally that are targeted for freelancers, such as small and medium-sized businesses or large corporations, helping you avoid arduous tasks such as provisioning, maintenance and capacity planning by implementing cloud computing models: IaaS, PaaS and SaaS.',
        services: [
            {
                name: '',
                active: false,
                description: [
                    'Cloud Computing.',
                    'Databases.',
                    'Virtual Networks.',
                    'Enterprise applications.',
                    'Storage and content management.',
                    'Business intelligence.',
                    'Mobile application management.',
                    'Internet of Things.',
                    'Developer tools.',
                    'Security and access control.'
                ]
            },
        ]
    },
    AZURE: {
        _id: 1,
        name: 'Microsoft Azure',
        description: 'Cloud computing service to build, test, deploy and manage applications and services, using its data centers as a service (SaaS), platform as a service (PaaS) and infrastructure as a service (IaaS).',
        services: [
            {
                name: '',
                active: false,
                description: [
                    'Azure Virtual Machines Linux',
                    'Azure Virtual Machines Windows',
                    'Azure SQL Database',
                    'Azure Blob Storage',
                    'Azure Cosmos DB',
                    'Azure Fuctions',
                    'Azure Event Grid'
                ]
            }
        ]
    },
    IBM: {
        _id: 2,
        name: 'IBM Sterling',
        description: 'Provides our clients with solutions for the improvement of their business processes. Facilitating methods to address business problems through the appropriate use of information technologies.',
        services: [
            {
                name: 'IBM Sterling Suite',
                active: false,
                description: [
                    'Architecture design.',
                    'Product implementation.',
                    'Development of customized artifacts on the solution.',
                    'IT Assessment.',
                    'HealthCheck and tuning.',
                    'SProduction support.',
                    'Knowledge transfer and support.'
                ]
            }
        ]
    },
    NYCE: {
        _id: 3,
        name: 'NYCE',
        description: 'National Standards Body (ONN) in the electronics, telecommunications and information technology industry, as a strategic ally in the market, providing security and confidence to the industry and consumers.',
        services: [
            {
                name: '',
                active: false,
                description: [
                    'NYCE Laboratories.',
                    'NYCE Certification and inspection.',
                    'NYCE Asia.'
                ]
            }
        ]
    },
    REDIS: {
        _id: 4,
        name: 'Redis',
        description: 'It makes applications faster and more efficient, creating a data layer through open source code. Redis has a wide variety of applications that adapt to every need or requirement.',
        services: [
            {
                name: '',
                active: false,
                description: [
                    'Attractive TCO.',
                    'Architectural simplicity and scale.',
                    'Design for hybrids when ready to migrate to the cloud.',
                    'Improved developer and operations productivity.',
                    'Azure Cosmos DBS Professional Services.'
                ]
            }
        ]
    },
    REDHAT: {
        _id: 5,
        name: 'Red Hat',
        description: 'We offer Open Source solutions, which allow companies to work across platforms and environments more easily, from the core data center to the edge of the network, helping to design flexible and robust IT infrastructure solutions.',
        services: [
            {
                name: '',
                active: false,
                description: [
                    'Consulting.',
                    'Development.',
                    'Digital transformation',
                    'Infrastructure',
                    'Support'
                ]
            }
        ]
    },
    SALESFORCE: {
        _id: 6,
       name: 'Salesforce',
        description: 'Customer relationship management solution unites a company and its customers through an integrated CRM platform that provides all departments, including marketing, sales, commerce and services, in a single shared view for each customer.',
        services: [
            {
                name: 'Specialized IT services',
                active: false,
                description: [
                    'Implementation from 0.',
                    'Customization, fixes and improvements to your Salesforce organization per project.'
                ]
            },
            {
                name: 'Support and AMS',
                active: false,
                description: [
                    'Customization, fixes and enhancements to your Salesforce organization on demand.',
                    'Adoption and training plans for your team.',
                    'Data loading and migration.',
                    'Evolve your Salesforce platform.'
                ]
            },
            {
                name: 'Assesment​',
                active: false,
                description: [
                    'Identify areas of opportunity in your processes and implementation.',
                    'Salesforce expert training.',
                    'Train your team as Salesforce Developers.'
                ]
            }
        ]
    },
    SAP: {
            _id: 7,
            name: 'SAP',
            description: 'It helps companies and organizations of all sizes and industries manage their businesses profitably by giving employees in different departments easy access to real-time information across the enterprise.',
            services: [
                {
                    name: 'ABAP Development',
                    active: false,
                    description: [
                        'ABAP and ABAP OO Custom Objects (Transactions, Reports, Function Modules, Classes).',
                        'Integrations with SAP and Non-SAP systems (RFCs, Idocs, SOAP, Webservices, PI Proxies, REST Services, Odata services with SAP Gateway), Webservices, PI Proxies, REST Services, Odata services with SAP Gateway).',
                        'Code customizations (User exits, BAdIs, BTEs, Explicit and implicit Enhancements, screen exits).',
                        'Forms (SAPScripts, Smartforms, Adobe Forms).',
                        'UI technologies (Dynpros, WebDynpros, ITS, SAPUI5).'
                    ]
                },
                {
                    name: 'ABAP S/4 HANA',
                    active: false,
                    description: [
                        'Support in code remediation for S/4HANA.',
                        'Tuning of extensions for upgrade..',
                        'ABAP developers with experience in ABAP 7.40+ and Eclipse IDE for ABAP.',
                        'Refactoring using Code Push-down philosophy to take advantage of the new system architecture.',
                        'Development of ABAP objects specific to S/4HANA.'
                    ]
                }
            ]
        
            
        },
      
     
       
       
        TERRAFORM: {
            _id: 8,
            name: 'Terraform',
            description: 'Open Source infrastructure-as-code tool by HashiCorp. Drives organizations to improve productivity, reduce risk and increase speed of business as they integrate the cloud into their IT environments.',
            services: [
                {
                    name: '',
                    active: false,
                    description: [
                        'Infrastructure-as-code deployments.',
                        'Deployment of infrastructure as code in multi-cloud environments.',
                        'Kubernetes administration.',
                        'Automate network infrastructure.',
                        'Insert credentials with Terraform.'
                    ]
                }
            ]
        },
       
     
    
      }
/*VTEX: {
    _id: 10,
    name: 'VTEX',
    description: 'Es una plataforma de comercio digital para empresas que permite a las marcas y a los minoristas lograr un Time-To-Market más rápido, llegar a sus clientes a través de cualquier canal y descubrir nuevas áreas de crecimiento.',
    services: [
        {
            name: '',
            description: [
                'Comercio B2C',
                'Comercio B2B',
                'Marketplace',
                'Omnichannel',
                'Comercio Headless',
            ]
        },
    ]
},
TWILIO: {
    _id: 11,
    name: 'Twilio',
    description: 'Plataforma de comunicación en la nube líder en el mundo, que permite atraer clientes a través de canales: SMS, voz, video, WhatsApp y mail.',
    services: [
        {
            name: '',
            description: [
                'Programable SMS',
                'Twilio Flex',
                'Programmable Voice',
                'Twilio Sedgrid'
                        ]
        },
    ]
}*/

export const CAROUSEL_ALLLIANCES = [
   
   
    
    {
        name: 'AWS',
        icon: '<span class="icon-aws-color fs-50"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>',
        icon_gray: '<span class="icon-aws-gris"></span>',
        classInactive: 'azure-inactive',
        classActive: 'azure-active'
    },
    {
        name: 'AZURE',
        icon: '<span class="icon-Microsoft-Azure-color"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span></span>',
        icon_gray: '<span class="icon-Microsoft-Azure-gris"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span></span>',
        classInactive: 'azure-inactive',
        classActive: 'azure-active'
    },
    {
        name: 'IBM',
        icon: '<span class="icon-IBM"></span>',
        icon_gray: '<span class="icon-ibm-gray"></span>',
        classInactive: 'ibm-inactive',
        classActive: 'ibm-active'
    },
    {
        name: 'NYCE',
        icon: '<span class="icon-NYCE"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span><span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span><span class="path25"></span><span class="path26"></span><span class="path27"></span></span>',
        icon_gray: '<span class="icon-nyce-gray"></span>',
        classInactive: 'nyce-inactive',
        classActive: 'nyce-active'
    },
    
    {
        name: 'REDIS',
        icon: '<span class="icon-Redis-labs"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span></span>',
        icon_gray: '<span class="icon-redis-gray"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span></span>',
        classInactive: 'redis-inactive',
        classActive: 'redis-active'
    },
    {
        name: 'REDHAT',
        icon: '<span class="icon-red_hat_color"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span></span>',
        icon_gray: '<span class="icon-red_hat_gray"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span>',
        classInactive: 'redhat-inactive',
        classActive: 'redhat-active'
    },
    {
        name: 'SALESFORCE',
        icon: '<span class="icon-Salesforce"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
        icon_gray: '<span class="icon-salesforce-gray"></span>',
        classInactive: 'salesforce-inactive',
        classActive: 'salesforce-active'
    },
    {
        name: 'SAP',
        icon: '<span class="icon-SAP"><span class="path1"></span><span class="path2"></span></span>',
        icon_gray: '<span class="icon-sap-gray"></span>',
        classInactive: 'sap-inactive',
        classActive: 'sap-active'
    },
    {
        name: 'TERRAFORM',
        icon: '<span class="icon-Terraform"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>',
        icon_gray: '<span class="icon-terraform-gray"></span>',
        classInactive: 'terraform-inactive',
        classActive: 'terraform-active'
    },
  
]
