<div class="page-content">
  <app-header class="app-header"></app-header>
  <div>
    <router-outlet class="margin-header"></router-outlet>
  </div>
  <app-cookie-banner></app-cookie-banner>
  <app-footer class="app-footer pt-4"></app-footer>
</div>
<div class="h-100" *ngIf="loader">
  <app-loader *ngIf="loader" class="h-100"></app-loader>
</div>
<chat-bot></chat-bot>