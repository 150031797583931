import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, timer } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
export class Message {
  constructor(
    public typeUser: string,
    public typeDescription: string,
    public typeData: string,
    public message: string,
    public time: string,
    public url?: string,
    public filePath?: string | ArrayBuffer | null | undefined,
    public succesFile?: boolean,
    public typeProgram?: string,
    public suggestionsProgram?: [string],
  ) { }
}
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  //arreglo de mensajes de la conversacion
  conversation = new BehaviorSubject<Message>(new Message("", "", "", "", ""));
  suggestionsBol = new BehaviorSubject<boolean>(false);
  suggestionsVals = new BehaviorSubject<Array<string>>([]);
  currentPage = new BehaviorSubject<string>("");
  soundOff = new BehaviorSubject<boolean>(false);
  writing = new BehaviorSubject<boolean>(false);
  currentInterval: any;
  enableConv = new BehaviorSubject<boolean>(false);
  firstTime = new BehaviorSubject<boolean>(true);
  enableSurvey = new BehaviorSubject<boolean>(false);
  showCurse = new BehaviorSubject<boolean>(false);
  enableFiles = new BehaviorSubject<boolean>(false);
  endEncuesta = new BehaviorSubject<boolean>(false);
  langChatbot = new BehaviorSubject<string>("es");
  programCard = new BehaviorSubject<boolean>(false);
  programValue = new BehaviorSubject<string>("");
  history: any[] = [];
  timer: any;
  token: string = '';
  sessionId = new BehaviorSubject<string>("");
  url = `${environment.baseUrl}/chatbot/public/texto`
  urlEncuesta = `${environment.baseUrl}/chatbot/public/texto/encuesta`
  urlSurveys = `${environment.baseUrl}/chatbot/registerSurvey`
  urlAudio = `${environment.baseUrl}/chatbot/public/audio`
  urlTranscripcion = `${environment.baseUrl}/chatbot/saveTrans`
  urlUploadFile = `${environment.baseUrl}/chatbot/uploadFile`

  surveyStage: number = 0;
  surveyAnswers: string[] = [];

  endstage: number = 0;

  emailParameter = '';

  sender: string = 'Tu'
  constructor(private http: HttpClient) {
  }


  converse(msg: string, inactive: boolean) {
    const regexExp = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
    if (this.enableConv.value && !inactive) {
      this.changeSuggestionsBol(false)
      const userMessage = new Message("user", this.sender, "message", msg, this.convTime("time"));
      this.update(userMessage);
      this.playAudio();
      this.changeWriting(true);
      let emojisUsed = msg.match(regexExp);
      const query = {
        sessionId: this.sessionId.value,
        message: msg.replace(regexExp, ''),
        currentPage: this.currentPage.value,
        history: this.history,
        lang: this.langChatbot.value
      }
      this.http.post(this.url, query).subscribe((res: any) => {
        this.changeCurrentSession(res.sessionId);
        let speech = res.message.join(' ');
        if (speech != undefined) {
          this.history = res.history;
          if (res.parameters.programa || res.parameters.servicio) {
            this.changeProgramBool(true)
            this.changeProgramValue(res.parameters.programa || res.parameters.servicio)
            let msgBotFull = speech + ((emojisUsed != undefined) ? emojisUsed?.join('') : '')
            let type = (res.parameters.programa || res.parameters.servicio) ? 'card' : "message";
            let url = (res.parameters.programa) ? `${environment.siteUrl}/carreras` : `${environment.siteUrl}/servicios`
            let botMessage = new Message('bot', "2BChat", type, msgBotFull, this.convTime("time"), url, null, undefined,
              res.parameters.programa ? res.parameters.programa : res.parameters.servicio, res.suggestions);
            setTimeout(() => {
              this.update(botMessage);
              this.playAudio();
            }, 1000)
          } else {
            this.changeProgramBool(false)
            this.changeProgramValue('')
            res.message.forEach((msg: any) => {
              if (msg !== '¡Hola! Bienvenido a 2BCORE' || msg !== 'Hello! Welcome to 2BCORE') {
                let msgBotFull = msg + ((emojisUsed != undefined) ? emojisUsed?.join('') : '')
                let type = (res.parameters.programa) ? 'card' : "message";
                let botMessage = new Message('bot', "2BChat", type, msgBotFull, this.convTime("time"));
                setTimeout(() => {
                  this.update(botMessage);
                  this.playAudio();
                }, 1000)
              }
            })
          }
          if (res.parameters.end_conv == true) {
            this.changeEnableConv(false);
            this.changeEnableSurvey(true);
            const query = {
              sessionId: this.sessionId.value,
              message: "start",
              currentPage: this.currentPage.value,
              history: this.history,
              lang: this.langChatbot.value
            }
            this.http.post(this.urlEncuesta, query).subscribe((res: any) => {
              this.changeCurrentSession(res.sessionId);
              let speech = res.message.join(' ');
              if (speech != undefined) {
                this.history = res.history;
                res.message.forEach((msg: any) => {
                  let botMessage = new Message('bot', "2BChat", "message", msg, this.convTime("time"));
                  setTimeout(() => {
                    this.update(botMessage);
                    this.playAudio();
                  }, 1000)
                })
                this.currentInterval = setInterval(() => {
                  if (res.suggestions.length > 0) {
                    this.changeSuggestionsBol(true)
                    this.changeSuggestionsValues(res.suggestions)
                  }
                  this.playAudio();
                  this.changeWriting(false);
                  clearInterval(this.currentInterval);
                }, res.message.length * 1200);
                this.changeCurrentPage(res.currentPage)
              } else {
              }
            })
          } else {
            this.currentInterval = setInterval(() => {
              if (res.suggestions.length > 0) {
                this.changeSuggestionsBol(true)
                this.changeSuggestionsValues(res.suggestions)
              } else {
                this.changeSuggestionsBol(false)
                this.changeSuggestionsValues([])
              }
              if (res.parameters.enableFiles == true) {
                this.changeEnableFiles(true);
              }
              if (res.paramsDialog.mail && res.paramsDialog.mail?.stringValue != undefined) {
                this.emailParameter = res.paramsDialog.mail.stringValue !== 'NULL_VALUE' ? res.paramsDialog.mail.stringValue : this.emailParameter
              }
              if (!res.parameters.end_conv) {
                this.changeWriting(false);
              }
              clearInterval(this.currentInterval);
              this.changeWriting(false)
            }, res.message.length * 1200);
            this.changeCurrentPage(res.currentPage)
          }

        } else {
          console.log("reintento");
        }
      },
        (err: any) => {
          console.log("err", err);
        }
      );
    } else {
      if (inactive) {
        const botMessage = new Message('bot', "2BChat", "message", msg, this.convTime("time"));
        this.update(botMessage);
        this.playAudio();
        if (this.suggestionsBol.value == true) {
          this.changeSuggestionsBol(true)
        }
        this.changeWriting(false);
      } else if (this.enableSurvey.value) {
        this.changeSuggestionsBol(false)
        const userMessage = new Message("user", this.sender, "message", msg, this.convTime("time"));
        this.update(userMessage);
        this.playAudio();
        if (msg.replace(regexExp, '') == '') {
          let msgShow = this.history[this.history.length - 1].bot
          const botMessage = new Message('bot', "2BChat", "message", msgShow, this.convTime("time"));
          this.update(botMessage);
          this.playAudio();
        } else {
          const query = {
            sessionId: this.sessionId.value,
            message: msg,
            currentPage: this.currentPage.value,
            history: this.history,
            lang: this.langChatbot.value,
            email: this.emailParameter
          }
          this.changeWriting(true);
          this.http.post(this.urlEncuesta, query).subscribe((res: any) => {
            this.changeCurrentSession(res.sessionId);
            this.emailParameter = res.paramsDialog.email?.stringValue
            let speech = res.message.join(' ');
            if (speech != undefined) {
              this.history = res.history;
              res.message.forEach((msg: any) => {
                let botMessage = new Message('bot', "2BChat", "message", msg, this.convTime("time"));
                setTimeout(() => {
                  this.update(botMessage);
                  this.playAudio();
                }, 1500)
              })
              this.currentInterval = setInterval(() => {
                if (res.suggestions.length > 0) {
                  this.changeSuggestionsBol(true)
                  this.changeSuggestionsValues(res.suggestions)
                } else {
                  this.changeSuggestionsBol(false)
                }
                if (res.parameters.saveTranscript == true) {
                  const query = {
                    sessionId: this.sessionId.value,
                    history: this.history,
                  }
                  this.http.post(this.urlTranscripcion, query).subscribe((res: any) => {
                  })
                }
                this.changeWriting(false);
                clearInterval(this.currentInterval);
                setTimeout(() => {
                  if (res.parameters.endEncuesta == true) {
                    if (this.langChatbot.value == "es") {
                      this.converse("Esta conversación se cerrará automáticamente en 5 segundos", true)
                    } else if (this.langChatbot.value == "en") {
                      this.converse("This conversation will be closed automatically in 5 seconds 👋", true)
                    }
                    this.changeEndEncuesta(true);
                  }
                }, 2000)
              }, res.message.length * 1200);

              this.changeCurrentPage(res.currentPage)
            } else {
              console.log("reintento");
            }
          })
        }
      }
    }
  }

  converseSendFile(msg: string) {
    const userMessage = new Message("user", this.sender, "message", msg, this.convTime("time"));
    this.playAudio();

    const query = {
      sessionId: this.sessionId.value,
      message: msg,
      currentPage: this.currentPage.value,
      history: this.history,
      lang: this.langChatbot.value
    }
    this.changeWriting(!this.writing.value);
    this.http.post(this.url, query).subscribe((res: any) => {
      this.changeCurrentSession(res.sessionId);
      let speech = res.message.join(' ');
      if (speech != undefined) {
        const botMessage = new Message('bot', "2BChat", "message", speech, this.convTime("time"));
        this.history = res.history;
        this.currentInterval = setInterval(() => {
          this.update(botMessage);
          if (res.suggestions.length > 0) {
            this.changeSuggestionsBol(true)
            this.changeSuggestionsValues(res.suggestions)
          }
          this.playAudio();
          this.changeWriting(!this.writing.value);
          this.changeEnableFiles(false);
          clearInterval(this.currentInterval);
        }, 1000);
        this.changeCurrentPage(res.currentPage)
      } else {
        console.log("reintento");
      }
    },
      (err: any) => {
        console.log("err", err);
      }
    );
  }

  converseStart(msg: string) {
    this.changeSuggestionsBol(false)
    this.changeProgramBool(false)
    this.changeProgramValue('')
    const query = {
      sessionId: this.sessionId.value,
      message: msg,
      currentPage: this.currentPage.value,
      history: this.history,
      lang: this.langChatbot.value
    }
    this.changeWriting(true);
    this.http.post(this.url, query).subscribe((res: any) => {
      this.changeCurrentSession(res.sessionId);
      let speech = res.message.join(' ');
      if (speech != undefined) {
        this.history = res.history;
        if (res.parameters.programa || res.parameters.servicio) {
          this.changeProgramBool(true)
          this.changeProgramValue(res.parameters.programa || res.parameters.servicio)
          let msgBotFull = speech;
          let type = (res.parameters.programa || res.parameters.servicio) ? 'card' : "message";
          let url = (res.parameters.programa) ? 'https://pre-site.2bcore.com.mx/carreras' : 'https://pre-site.2bcore.com.mx/servicios'
          let botMessage = new Message('bot', "2BChat", type, msgBotFull, this.convTime("time"), url, null, undefined,
            res.parameters.programa ? res.parameters.programa : res.parameters.servicio, res.suggestions);
          setTimeout(() => {
            this.update(botMessage);
            this.playAudio();
          }, 1000)
        } else {
          this.changeProgramBool(false)
          this.changeProgramValue('')
          res.message.forEach((msg: any) => {
            if (msg !== '¡Hola! Bienvenido a 2BCORE' || msg !== 'Hello! Welcome to 2BCORE') {
              let msgBotFull = msg
              let type = (res.parameters.programa) ? 'card' : "message";
              let botMessage = new Message('bot', "2BChat", type, msgBotFull, this.convTime("time"));
              setTimeout(() => {
                this.update(botMessage);
                this.playAudio();
              }, 1000)
            }
          })
        }
        this.currentInterval = setInterval(() => {
          if (res.suggestions.length > 0) {
            this.changeSuggestionsBol(true)
            this.changeSuggestionsValues(res.suggestions)
          } else {
            this.changeSuggestionsBol(false)
            this.changeSuggestionsValues([])
          }
          this.playAudio();
          this.changeWriting(false);
          clearInterval(this.currentInterval);
        }, res.message.length * 1000);
        this.changeCurrentPage(res.currentPage)
      } else {
        console.log("reintento");
      }
    },
      (err: any) => {
        console.log("err", err);
      }
    );
  }

  upload(formData: FormData) {
    this.changeSuggestionsBol(false)
    return this.http.post(this.urlAudio, formData).subscribe((res: any) => {
      let transcript = res.transcript
      if (transcript.trim().length >= 2) {
        // const userMessage = new Message("user", this.sender, "message", transcript, this.convTime("time"));
        // this.update(userMessage);
        this.playAudio();
        this.changeCurrentSession(res.sessionId);
        let speech = res.message.join(' ');
        if (speech != undefined) {
          const botMessage = new Message('bot', "2BChat", "message", speech, this.convTime("time"));
          this.history = res.history;
          this.currentInterval = setInterval(() => {
            this.update(botMessage);
            this.playAudio();
            if (res.suggestions.length > 0) {
              this.changeSuggestionsBol(!this.suggestionsBol.value)
              this.changeSuggestionsValues(res.suggestions)
            }
            clearInterval(this.currentInterval);
            this.changeCurrentPage(res.currentPage)
          }, 1000);
        }
      } else {
        let notsoundmsg = '¡Ups! creo que no escuché nada, ¿podrías repetirlo?';
        const botMessage = new Message('bot', "2BChat", "message", notsoundmsg, this.convTime("time"));
        this.history = res.history;
        this.currentInterval = setInterval(() => {
          this.update(botMessage);
          if (res.suggestions.length > 0) {
            this.changeSuggestionsBol(!this.suggestionsBol.value)
            this.changeSuggestionsValues(res.suggestions)
          }
          this.changeCurrentPage(res.currentPage)
          clearInterval(this.currentInterval);
        }, 1000);
      }
    })
  }

  update(msg: Message) {
    this.conversation.next(msg);
  }

  changeEndEncuesta(value: boolean) {
    this.endEncuesta.next(value);
  }
  changeEnableConv(change: boolean) {
    this.enableConv.next(change);
  }
  changeFirstTime(change: boolean) {
    this.firstTime.next(change);
  }
  changeEnableSurvey(change: boolean) {
    this.enableSurvey.next(change);
  }
  changeEnableFiles(change: boolean) {
    this.enableFiles.next(change);
  }
  changeShowCurse(change: boolean) {
    this.showCurse.next(change);
  }
  changeSuggestionsBol(change: boolean) {
    this.suggestionsBol.next(change);
  }
  changeSuggestionsValues(change: Array<string>) {
    this.suggestionsVals.next(change);
  }
  changeCurrentPage(change: string) {
    this.currentPage.next(change);
  }
  changeProgramBool(change: boolean) {
    this.programCard.next(change)
  }
  changeProgramValue(change: string) {
    this.programValue.next(change)
  }
  changeWriting(change: boolean) {
    this.writing.next(change);
  }

  changeCurrentSession(change: string) {
    this.sessionId.next(change);
  }

  changeLang(change: string) {
    this.langChatbot.next(change);
    if (change == 'en') {
      this.sender = 'You'
    } else if (change == 'es') {
      this.sender = 'Tú'
    }
  }

  changeSound(change: boolean) {
    this.soundOff.next(change);
  }

  playAudio() {
    let audio = new Audio();
    audio.src = "https://d3aiotmb6js9zr.cloudfront.net/assets/sounds/messageSound.mp3";
    audio.load();
    if (this.soundOff.getValue() == false) {
      audio.play();
    }
  }

  convTime(type: string) {
    let time = new Date();
    let hrs = time.getHours();
    let min = time.getMinutes();
    let sec = time.getSeconds();
    let day = time.getDate();
    let month = time.getMonth();
    let year = time.getFullYear();
    //UwU
    if (type == "date") {
      return `${day}-${month}-${year}`;
    } else {
      if (hrs < 12) {
        if (hrs == 0) {
          if (min < 10) {
            return `${hrs + 12}:0${min} AM`;
          } else {
            return `${hrs + 12}:${min} AM`;
          }
        } else {
          if (min < 10) {
            return `${hrs}:0${min} AM`;
          } else {
            return `${hrs}:${min} AM`;
          }
        }
      } else {
        if (hrs == 12) {
          if (min < 10) {
            return `${hrs}:0${min} PM`;
          } else {
            return `${hrs}:${min} PM`;
          }
        } else {
          if (min < 10) {
            return `${hrs - 12}:0${min} PM`;
          } else {
            return `${hrs - 12}:${min} PM`;
          }
        }
      }
    }
  }

  uploadFile(formData: FormData) {
    return this.http.post(this.urlUploadFile, formData).subscribe((res: any) => {
      console.log(res);

    })
  }
}
