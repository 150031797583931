import { Component, OnInit } from '@angular/core';
import { CAT_FRAGMENT_ID } from 'src/app/constants/search-word.const';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  readonly IDS = CAT_FRAGMENT_ID;

  year: number | undefined;

  constructor() { }

  ngOnInit(): void {
    this.year = (new Date()).getFullYear();
  }

}
