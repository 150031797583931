import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
/** OPERADORES */
import { BehaviorSubject, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Servicio de dimensiones del navegador.
 *
 * @export
 * @class DimentionService
 */
@Injectable({ providedIn: 'root' })
export class DimentionService {
  /** Variable que consigue la coordenada de Y en scroll */
  scrollYCoord = new BehaviorSubject<number>(1);

  /** Variable que consigue el tamaño del ancho de la pantalla */
  widthSize = new BehaviorSubject<number>(1);

  /** Booleano que detecta si la renderización se está ejecutando en navegador */
  isBrowser: boolean;
  /***almacena el ancho de un elemento */
  viewHeight = new BehaviorSubject<number>(0);

  /**
   * Creates an instance of PageDimentionsService.
   * @param {*} platformId
   * @memberof PageDimentionsService
   */
  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    this.isBrowser = isPlatformBrowser(this.platformId);

    this.getScrollY();
    this.getWidthScreen();
  }

  /**
   * Método que consigue las coordenadas del scroll en Y
   * @memberof PageDimentionsService
   */
  getScrollY() {
    if (this.isBrowser) {
      this.scrollYCoord.next(window.scrollY);
      fromEvent(window, 'scroll')
        .pipe(map(() => window.scrollY))
        .subscribe(coord => this.scrollYCoord.next(coord));
    }
  }

  /**
   * Método que consigue el ancho de la pantalla
   * @memberof PageDimentionsService
   */
  getWidthScreen() {
    if (this.isBrowser) {
      this.widthSize.next(window.innerWidth);
      fromEvent(window, 'resize')
        .pipe(map(() => window.innerWidth))
        .subscribe(size => this.widthSize.next(size));
    }
  }

  getDevice() {
    const getAgent = window.navigator.userAgent;

    if ((getAgent.match(/iPhone|iPod|Android|BlackBerry|Windows Phone/i)) && window.innerWidth <= 576) {
      return 'mobile';
    } else if ((getAgent.match(/iPad|Android|BlackBerry/i)) && window.innerWidth <= 768) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }
}
