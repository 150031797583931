import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { LoaderService } from './services/loader/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = '2bcore';
  loader = false;
  isBrowser: boolean;
  constructor(@Inject(PLATFORM_ID,) platformId: Object,
  public angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
  private loaderService: LoaderService){
    this.isBrowser = isPlatformBrowser(platformId);
    angulartics2GoogleAnalytics.startTracking();
		if ((<any>window).ga) {
			(<any>window).ga('create', 'UA-131748846-1', 'auto');
			(<any>window).ga('send', 'pageview');
			console.log('Google Analytics created');
		}
  }

  ngOnInit() {
    window.scroll(0,0);
    this.loaderService.isLoading.next(true);
    this.loaderService.isLoading.subscribe(stateCurrent => {

      setTimeout(() => {
        this.loader = stateCurrent;
      }, 100);
    });
    setTimeout(() => {
      this.loaderService.isLoading.next(false);
    }, 3300);
  }

  
}
