import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router,NavigationStart, Event as NavigationEvent } from '@angular/router';
import { TranslationService } from '../../services/translate/translation.service';
import { Angulartics2 } from 'angulartics2';
import { CAROUSEL_DATA, CAROUSEL_DATA_ENG, CAT_COMPONENT, CAT_SECTION } from 'src/app/constants/search-word.const';
import { TowersService } from 'src/app/services/towers.service';
import { ALLIANCES_SECTIONS } from 'src/app/constants/alliances.const';

interface SectionData {
    id: CAT_SECTION,
    name: string,
    keyWords: string[]
}

interface ComponentData {
  view: CAT_COMPONENT,
  sections: SectionData[]
}

interface Redirect {
  name: string,
  redirto: string,
  active: boolean,
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showBoxLang: boolean = true;
  searchTerm = '';
  components: ComponentData[] = [];
  allComponents: ComponentData[] = [];
  alliances = ALLIANCES_SECTIONS;

  currentLang: string | any;
  type = 'password';
  isSearch = false;
  optionsRedirect = [
    {
      name: 'Inicio',
      redirto: '/inicio',
      active: false
    },
    {
      name: 'Servicios',
      redirto: '/servicios',
      active: false
    },
    {
      name: 'Nosotros',
      redirto: '/nosotros',
      active: false
    },
    {
      name: 'Carreras',
      redirto: '/carreras',
      active: false
    },
    
    {
      name: 'RedHat',
      redirto: '/red-hat',
      active: false
    },
    {
      name: 'IBM',
      redirto: '/ibm-sterling',
      active: false
    },
    {
      name: 'Alianzas',
      redirto: '/alianzas',
      active: false
    },
    {
      name: 'Contacto',
      redirto: '/contacto',
      active: false
    },
  ]

  inicio = {
    name: 'Inicio',
    redirto: '/inicio',
    active: false
  }

  searchComponetRedirect = new Map<CAT_COMPONENT, Redirect>([

    [CAT_COMPONENT.HOME_PAGE, this.optionsRedirect[0]],

    [CAT_COMPONENT.SERVICIOS, this.optionsRedirect[1]],

    [CAT_COMPONENT.NOSOTROS, this.optionsRedirect[2]],

    [CAT_COMPONENT.ALIANZAS, this.optionsRedirect[4]],

    [CAT_COMPONENT.CARRERAS, this.optionsRedirect[3]],

    [CAT_COMPONENT.COOKIES, this.optionsRedirect[0]],

    [CAT_COMPONENT.FOOTER, this.optionsRedirect[0]],

  ]);

  searchWordsByLang = new Map<string, any>([
    ['es', CAROUSEL_DATA],
    ['en', CAROUSEL_DATA_ENG],
  ]);

  constructor(
    //private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    public translation: TranslationService,
    private angulartics2: Angulartics2,
    private changeDetectorRef: ChangeDetectorRef,
    private tower: TowersService
  ) {}

  formLogin: UntypedFormGroup = this.formBuilder.group({
    user: ['', Validators.required],
    password: ['', Validators.required],
  });

  ngOnInit(): void {
    this.translation.currentLang.observables.lang.subscribe((lang : any) => {
      this.currentLang = lang
      this.search(this.searchTerm);
    });
   this.router.events
   .subscribe(
     (event: NavigationEvent) => {
       if(event instanceof NavigationStart) {
         
        switch (event.url) {
          case '/inicio':
            this.setActiveRoute(0);
            break;
          case '/servicios':
            this.setActiveRoute(1);
            this.tower.activeRoute.next(event.url);
            break;
          case '/nosotros':
            this.setActiveRoute(2);
            break;
          case '/carreras':
            this.setActiveRoute(3);
            break;
          case '/red-hat':
            this.setActiveRoute(4);
            break;
            case '/ibm-sterling':
              this.setActiveRoute(4);
              break;
          case '/contacto':
            this.setActiveRoute(6);
            break;
          default: 
          this.optionsRedirect.forEach((route: any, idx: any) => {
            route.active = false;
            });
            break;
        }
       }
     });
  }

/**
 *
 *
 * @param {*} index
 * @memberof HeaderComponent
 */
setActiveRoute(index: any) {
 setTimeout(() => {
  this.optionsRedirect.forEach((route: any, idx: any) => {
    if (index === idx) {
      route.active = true;
    } else {
      route.active = false;
    }
  });
 },100);
  }
 
   setLang(item: string) {
		this.angulartics2.eventTrack.next({
			action: 'click',
			properties: {
				category: 'Navbar',
				value: 15,
				label: 'Move to ' + item
			}
		});
	}
 
  showPassword() {
    this.type = this.type === 'text' ? 'password' : 'text';
  }

  validateEnter(event: any) {

    
    if (event.key === "Enter" && this.components.length > 0) {
      const id = this.components[0].sections[0].id; 
      const idRedirect =`${this.components[0].view}${id}`;
      document.getElementById(idRedirect.toString())?.click();
      this.searchTerm = '';
    }
    
  }

  showInputSearching() {
    this.isSearch = !this.isSearch;
    
     if (this.isSearch) {
      document.getElementById("inputSearch")?.focus();
      this.changeDetectorRef.detectChanges(); 
     }
  }

  search(toSearch: string) {
    this.components = [];

    if (toSearch.length < 1) {
      return
    }
  
    this.allComponents = this.searchWordsByLang.get(this.currentLang);

    this.allComponents.forEach((component) => {
      const componentResult: ComponentData = {
        view: component.view,
        sections: []
      };
      
      component.sections.forEach((section) => {
        const resultKeyWord = section.keyWords.filter((keyWord) => {
          // Comparación de las palabras clave
          const propertyNameRegex = new RegExp(toSearch.toLowerCase(), 'gi');
          return propertyNameRegex.test(keyWord.toLowerCase());
        })

        if (resultKeyWord.length > 0) {
          // Palabras que coinciden por seccion
          const sectionResult: SectionData = {
            id: section.id,
            name: section.name,
            keyWords: resultKeyWord
          }
          componentResult.sections.push(sectionResult)
        }
      });

      // Secciones resultantes por componente
      if (componentResult.sections.length > 0) {
        this.components.push(componentResult)
      }
    });

  }

  services = [
    CAT_SECTION.SolutionsDevelopment,
    CAT_SECTION.SpecializedServices,
    CAT_SECTION.NormalizationProcesses,
    CAT_SECTION.Institute,
    CAT_SECTION.Talents,
  ]

  setSlide(slideId: CAT_SECTION) {
    
    const isService = this.services.includes(slideId);

    if (isService) {
      this.tower.activeTowerService.next(slideId.toString());
      return;
    }

    const isAlliance = this.alliances.includes(slideId);
    if (isAlliance) {
      
      this.tower.activeAlliance.next(slideId.toString());
      return;
    }
  }

}
