<div class="general-heigth">
  <div class="container padding-mobile pe-md-0" style="margin-top: 90px" id="{{IDS.careers}}">
    <div class="row separate-back px-md-0">
      <div class="d-flex body-text align-items-center px-md-0" (click)="goToHome()" style="cursor: pointer; width: fit-content">
        <span class="icon-Regresar me-2 fs-18" (click)="goToHome()" style="cursor: pointer"></span>
       <span class=" d-none d-md-block"> {{ "back.btn" | translate }}</span>  
      </div>
    </div>
    <div class="row m-0 mb-5 px-md-0">
        <div class="col-md-6 d-flex flex-column text-center justify-content-center mb-5 mb-md-0 order-2 order-md-1">
          <video loop autoplay muted playsinline oncanplay="this.play()" onloadedmetadata="this.muted = true"
            class="img-careers margin-body mx-auto">
            <source src="https://2bcore-site.s3.us-east-2.amazonaws.com/public/siteAnimations/carreras.mp4" type="video/mp4" />
          </video>
          <div class="d-md-none">
            <a class="btn-bubbleWhite m-auto" href="mailto:hr@2bcore.com" (click)="showLoader()" aria-label="enviar-mail">
              {{ "careers.btn" | translate }}
            </a>
          </div>
        </div>
        <div class="col-md-6 text-center text-md-end justify-content-end order-1 order-md-2 margin-text pe-lg-0">
          <div class="">
            <h1 class="body-title-2 text-center text-md-end margin-title">
              {{ "careers.title" | translate }}
            </h1>
            <p class="body-text margin-body">{{ "careers.description" | translate }}</p>
          </div>
          <div class="d-none d-md-block">
            <a class="btn-bubbleWhite ms-auto" href="mailto:hr@2bcore.com" aria-label="enviar mail" (click)="showLoader()">
              {{ "careers.btn" | translate }}
            </a>
          </div>
        </div>
       
    </div>
  </div>
  <div class="container-fluid d-flex cont-collaborators" id="{{IDS.collaborators}}">
      <div class="container">
        <div class="row h-100">
          <div class="col-lg-5 d-flex flex-column align-items-center mb-4 mb-lg-0 justify-content-center">
            <h2 class="body-title-2 ">
              {{ "careers.collaboration" | translate }}
            </h2>
            <p class="body-text">{{ "careers.coll-desc" | translate }}</p>
          </div>
          <div class="col-lg-7 d-flex flex-column justify-content-center position-carousel">
            <swiper [config]="swiperConfig" class="w-100" (slideChange)="initSlidersTestimonios($event)">
              <ng-template *ngFor="let card of 'careers.cards' | translate" class="d-flex align-items-center" swiperSlide>
                <div class="card-carrusel m-auto m-lg-0">
                  <div class="card-body h-100">
                    <p class="card-text fw-regular">"{{ card.text }}".</p>
                    <div class="d-flex">
                      <img src="{{card.img}}" class="card-img" alt="card-img" />
                      <div class="ml-2 align-items-center">
                        <h5 class="card-name">{{ card.name }}</h5>
                        <p class="card-puesto fw-regular">{{ card.puesto }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </swiper>
            <div class="d-flex justify-content-center  justify-content-lg-start" style="margin-top: 25px">
              <div class="icon-down fs-32 cr-pointer" style="transform: rotate(90deg); margin-right: 30px"></div>
              <div class="icon-up fs-32 cr-pointer" style="transform: rotate(90deg)" ></div>
            </div>
          </div>
        </div>
      </div>
  </div>
  <div class="container mb-5 pb-5">
    <div class="row m-0 px-0" id="{{IDS.programs}}">
        <div class="col-md-12 programs">
          <h2 class="body-title mb-5">{{ "careers.programs" | translate }}</h2>
          <p class="bodytext2 fw-regular mb-0">{{ "careers.prog-desc" | translate }}</p>
        </div>
        <div class="col-md-12 d-flex align-items-center justify-content-center" [ngClass]="!showFullContent ? 'd-block' : 'd-none'" >
            <div class=" d-flex justify-content-start"  *ngIf="showFullContent == false">
              <span class="icon-left-d fs-32 cr-pointer"></span>
            </div>
            <div class="col-md-11 m-auto col-9 mx-2 d-flex align-items-center">
              <swiper [config]="swiperConfig2" class="widthCarrusel3" (slideChange)="initSliderPrograms($event)">
                <ng-template *ngFor="let program of ('careers.program' | translate); let i = index"
                  class="d-flex justify-content-center align-items-center card-programs p-7" swiperSlide>
                  <div class="card">
                    <div class="card-body">
                      <img src="{{ program.img }}"  alt="program-img"  class="img-program" [ngClass]="(i === 2 || i === 3) ? 'img-last-programs' : 'w-auto'" >
                      <!-- <video loop autoplay muted playsinline oncanplay="this.play()" onloadedmetadata="this.muted = true" *ngIf="showFullContent == false"
                        class="img-program">
                        <source src="{{ program.img }}" type="video/mp4" />
                      </video> -->
                      <h5 class="card-title fw-bold">{{ program.title }}</h5>
                      <p class="card-text text-center">{{ program.text }}</p>
                      <button (click)="showContent(i)" class="btn-bubbleWhite button-card-car" aria-label="Ver más">
                        {{ "careers.btn-more" | translate }}
                      </button>
                    </div>
                  </div>
                </ng-template>
              </swiper>
            </div>
            <div class=" d-flex justify-content-end">
              <span class="icon-right-d fs-32 cr-pointer"></span>
            </div>
        </div>
  
      <div class="row mx-auto" style=" margin-top: 15px;margin-bottom: 10px;" [ngClass]="showFullContent ? 'd-block' : 'd-none'">
        <div class="col-12 col-md-11 m-auto d-flex align-items-center">
          <div class="col-md-10 full-content d-flex">
              <div class="d-flex"
                >
                <div class="programs-responsive">
                  <div class="close-responsive">
                    <span (click)="closeContent()" class="icon-close-gray fs-32 cr-pointer"><span
                        class="path1"></span><span class="path2"></span></span>
                  </div>
                  <div class="col-md-4 d-flex">
                    <div class="d-flex flex-column align-items-center h-100 justify-content-around text-center ms-2 mb-3">
                      <img src="{{ activePrograms.img }}"  alt="img-program"  class="img-program" style="width: auto;"
                      >
                   
                      <h3 class="text-bold mb-3 card-intern-title">{{ activePrograms.title }}</h3>
                      <p class="small-text container mb-3 card-intern-text">{{ activePrograms.text }}</p>
                      <button class="btn-bubble" style="height: 50px;" (click)="openLoader()" [id]="activePrograms.idChatbot" aria-label="Únete a nosotros">
                        {{ "careers.btn-join" | translate }}
                      </button>
                    </div>
                  </div>
                  <div class="col-md-7 d-flex flex-column align-self-center px-3 mt-5">
                    <h4 class="mb-4 text-start card-intern-body">{{ activePrograms.subTitle }}</h4>
                    <div class="d-flex" *ngFor="let element of activePrograms.info">
                      <span class="icon-bullet my-1 me-1"><span class="path1"></span><span class="path2"></span></span>
                      <p class="texto">{{ element }}</p>
                    </div>
                  
                  </div>
                  <div class="col-md-1">
                    <div class="close">
                      <span (click)="closeContent()" class="icon-close-gray fs-32 cr-pointer"><span
                          class="path1"></span><span class="path2"></span></span>
                    </div>
                  </div>
               
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>