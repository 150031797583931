import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { TranslationService } from 'src/app/services/translate/translation.service';
import { Angulartics2 } from 'angulartics2';
import { CAT_FRAGMENT_ID } from 'src/app/constants/search-word.const';
import { LoaderService } from 'src/app/services/loader/loader.service';

SwiperCore.use([ Navigation, Autoplay ]);

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {
  readonly IDS = CAT_FRAGMENT_ID;

  showFullContent: boolean = false  ;
  currentLang: string|any;
  activeIndexProgram  = 0;
  activeIndexColab = 0;
 

  swiperConfig: any = {
    loopAdditionalSlides: 0,
    allowTouchMove: true,
    slideToClickedSlide: true,
    initialSlide: 0,
    loopedSlides: 5,
    loop : true,
    on: { 
      realIndexChange: (index: any) => { 
        this.activeIndexColab = index.realIndex;
        console.log( this.activeIndexColab);
          this.changeDetectorRef.detectChanges();
      }, 
    },
    navigation: {
      nextEl: '.icon-up',
      prevEl: '.icon-down',
    },
    breakpoints: {
       1900: {
        slidesPerView: 3,
        centeredSlides: false,
        
      },
      992: {
        slidesPerView: 2.1,
        centeredSlides: false,
        
      },
      768: {
        slidesPerView: 1,
        centeredSlides: true,
        
      },
      576: {
        slidesPerView: 1,
        centeredSlides: true,
        
      },
      375: {
        slidesPerView: 1,
        centeredSlides: true,
        
      }
    }
  }

  swiperConfig2: any = {
    loopAdditionalSlides: 0,
    allowTouchMove: true,
    slideToClickedSlide: true,
    initialSlide: 0,
    loopedSlides: 3,
    loop : true,
    on: { 
      realIndexChange: (index: any) => { 
        this.activeIndexProgram = index.realIndex;
          this.changeDetectorRef.detectChanges();
      }, 
    },
    spaceBetween: 16,
    navigation: {
      nextEl: '.icon-right-d',
      prevEl: '.icon-left-d',
    },
    breakpoints: {
      992: {
        slidesPerView: 3,
        spaceBetween: 16,
        centeredSlides:false
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 16,
        centeredSlides:true
      },
      576: {
        slidesPerView: 1,
        spaceBetween: 16,
        centeredSlides:true
      },
      375: {
        slidesPerView: 1,
        spaceBetween: 16,
        centeredSlides:true
      }
    }
  }
 
  swiperAuxTest?: SwiperCore;

  swiperAuxPrograms?: SwiperCore;
  cards = [
    {
      text: 'Cada proyecto es un reto que solidifica los resultados en calidad y experiencia',
      img: 'assets/images/Testimonios/jorgeavila.jpg',
      puesto: 'Gerente CX',
      name: 'Jorge Ávila'
    },
    {
      text: '2BCORE Es el luger ideal para dar el primer paso hacia el campo laboral',
      img: 'assets/images/Testimonios/Lucero.png',
      puesto: 'Consultor UX',
      name: 'Lucero Jiménez'
    },
    {
      text: 'Lo que más me gusta de 2BCORE es la constante adquisición de expereicncia por medio de múltiples proyectos en los que se me permite colaborar',
      img: 'assets/images/Testimonios/Jesus.jpeg',
      puesto: 'Full Stack Leader',
      name: 'Jesús Torres'
    },
    {
      text: '2BCORE te ofrece muchas oportunidades para el desarrollo profesional y personal, además de impulsarte para el cumplimiento de tus metas y objetivos',
      img: 'assets/images/Testimonios/Yessenia.jpg',
      puesto: 'Gerente de Calidad',
      name: 'Yessenia Ruiz'
    },
    {
      text: 'Siempre se ha tratado de mantener la filosofía de ser todos una familia, sin importar los retos que van surgiendo en el camino',
      img: 'assets/images/Testimonios/Ehdrei Aramburo.jpeg',
      puesto: 'Gerente Centro de Desarrollo',
      name: 'Ehdrei Aramburo'
    },
    {
      text: '2BCORE Te motiva a superarte cada día y te ayuda a cumplir tus metas profesionales',
      img: 'assets/images/Testimonios/Shantal.jpeg',
      puesto: 'Ventas corporativas',
      name: 'Shantal Lavín'
    }
  ]


  programEn = [
    {
      title: 'Scholars',
      text: 'IT students interested in generating experience and developing their potential.',
      img: 'https://2bcore-site.s3.us-east-2.amazonaws.com/public/carreras/Becarios.gif',
      subTitle: 'Start your professional career with us',
      idChatbot: 'scholars',
      info: [
        'Develop experience in the IT area by participating in real projects.',
        'Participate in courses and certifications to enrich your profile.',
        'Learn about tools and equipment to develop your activities.',
        'Flexible and hybrid schedules that allow you to combine work and school.',
        'Financial scholarship and minor medical expenses insurance.',
        'Professional growth and development.',
        'Learn from the experts.'
      ]
    },
    {
      title: 'New Graduates',
      text: 'We want to share our passion for what we do, guide you and support you in your professional growth.',
      img: 'https://2bcore-site.s3.us-east-2.amazonaws.com/public/carreras/Reci%C3%A9n+egresados.gif',
      subTitle: 'Join the workplace',
      idChatbot: 'graduates',
      info: [
        'Grow within the IT area.',
        'Participate in courses and certifications to enrich and develop your profile.',
        'Have a career plan to grow within the area of your interest.'
      ]
    },
    {
      title: 'Professionals',
      text: 'Add your experience to our team so that together, we can make a difference with our clients.',
      img: 'https://2bcore-site.s3.us-east-2.amazonaws.com/public/carreras/Profesionales.gif',
      subTitle: 'Become a part of our expert consultants.',
      idChatbot: 'professionals',
      info: [
        'Benefits in excess of the law.',
        'Additional days off in addition to those established by law.',
        'Minor and/or major medical expenses insurance.',
        'Hybrid schedules.',
        'Continuous training.',
        'Career plan.',
        'National and international mobility program.',
        'Ophthalmological and dental support.'  
      ]
    },
    {
      title: 'Social service or professional internship',
      text: 'Consolidate your professional training in a professional environment.',
      img: 'https://2bcore-site.s3.us-east-2.amazonaws.com/public/carreras/Servicio+Social.gif',
      subTitle: 'Acquire and apply your knowledge',
      idChatbot: 'socialService',
      info: [
        'Apply your acquired knowledge in different areas of study.',
        'Increase intellectual capital, through the experience generated.',
        'Network with other students from different disciplines involved in the achievement of projects.' 
      ]
    }
    ]
  programs = [
    {
      title: 'Becarios',
      text: 'Estudiantes de carreras TI interesados en generar experiencia y desarrollar su potencial.',
      img: 'https://2bcore-site.s3.us-east-2.amazonaws.com/public/carreras/Becarios.gif',
      subTitle: 'Inicia tu carrera profesional con nosotros',
      idChatbot: 'scholars',
      info: [
        'Desarrolla experiencia en el área de TI participando en proyectos reales.',
        'Participa en cursos y certificaciones para enriquecer tu perfil.',
        'Conoce herramientas y equipo para desarrollar tus habilidades.',
        'Horarios flexibles e híbridos que te permitan combinar el trabajo con tu escuela.',
        'Beca económica y seguro de gastos médicos menores.',
        'Crecimiento y desarrollo profesional.',
        'Aprende de los expertos.'
      ]
    },
    {
      title: 'Recién egresados',
      text: 'Queremos compartirles la pasión por lo que hacemos, guiarte y apoyarte en tu crecimiento profesional.',
      img: 'https://2bcore-site.s3.us-east-2.amazonaws.com/public/carreras/Reci%C3%A9n+egresados.gif',
      subTitle: 'Intégrate al ámbito laboral',
      idChatbot: 'graduates',
      info: [
        'Crece dentro del área de TI.',
        'Participa en cursos y certificaciones para enriquecer y desarrollar tu perfil.',
        'Cuenta con un plan de carrera para crecer dentro del área de tu interés.'
      ]
    },
    {
      title: 'Profesionales',
      text: 'Suma tu experiencia al equipo de trabajo para que juntos, marquemos la diferencia con nuestros clientes.',
      img: 'https://2bcore-site.s3.us-east-2.amazonaws.com/public/carreras/Profesionales.gif',
      subTitle: 'Sé parte de nuestros consultores expertos',
      idChatbot: 'professionals',
      info: [
        'Prestaciones superiores a la ley.',
        'Días de descanso adicionales a los que marca la ley.',
        'Seguro de gastos médicos menores y/o mayores.',
        'Horarios híbridos.',
        'Capacitación continua.',
        'Plan de carrera.',
        'Programa de movilidad nacional e internacional.',
        'Apoyo oftalmológico y odontológico.'  
      ]
    },
    {
      title: 'Servicio Social o Prácticas profesionales',
      text: 'Consolida tu formación profesional en un ambiente profesional.',
      img: 'https://2bcore-site.s3.us-east-2.amazonaws.com/public/carreras/Servicio+Social.gif',
      subTitle: 'Adquiere y aplica tus conocimientos',
      idChatbot: 'socialService',
      info: [
        'Aplica tus conocimientos adquiridos en diferentes áreas de estudio.',
        'Incrementar el capital intelectual, a través de la experiencia generada.',
        'Relaciónate con otros estudiantes de distintas disciplinas involucradas en el logro de proyectos.' 
      ]
    }
  ]

  activePrograms:any


  constructor( private router: Router,
    public translation: TranslationService,
    private angulartics2: Angulartics2,
    private loaderService: LoaderService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
   
    this.loaderService.isLoading.next(true);
    setTimeout(() => {
      this.loaderService.isLoading.next(false);
     
    }, 1000);
    this.translation.currentLang.observables.lang.subscribe((lang : any) => 
    {
      this.currentLang = lang;
      console.log(lang);   
      this.currentLang === 'es' ? this.activePrograms = this.programs[this.activeIndexProgram] : this.activePrograms = this.programEn[this.activeIndexProgram]
      this.changeDetectorRef.detectChanges();
    }
  );
  this.changeDetectorRef.detectChanges();
  }
  initSlidersTestimonios(e:any) {
    this.swiperAuxTest = e;
  }

  initSliderPrograms(e: SwiperCore[]) {
    this.swiperAuxPrograms = e[0];
    
  }
  showLoader() {
    this.loaderService.isLoading.next(true);
    setTimeout(() => {
      this.loaderService.isLoading.next(false);
    },2500);
  }
  showContent(i: any){
    this.activeIndexProgram = i;
    this.showFullContent = true;
    this.currentLang === 'es' ? this.activePrograms = this.programs[this.activeIndexProgram] : this.activePrograms = this.programEn[this.activeIndexProgram]
    this.changeDetectorRef.detectChanges();
  }  

  closeContent(){
    this.loaderService.isLoading.next(true);
    setTimeout(() => {
      this.loaderService.isLoading.next(false);
    },1700);
    this.showFullContent = false;
    this.swiperAuxPrograms?.slideToLoop(0);
    this.changeDetectorRef.detectChanges();
  }


  openLoader() {
    this.loaderService.isLoading.next(true);
    setTimeout(() => {
      this.loaderService.isLoading.next(false);
    },1500);
  }
  goToHome(){
    this.router.navigate(['/inicio'])
  }

  
  
}