<div class="content-cookie" [hidden]="cookiesAccepted==='true' || !isShowBanner">
</div>
    <!-- Cookie Banner -->
<div class="cookie-banner cookie-hresponsive position-fixed bottom-0 w-100"
[hidden]="cookiesAccepted==='true' || !isShowBanner">
<div class="container pb-5 custom-padding-cookie">
    <p class="fw-bold fs-24 fsm-24 lh-29 text-center text-md-start mt-5 mb-4"><span class="icon-cookies me-3"><span
                class="path1"></span><span class="path2"></span><span class="path3"></span><span
                class="path4"></span><span class="path5"></span><span class="path6"></span><span
                class="path7"></span><span class="path8"></span><span class="path9"></span><span
                class="path10"></span></span>{{ "footer.cookies.title" | translate }}</p>
    <p class="fs-16 fsm-14 text-justify lh-22 margin-buttons text-heigth-mob">{{ "footer.cookies.banner" | translate }} <span class="cookie-bannergrid mt-2 mb-4 m-md-0"><a
                class="txt-blue-link text-center text-md-start fw-bold fs-16 fsm-14 lh-22 mt-2 text-decoration-none"
                style="cursor: pointer;" (click)="openModal()">{{ "footer.cookies.open-modal" | translate }}</a></span></p>

    <div class="d-flex flex-column flex-md-row align-items-center">
        <button class="btn-bubbleGray fs-18 fsm-16 lh-22 me-md-3 mx-5 mb-3 mb-md-0 mx-md-0" style="width: 250px;" aria-label="Ver cookies info"
            (click)="allCookiesBanner()">{{ "footer.cookies.btn-allow" | translate }}</button>
        <button class="btn-bubbleGray  fs-18 fsm-16 lh-22 me-md-2 mx-5 mx-md-0" style="width: 250px;" aria-label="Ver preferencias cookies"
            (click)="cookiePreferencesShow()">{{ "footer.cookies.btn-gest" | translate }}</button>
    </div>
</div>
</div>

<!-- Cookie Preferences -->
<div class="cookie-preferences position-fixed bottom-0 w-100 pb-5" [hidden]="!isShowPreferences">
<div class="container">
    <div class="row mx-3">
        <div class="col-12">
            <p class="fw-bold fs-24 lh-29 text-center text-md-start mt-5"><span class="icon-cookies me-3"><span
                        class="path1"></span><span class="path2"></span><span class="path3"></span><span
                        class="path4"></span><span class="path5"></span><span class="path6"></span><span
                        class="path7"></span><span class="path8"></span><span class="path9"></span><span
                        class="path10"></span></span>{{ "footer.cookies.title" | translate }}</p>
            <p class=" txt-principal-gray text-center text-md-start fs-18 fsm-16 lh-24 my-auto pb-4 pt-2">
                {{ "footer.preferences.title-gest" | translate }}
            </p>
        </div>

        <div class="col-12 px-0 my-auto">
            <div class="accordion" id="accordionCookies">
                <div class="accordion-item bg-transparent text-white border-0 pb-4">
                    <div class="accordion-header text-decoration-none text-reset  d-flex justify-content-between"
                        id="headingOne">
                        <a class="d-flex bg-transparent fw-bold text-white text-decoration-none ps-0" type="button"
                            data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-label="Abrir acordeon" 
                            aria-controls="collapseOne" (click)="changeActiveCollapse('strict')">
                            <div class="transition-list my-auto" [ngClass]="listStrict ? 'list-collapse' : ''">
                                <span
                                    class="icon-collapsed fs-10"></span></div>
                            <p class="my-auto ms-3 fs-16 fsm-14">{{ "footer.preferences.necesary" | translate }}</p>
                        </a>
                        <p class="fw-bold fs-16 fsm-14 lh-22 py-auto m-0 text-end">{{ "footer.preferences.always" | translate }}</p>
                    </div>
                    <div id="collapseOne" data-bs-parent="#accordionCookies" class="accordion-collapse collapse"
                        aria-labelledby="headingOne">
                        <div class="accordion-body p-0">
                            <p class="fw-medium fs-16 fsm-14 lh-22 mt-3">{{ "footer.preferences.text-necesary" | translate }}</p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item bg-transparent text-white border-0 pb-4">
                    <div class="accordion-header text-decoration-none text-reset  d-flex justify-content-between"
                        id="headingTwo">
                        <a class="d-flex bg-transparent fw-bold text-white text-decoration-none ps-0" type="button"
                            data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-label="Abrir acordeon" 
                            aria-controls="collapseTwo" (click)="changeActiveCollapse('performance')">
                            <div class="transition-list" [ngClass]="listPerformance ? 'list-collapse' : ''"><span
                                    class="icon-collapsed fs-10"></span></div>
                            <p class="my-auto ms-3 fs-16 fsm-14">{{ "footer.preferences.rend" | translate }}</p>
                        </a>
                        <div class="col-2 form-check form-switch d-flex justify-content-end form-switch-md p-0 me-0 py-auto">
                            <label class="toggler-wrapper style-1">
                                <input type="checkbox"
                                (click)="performanceCookies=!performanceCookies; switch()"
                                [checked]="performanceCookies===true">
                                <div class="toggler-slider">
                                  <div class="toggler-knob"></div>
                                </div>
                              </label>
                        </div>
                    </div>
                    <div id="collapseTwo" data-bs-parent="#accordionCookies" class="accordion-collapse collapse"
                        aria-labelledby="headingTwo">
                        <div class="accordion-body p-0">
                            <p class="fw-medium fs-16 fsm-14 lh-22 mt-3">{{ "footer.preferences.text-rend" | translate }}</p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item bg-transparent text-white border-0 pb-4">
                    <div class="accordion-header text-decoration-none text-reset d-flex justify-content-between"
                        id="headingThree">
                        <a class="d-flex bg-transparent fw-bold text-white text-decoration-none ps-0" type="button"
                            data-bs-toggle="collapse" data-bs-target="#collapseThree" aaria-expanded="false" aria-label="Abrir acordeon" 
                            aria-controls="collapseThree" (click)="changeActiveCollapse('functional')">
                            <div class="transition-list" [ngClass]="listFunctional ? 'list-collapse' : ''"><span
                                    class="icon-collapsed fs-10"></span></div>
                            <p class="my-auto ms-3 fs-16 fsm-14">{{ "footer.preferences.functionals" | translate }}</p>
                        </a>
                        <div class="col-2 form-check d-flex justify-content-end form-switch form-switch-md p-0 me-0 py-auto">
                            <label class="toggler-wrapper style-1">
                                <input type="checkbox"
                                (click)="functionalCookies=!functionalCookies;switch()"
                                [checked]="functionalCookies===true">
                                <div class="toggler-slider">
                                  <div class="toggler-knob"></div>
                                </div>
                              </label>
                        </div>
                    </div>
                    <div id="collapseThree" data-bs-parent="#accordionCookies" class="accordion-collapse collapse"
                        aria-labelledby="headingThree">
                        <div class="accordion-body p-0">
                            <p class="fw-medium fs-16 fsm-14 lh-22 mt-3">{{ "footer.preferences.text-functionals" | translate }}</p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item bg-transparent text-white border-0 pb-4">
                    <div class="accordion-header text-decoration-none text-reset d-flex justify-content-between"
                        id="headingFour">
                        <a class="d-flex bg-transparent fw-bold text-white text-decoration-none ps-0" type="button" aria-label="Abrir acordeon" 
                            data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false"
                            aria-controls="collapseFour" (click)="changeActiveCollapse('analytics')">
                            <div class="transition-list" [ngClass]="listAnalytics ? 'list-collapse' : ''"><span
                                    class="icon-collapsed fs-10"></span></div>
                            <p class="my-auto ms-3 fs-16 fsm-14">{{ "footer.preferences.analitycs" | translate }}</p>
                        </a>
                        <div class="col-2 form-check d-flex justify-content-end form-switch form-switch-md p-0 me-0 py-auto">
                            <label class="toggler-wrapper style-1">
                                <input type="checkbox"
                                (click)="analyticCookies=!analyticCookies;switch()"
                                [checked]="analyticCookies===true">
                                <div class="toggler-slider">
                                  <div class="toggler-knob"></div>
                                </div>
                              </label>
                          
                        </div>
                    </div>
                    <div id="collapseFour" data-bs-parent="#accordionCookies" class="accordion-collapse collapse"
                        aria-labelledby="headingFour">
                        <div class="accordion-body p-0">
                            <p class="fw-medium fs-16 fsm-14 lh-22 mt-3">{{ "footer.preferences.text-analitycs" | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column flex-md-row align-items-center mt-4">
            <button class="btn-bubbleGray fs-18 fsm-16 lh-22 me-md-2 mb-3 mb-md-0 mx-4 mx-md-0 px-3" aria-label="Ocultar banner cookies"
                style="width: 250px;" (click)="cookieBannerHide()">{{ "footer.preferences.btn-confirm" | translate }}</button>
            <button   style="width: 250px;"  class="btn-bubbleGray fs-18 fsm-16 lh-22 me-md-2 mx-4 mx-md-0" aria-label="Ocultar banner cookies"
                (click)="cookieBannerHide(); isShowBanner=true; isShowPreferences=false">{{ "footer.preferences.btn-reject" | translate }}</button >
        </div>
    </div>
</div>
</div>

<!-- Cookie Modal -->
<div class="modal fade" id="cookieModal" tabindex="-1" aria-labelledby="cookieModalLabel" aria-hidden="true">
<div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content cookie-modal">
        <div class="modal-header border-0">
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"  aria-label="Cerrar modal"
                aria-label="Close"></button>
        </div>
        <div class="modal-body cookie-modal-text mx-3 mx-md-5">
            <p class="fw-bold fs-28 fsm-16 lh-34 text-center mt-0 mb-4 mt-md-2 mb-md-5" id="cookieModalLabel">
                {{ "footer.use.title" | translate }}</p>
            <p class="fs-14 fsm-12 lh-17 justify-text">{{ "footer.use.p1" | translate }} </p>

            <p class="fw-medium fs-22 fsm-14 lh-27 my-4">{{ "footer.use.p2" | translate }}</p>

            <p class="fs-14 fsm-12 lh-17 justify-text"> {{ "footer.use.p3" | translate }}</p>

            <p class="fs-14 fsm-12 lh-17 justify-text">{{ "footer.use.p4" | translate }}
            </p>

            <p class="fs-14 fsm-12 lh-17 fw-bold my-4">{{ "footer.use.p5" | translate }}</p>

            <p class="fs-14 fsm-12 lh-17 justify-text">{{ "footer.use.p6" | translate }}</p>

            <p class="fs-14 fsm-12 lh-17 fw-bold my-4">{{ "footer.use.p7" | translate }}</p>

            <p class="fs-14 fsm-12 lh-17 justify-text"> {{ "footer.use.p8" | translate }}</p>

            <p class="fs-14 fsm-12 lh-17 fw-bold my-4">{{ "footer.use.p9" | translate }}</p>

            <p class="fs-14 fsm-12 lh-17 justify-text">{{ "footer.use.p10" | translate }}</p>

            <p class="fs-14 fsm-12 lh-17 fw-bold my-4">{{ "footer.use.p11" | translate }}</p>

            <p class="fs-14 fsm-12 lh-17 justify-text"> {{ "footer.use.p12" | translate }}</p>

            <p class="fw-medium fs-22 fsm-14 lh-27 my-4">{{ "footer.use.p13" | translate }}</p>

            <p class="fs-14 fsm-12 lh-17 fw-bold my-4">{{ "footer.use.p14" | translate }}</p>

            <p class="fs-14 fsm-12 lh-17 justify-text"> {{ "footer.use.p15" | translate }}</p>

            <p class="fs-14 fsm-12 lh-17 fw-bold my-4">{{ "footer.use.p16" | translate }}</p>

            <p class="fs-14 fsm-12 lh-17 justify-text">{{ "footer.use.p17" | translate }}</p>

            <p class="fw-medium fs-22 fsm-14 lh-27 my-4">{{ "footer.use.p18" | translate }}</p>

            <p class="fs-14 fsm-12 lh-17 justify-text">{{ "footer.use.p19" | translate }}</p>

            <p class="fs-14 fsm-12 lh-17 justify-text">{{ "footer.use.p20" | translate }}</p>
        </div>
        <div class="modal-footer border-0 mb-4">
            <button type="button" class="btn btn-secondary mx-auto fs-18 fsm-16 lh-22" aria-label="Cerrar modal"
                data-bs-dismiss="modal">{{ "footer.use.btn" | translate }}</button>
        </div>
    </div>
</div>
</div>
