import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutusComponent } from './modules/aboutus/aboutus.component';
import { AlliancesComponent } from './modules/alliances/alliances.component';
import { CareersComponent } from './modules/careers/careers.component';
import { ContactComponent } from './modules/contact/contact.component';
import { ServiceTowerComponent } from './modules/service-tower/service-tower.component';
import { RedHatComponent } from './modules/microsites/red-hat/red-hat.component';
import { IbmSterlingComponent } from './modules/microsites/imb-sterling/ibm-sterling.component';

const routes: Routes = [
  { path: '', redirectTo: 'inicio', pathMatch: 'full' },
  { path: 'nosotros', component: AboutusComponent },
  { path: 'inicio', loadChildren: () => import('./modules/modules.module').then((m) => m.ModulesModule) },
  { path: 'alianzas', component: AlliancesComponent },
  { path: 'red-hat', loadChildren: () => import('./modules/microsites/red-hat/red-hat.module').then((m) => m.RedHatModule) },
  { path: 'ibm-sterling', loadChildren: () => import('./modules/microsites/imb-sterling/ibm-sterling.module').then((m) => m.IbmSterlingModule) },
  { path: 'servicios', component: ServiceTowerComponent },
  { path: 'carreras', component: CareersComponent },
  { path: 'contacto', component: ContactComponent }
  // {path: 'alliances', component: AlliancesComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
