<div class="bg-footer" id="{{IDS.footer}}">
  <div class="container">
    <div class="row ">
      <div class="col-12 col-lg-4 d-grid justify-content-center justify-content-lg-start align-items-start">
        <div class="d-flex justify-content-center m-auto">
          <img src="assets/logos/2BC_Isotipo_Blanco_2024.svg" alt="2BCore Logo">
        </div>
        <div class="fs-12 mt-2 mb-4 mb-lg-0">
          © 2BCORE {{ year }} .
          <!--v1.0.0 DEV version-->
          <!--v0.0.49 QA version-->
          <!-- v3.1.1 PRE version -->
          <!-- v3.1.1 PROD -->
        </div>
      </div>
      <div class="col-12 col-lg-4 d-grid justify-content-center align-items-center text-center mb-4 mb-lg-0">
        <div class="cr-pointer mb-3 fs-14" data-bs-toggle="modal" data-bs-target="#privacyModal">
          {{ "footer.policy" | translate }}
        </div>
        <div class="cr-pointer fs-14" data-bs-toggle="modal" data-bs-target="#policiesModal">
          {{ "footer.security" | translate }}
        </div>
      </div>
      <div class="col-12 col-lg-4 d-grid  justify-content-center justify-content-lg-end align-items-center">
        <div class="fs-16 text-center" style="margin-bottom: 24px;">
          {{ "footer.follow" | translate }}
        </div>
        <div class="d-flex justify-content-center">
          <a href="https://www.linkedin.com/company/2bcore/mycompany/" aria-label="Ir a linkedin" target="_blank">
            <span class="icon-linkedin-w fs-23 mx-2"></span>
          </a>
          <a href="https://www.facebook.com/2bcore" aria-label="Ir a facebook" target="_blank">
            <span class="icon-facebook-w fs-23 mx-2"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="policiesModal" tabindex="-1" aria-labelledby="policiesModalLabel" aria-hidden="true">
  <div class="d-flex align-items-center heigth-custom">
    <div class="modal-dialog margin-mobile modal-policies cookie-modal">

      <div class="modal-content">
        <div class="btn-close-modal cr-pointer" data-bs-dismiss="modal">
          <img src="../../../assets/images/svg/header/cerrar.svg" class="button-close" alt="button-close">
        </div>
        <div class="content text-center">
          <p class="title fw-semi mb-4">{{ "footer.information.title" | translate }}</p>
          <p class="bpdy text-justify">{{ "footer.information.p1" | translate }}.</p>
          <p class="bpdy text-justify">{{ "footer.information.p2" | translate }}</p>
          <button class="btn-bubbleGray mt-3" data-bs-dismiss="modal" aria-label="Ocultar modal">{{
            "footer.information.btn" | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="privacyModal" tabindex="-1" aria-labelledby="privacyModalLabel" aria-hidden="true">
  <div class="d-flex align-items-center h-70 overflow-y-auto">
    <div class="modal-dialog margin-mobile modal-privacy cookie-modal">

      <div class="modal-content">
        <div class="btn-close-modal" data-bs-dismiss="modal">
          <img src="../../../assets/images/svg/header/cerrar.svg" class="button-close" alt="button-close">
        </div>
        <div class="content text-center">
          <p class="fs-28  fw-semi mb-4 title">{{ "footer.privacy.title" | translate }}</p>
          <p class="text-justify body">{{ "footer.privacy.p1" | translate }}</p>
          <p class=" fs-22 lh-27 fw-semi mt-5  text-start subtitle">{{ "footer.privacy.p2" | translate }}</p>
          <p class="text-justify body mb-5">{{ "footer.privacy.p3" | translate }}</p>
          <p class=" fs-22 lh-27 fw-semi subtitle text-start">{{ "footer.privacy.p4" | translate }}</p>
          <p class="text-justify body mb-5">{{ "footer.privacy.p5" | translate }}</p>
          <p class=" fs-22 lh-27 fw-semi subtitle text-start">{{ "footer.privacy.p6" | translate }}</p>
          <p class="text-justify body mb-5">
            {{ "footer.privacy.p7" | translate }}
            <br><br>
            {{ "footer.privacy.p8" | translate }} <br> {{ "footer.privacy.p9" | translate }} <br> {{
            "footer.privacy.p10" | translate }} <br>
            {{ "footer.privacy.p11" | translate }}
            <br><br>
            {{ "footer.privacy.p12" | translate }}
          </p>
          <p class=" fs-22 lh-27 fw-semi subtitle text-start">{{ "footer.privacy.p13" | translate }}</p>
          <p class="text-justify body mb-5">
            {{ "footer.privacy.p14" | translate }}
            <br><br>
            {{ "footer.privacy.p15" | translate }}
            <br><br>
            {{ "footer.privacy.p16" | translate }}
            <br><br>
            {{ "footer.privacy.p17" | translate }}
            <br><br>
            {{ "footer.privacy.p18" | translate }}
            <br><br>
            {{ "footer.privacy.p19" | translate }}
          </p>
          <p class=" fs-22 lh-27 fw-semi subtitle text-start">{{ "footer.privacy.p20" | translate }}</p>
          <p class="text-justify body mb-5">
            {{ "footer.privacy.p21" | translate }}
          </p>
          <button class="btn-bubbleGray mt-4" data-bs-dismiss="modal" aria-label="Ocultar modal">{{ "footer.privacy.btn"
            | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>