import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

declare var window: any;
@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit {

  cookieModal: any;
  isShowBanner = true;
  isShowPreferences = false;
  cookiesAccepted: any = 'false';
  performanceCookies = false;
  functionalCookies = false;
  analyticCookies = false;
  listStrict: boolean = false;
  listFunctional: boolean = false;
  listPerformance: boolean = false;
  listAnalytics: boolean = false;

  constructor() {
  }

  ngOnInit() {
    this.cookieModal = new window.bootstrap.Modal(
      document.getElementById("cookieModal")
    );
    this.cookiesAccepted = localStorage.getItem('cookies');
    localStorage.getItem('performanceCookies') === 'true' ? this.performanceCookies = true : this.performanceCookies = false;
    localStorage.getItem('functionalCookies') === 'true' ? this.functionalCookies = true : this.functionalCookies = false;
    localStorage.getItem('analyticCookies') === 'true' ? this.analyticCookies = true : this.analyticCookies = false;
  }

  openModal() {
    this.cookieModal.show();
  }
  changeActiveCollapse(list:any) {
    switch (list) {
      case 'strict':
        this.listStrict=  !this.listStrict;
        this.listFunctional=  false;
        this.listPerformance=  false;
        this.listAnalytics=  false;
        break;
      case 'functional' :
        this.listStrict= false;
        this.listFunctional=  !this.listFunctional;
        this.listPerformance=  false;
        this.listAnalytics=  false;
        break;
      case 'performance':
        this.listStrict= false;
        this.listFunctional=  false;
        this.listPerformance=  !this.listPerformance;
        this.listAnalytics=  false;
        break;
      case 'analytics': 
        this.listStrict= false;
        this.listFunctional=  false;
        this.listPerformance=  false;
        this.listAnalytics=  ! this.listAnalytics;
        break; 
      default:
        this.listStrict= false;
        this.listFunctional=  false;
        this.listPerformance=  false;
        this.listAnalytics=  false;



    }
  }
  cookieBannerHide() {
    this.isShowBanner = false;
    this.isShowPreferences = false;
    localStorage.setItem('cookies', 'true');
    localStorage.setItem('strictCookies', 'true');

    this.performanceCookies === true ? localStorage.setItem('performanceCookies', 'true') : localStorage.setItem('performanceCookies', 'false');
    this.functionalCookies === true ? localStorage.setItem('functionalCookies', 'true') : localStorage.setItem('functionalCookies', 'false');
    this.analyticCookies === true ? localStorage.setItem('analyticCookies', 'true') : localStorage.setItem('analyticCookies', 'false');

  };

  allCookiesBanner() {
    this.isShowBanner = false;
    this.isShowPreferences = false;
    localStorage.setItem('cookies', 'true');
    localStorage.setItem('strictCookies', 'true');
    localStorage.setItem('performanceCookies', 'true');
    localStorage.setItem('functionalCookies', 'true');
    localStorage.setItem('analyticCookies', 'true');
    this.cookiesAccepted = localStorage.getItem('cookies');
  };
  cookiePreferencesShow() {
    this.isShowPreferences = true;
  };
  switch() {

  }
}