<div class="bg-header container-fuild fixed-top">
  <nav class="navbar navbar-expand-lg container padding-mobile">
    <div class="grid-nav w-100">
      <!-- Logo Movil -->
      <div class="d-lg-none" [ngClass]="isSearch ? 'd-none': ''">
        <ng-container *ngTemplateOutlet="logo"></ng-container>
      </div>
      <!-- Logo Desktop -->
      <div class="d-none d-lg-block">
        <ng-container *ngTemplateOutlet="logo"></ng-container>
      </div>
      <ng-template #logo>
        <img src="assets/logos/2BC_Blanco_2024.svg" alt="2BCORE" [routerLink]="['/inicio']" />
      </ng-template>

      <div class="header-middle">
        <div class="header-menu d-none d-lg-grid">
          <a class="" [ngClass]="optionsRedirect[i].active ? 'active section-link-nothov' : 'section-link'"
            aria-label="Cambia ruta" *ngFor="
              let link of 'header.optionsRedirect' | translate;
              index as i
            " [routerLink]="i !== 4 ? ['/' + link.redirto] : null">
            <span *ngIf="i !== 4"> {{ link.name }}</span>

            <div class="dropdown " *ngIf="i === 4">
              <button class="custom-alliances dropdown-toggle" type="button" id="dropdownMenuButton1"
                data-bs-toggle="dropdown" aria-expanded="false">
                {{ link.name }}
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a class="dropdown-item" routerLink="/red-hat">Red Hat</a></li>
                <li><a class="dropdown-item" routerLink="/ibm-sterling">IBM Sterling</a></li>
                <li><a class="dropdown-item" routerLink="/alianzas">Ver más</a></li>
              </ul>
            </div>
            <div [ngClass]="optionsRedirect[i].active ? 'active-link' : ''"></div>
          </a>
        </div>

        <article class="search-box" [ngClass]="isSearch ? '' : 'd-none'">
          <section class="search-input">
            <img src="assets/images/svg/header/lupa.svg" alt="search" class="search-icon" />
            <input type="text" class="form-control" [(ngModel)]="searchTerm" (input)="search(searchTerm)" #inputSearch
              (keyup)="validateEnter($event)" placeholder="{{ 'header.menu.6' | translate }}..." />
            <img src="../../../assets/images/svg/header/cerrar.svg" alt="X" class="search-close"
              (click)="isSearch = false" />
          </section>

          <section class="search-result" *ngIf="components.length > 0">
            <ul *ngFor="let component of components; index as i">
              <li *ngFor="let section of component.sections; index as i">
                <a (click)="isSearch = false; setSlide(section.id)" id="{{component.view}}{{section.id}}"
                  aria-label="Cambiar ruta" [routerLink]="[searchComponetRedirect.get(component.view)?.redirto]"
                  href="#{{section.id}}" pageScroll [pageScrollOffset]="100" [pageScrollDuration]="5"
                  [pageScrollInterruptible]="true">
                  <span>{{ section.name }}</span>
                  <ng-container *ngFor="let keyWord of section.keyWords; index as i">
                    <strong>{{ keyWord + ((i+1) < section.keyWords.length ? ', ' : '' ) }}</strong>
                  </ng-container>
                </a>

              </li>
            </ul>
          </section>
        </article>

      </div>
      <div class="d-flex justify-content-end">
        <div class="d-flex align-items-center">
          <div class="icon-header" [ngClass]="isSearch ? 'd-none': ''">
            <div class="" (click)="showInputSearching();inputSearch.focus()">
              <span class="icon-search-d size-icons"></span>
            </div>
          </div>

          <!-- Idioma Movil -->
          <div class="d-lg-none" [ngClass]="isSearch ? 'd-none': ''">
            <ng-container *ngTemplateOutlet="idioma"></ng-container>
          </div>
          <!-- Idioma Desktop -->
          <div class="d-none d-lg-block">
            <ng-container *ngTemplateOutlet="idioma"></ng-container>
          </div>
          <ng-template #idioma>
            <div class="icon-header">
              <div class="dropdown">
                <span class=" dropdown-toggle icon-language size-icons" type="button" id="dropdownMenuButton"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                </span>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                  <div class="form-check d-flex" (click)="translation.changeLanguage('es')" id="spanish">

                    <span id="spanish" class="border-oval">
                      <span id="spanish" class="active-oval" *ngIf="currentLang ==='es'"></span>
                    </span>
                    <label id="spanish" class="form-check-label">
                      {{ "header.menu.7" | translate }}
                    </label>
                  </div>
                  <div class="form-check d-flex" (click)="translation.changeLanguage('en')" id="english">
                    <span id="english" class="border-oval">
                      <span id="english" class="active-oval" *ngIf="currentLang ==='en'"></span>
                    </span>
                    <label id="english" class="form-check-label" for="english">
                      {{ "header.menu.8" | translate }}
                    </label>
                  </div>
                </div>
              </div>

            </div>
          </ng-template>

          <div class="cr-pointer" data-bs-toggle="modal" data-bs-target="#loginModal">
            <div class="icon-header">
              <a href="https://intranet.2bcore.com" target="_blank" style="text-decoration: none;">
                <span class="icon-login-d size-icons"></span>
              </a>

            </div>
          </div>

        </div>
        <button class="navbar-toggler d-flex d-lg-none icon-header px-0" type="button" data-bs-toggle="modal"
          aria-label="mostrar menú" data-bs-target="#exampleModal">
          <img src="assets/images/svg/header/menu.svg" style="width: 18px" alt="menu" />
        </button>
      </div>
    </div>
  </nav>

  <div class="modal fade modal-menu" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog m-0">
      <div class="modal-content">
        <div class="btn-close-modal cr-pointer" data-bs-dismiss="modal">
          <img src="../../../assets/images/svg/header/cerrar.svg" style="width: 16px" alt="cerrar" />
        </div>
        <div class="content">
          <div class="d-flex" [routerLink]="['/inicio']"
            [ngClass]="optionsRedirect[0].active ? 'active item-menu-nothov' : 'item-menu'" data-bs-dismiss="modal">
            <div [ngClass]="optionsRedirect[0].active ? 'active-link-mobile' : ''"></div>
            {{ "header.menu.9" | translate }}

          </div>
          <div class="d-flex" [routerLink]="['/servicios']"
            [ngClass]="optionsRedirect[1].active ? 'active item-menu-nothov' : 'item-menu'" data-bs-dismiss="modal">
            <div [ngClass]="optionsRedirect[1].active ? 'active-link-mobile' : ''"></div>
            {{ "header.menu.1" | translate }}

          </div>

          <div class=" d-flex" [routerLink]="['/nosotros']"
            [ngClass]="optionsRedirect[2].active ? 'active item-menu-nothov' : 'item-menu'" data-bs-dismiss="modal">
            <div [ngClass]="optionsRedirect[2].active ? 'active-link-mobile' : ''"></div>
            {{ "header.menu.2" | translate }}
          </div>
          <div class=" d-flex" [routerLink]="['/carreras']"
            [ngClass]="optionsRedirect[3].active ? 'active item-menu-nothov' : 'item-menu'" data-bs-dismiss="modal">
            <div [ngClass]="optionsRedirect[3].active ? 'active-link-mobile' : ''"></div>
            {{ "header.menu.3" | translate }}
          </div>
          <div class="accordion accordion-flush py-0" id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingOne">
                <button class="accordion-button collapsed py-0" style="padding-left: 30px" type="button"
                  data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false"
                  aria-controls="flush-collapseOne">
                  <span class="acc-after"></span> {{ "header.menu.4" | translate }}
                </button>
              </h2>
              <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body pb-0">
                  <div class=" d-flex" [routerLink]="['/red-hat']"
                    [ngClass]="optionsRedirect[4].active ? 'active item-menu-nothov' : 'item-menu'"
                    data-bs-dismiss="modal">
                    <div [ngClass]="optionsRedirect[4].active ? 'active-link-mobile' : ''"></div>
                    {{ "header.menu.4.1" | translate }}
                  </div>
                  <div class=" d-flex" [routerLink]="['/ibm-sterling']"
                    [ngClass]="optionsRedirect[5].active ? 'active item-menu-nothov' : 'item-menu'"
                    data-bs-dismiss="modal">
                    <div [ngClass]="optionsRedirect[5].active ? 'active-link-mobile' : ''"></div>
                    {{ "header.menu.4.2" | translate }}
                  </div>
                  <div class="d-flex mb-0 cr-pointer" [routerLink]="['/alianzas']"
                    [ngClass]="optionsRedirect[6].active ? 'active item-menu-nothov' : 'item-menu'"
                    data-bs-dismiss="modal">
                    <div [ngClass]="optionsRedirect[6].active ? 'active-link-mobile' : ''"></div>
                    Ver más
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class=" d-flex" style="margin-top: 24px;" [routerLink]="['/contacto']"
            [ngClass]="optionsRedirect[7].active ? 'active item-menu-nothov' : 'item-menu'" data-bs-dismiss="modal">
            <div [ngClass]="optionsRedirect[7].active ? 'active-link-mobile' : ''"></div>
            {{ "header.menu.5" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>