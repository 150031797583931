import { Component, OnInit, ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { SwiperComponent, EventsParams } from "swiper/angular";
import { SERVICES_DESCRIPTION, SERVICES_DESCRIPTION_ENG  } from 'src/app/constants/home-services.const';
import { TowersService } from 'src/app/services/towers.service';
import { TranslationService } from 'src/app/services/translate/translation.service';
import { Angulartics2 } from 'angulartics2';
import { CAT_FRAGMENT_ID, CAT_SECTION } from 'src/app/constants/search-word.const';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { DimentionService } from 'src/app/services/dimention/dimention.service';
import { ALLIANCES, ALLIANCES_ENG, ALLIANCES_SECTIONS, CAROUSEL_ALLLIANCES } from 'src/app/constants/alliances.const';
SwiperCore.use([ Navigation, Autoplay ]);

@Component({
  selector: 'app-service-tower',
  templateUrl: './service-tower.component.html',
  styleUrls: ['./service-tower.component.scss']
})
export class ServiceTowerComponent implements OnInit {
  readonly IDS = CAT_FRAGMENT_ID;
  readonly servicesIds = [
    CAT_SECTION.SolutionsDevelopment,
    CAT_SECTION.SpecializedServices,
    CAT_SECTION.NormalizationProcesses,
    CAT_SECTION.Institute,
    CAT_SECTION.Talents,
  ];
  isDesktop = false;
  //indice de arreglo activo
  activeIndex = 0;
  SERVICES_TRANSLATED: any;
  // Arreglo de consatnttes de iconos
  towerIcons = [
    {
      inactiveIcon:'<span class="icon-Enterprise-Solutions"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
      activeIcon: 'https://2bcore-site.s3.us-east-2.amazonaws.com/public/torres/Torre1.gif',
      active: false
    },
    {
      inactiveIcon:'<span class="icon-Solutions-Development"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>',
      activeIcon: 'https://2bcore-site.s3.us-east-2.amazonaws.com/public/torres/Torre2.gif',
      active: false
    },
    {
      inactiveIcon:'<span class="icon-Specialized-Services"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
      activeIcon: 'https://2bcore-site.s3.us-east-2.amazonaws.com/public/torres/Torre3.gif',
      active: false
    },
    {
      inactiveIcon:'<span class="icon-Normalization--Processes"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
      activeIcon: 'https://2bcore-site.s3.us-east-2.amazonaws.com/public/torres/Torre4.gif',
      active: false
    },
    {
      inactiveIcon:'<span class="icon-BInstitute"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>',
      activeIcon: 'https://2bcore-site.s3.us-east-2.amazonaws.com/public/torres/Torre5.gif',
      active: false
    },
    {
      inactiveIcon:'<span class="icon-BTalents"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>',
      activeIcon: 'https://2bcore-site.s3.us-east-2.amazonaws.com/public/torres/Torre6.gif',
      active: false
    }
  ];
  activeService: any;
  currentLang: string | any;
  // configuracion inicial carrusel
  swiperConfig: any = {
    loopAdditionalSlides: 0,
    allowTouchMove: true,
    slideToClickedSlide: true,
    initialSlide: 0,
    preventClicksPropagation: false,
    loop : true,
    on: { 
      realIndexChange: (index: any) => { 
       this.setActiveSlide(index.realIndex);
      }, 
    },
    navigation: {
      nextEl: '.icon-down',
      prevEl: '.icon-up',
    },
    breakpoints: {
      992: {
        slidesPerView: 6
      },
      768: {
        slidesPerView: 3,
        centeredSlides: true,
      },
      576: {
        slidesPerView: 3,
        centeredSlides: true,
      },
      375: {
        slidesPerView: 3,
        centeredSlides: true,
      }
    }
  }

  swiperAux?: SwiperCore;

// configs carrusel alianzas
readonly alliancesIds = ALLIANCES_SECTIONS;
 // Constantes de iconos para carrusel
 carouselData = CAROUSEL_ALLLIANCES;
  // Alianza activa
  activeAlliance: any;
  activeIndexAliaza = 0;
  ALLIANCES_TRANSLATED : any;
  swiperConfigAlianza: any = {
    loopAdditionalSlides: 0,
    allowTouchMove: true,
    slideToClickedSlide: true,
    initialSlide: 0,
    loopedSlides: 7,
    loop : true,
    centeredSlides: true,
    slidesPerView: 3,
    on: { 
      realIndexChange: (index: any) => { 
       this.setActiveSlideAlianzas(index.realIndex);
      }, 
    },
    navigation: {
      nextEl: '.icon-alliance-down',
      prevEl: '.icon-alliance-up',
    },
    direction: 'vertical',
  }
  swiperConfigMobile: any = {
    loopAdditionalSlides: 0,
    allowTouchMove: true,
    slideToClickedSlide: true,
    initialSlide: 0,
    loopedSlides: 7,
    loop : true,
    centeredSlides: true,
    slidesPerView: 1.8,
    on: { 
      realIndexChange: (index: any) => { 
       this.setActiveSlideAlianzas(index.realIndex);
      }, 
    },
    navigation: {
      nextEl: '.icon-alliance-down',
      prevEl: '.icon-alliance-up',
    },
    direction: 'horizontal',
  }
  swiperAuxAlianza?: SwiperCore;
  constructor(
    private router: Router,
    private towerService: TowersService,
    public translation: TranslationService,
    private angulartics2: Angulartics2,
    private changeDetectorRef: ChangeDetectorRef,
    private loaderService: LoaderService,
    private dimentionService : DimentionService,
    private zone: NgZone
  ) { }

  ngOnInit(): void {
    this.dimentionService.widthSize.subscribe((width: any) => {
      if (width < 768) {
        this.isDesktop = false;
      } else {
        this.isDesktop = true;
      }
    });
    this.loaderService.isLoading.next(true);
    setTimeout(() => {
      this.loaderService.isLoading.next(false);
    }, 1000);
    this.translation.currentLang.observables.lang.subscribe((lang : any) => 
    {
      this.currentLang = lang;
      setTimeout(() => {
        this.ALLIANCES_TRANSLATED =  this.currentLang === 'es' ? ALLIANCES : ALLIANCES_ENG;
        this.setActiveSlideAlianzas(this.activeIndexAliaza);
      },200);
      this.SERVICES_TRANSLATED =  this.currentLang === 'es' ? SERVICES_DESCRIPTION : SERVICES_DESCRIPTION_ENG;
      this.setActiveSlide(this.activeIndex);
    
    
    }
  );
  this.towerService.activeTowerService.subscribe((tower:any) => {
    switch (tower) {
      case 'ENTERPRISE_SOLUTIONS':
        this.activeService =  this.SERVICES_TRANSLATED.ENTERPRISE_SOLUTIONS;      
        this.activeIndex = 0;
     this.swiperAux?.slideToLoop(this.activeIndex);
        break;
      case 'SOLUTIONS_DEVELOPMENT':
        this.activeService = this.SERVICES_TRANSLATED.SOLUTIONS_DEVELOPMENT;
        this.activeIndex = 1;
        this.swiperAux?.slideToLoop(this.activeIndex);
        break;
      case 'SPECIALIZED_SERVICES':
        this.activeService =  this.SERVICES_TRANSLATED.SPECIALIZED_SERVICES;
        
        this.activeIndex = 2;
        this.swiperAux?.slideToLoop(this.activeIndex);
        break;
      case 'NORMALIZATTION_PROCESS':
        this.activeService =  this.SERVICES_TRANSLATED.NORMALIZATTION_PROCESS;
        this.activeIndex = 3;
        
        this.swiperAux?.slideToLoop(this.activeIndex);
        break;
     
      case 'INSTITUTE':
        this.activeService =  this.SERVICES_TRANSLATED.INSTITUTE;
        
        this.activeIndex = 4;
        this.swiperAux?.slideToLoop(this.activeIndex);
        break;
      case 'TALENTS':
        this.activeService =  this.SERVICES_TRANSLATED.TALENTS;       
        this.activeIndex = 5;   
        
        this.swiperAux?.slideToLoop(this.activeIndex);       
        break;
      default:
        this.activeService =  this.SERVICES_TRANSLATED.ENTERPRISE_SOLUTIONS;
        this.activeIndex = 0;
        this.swiperAux?.slideToLoop(this.activeIndex);
        break;
    }
  });
  this.initServiceActive();
  }

  prevSlide() {
    this.swiperAux?.slidePrev();
  }

  nextSlide() {
    this.swiperAux?.slideNext();
  }

  changeActive(idx:any) {
    this.activeService.services.forEach((element: any, index: any) => {
      element.active = !element.active;
      if ( idx !== index ) {
        element.active = false;
      }
    });
  }

  onSlideChange(e: SwiperCore[]) {
  this.swiperAux = e[0];
    
  }
  onSlideChangeAlianza(e: SwiperCore[]) {
    this.swiperAuxAlianza = e[0];
    }
  setActiveSlide(index: any) {
    
    this.activeIndex = index;
    this.towerIcons.forEach((element:any, idx: any) => {
      element.active = index === idx ? true : false;
    });
    let stringAlliance  = Object.keys(this.currentLang === 'es' ? SERVICES_DESCRIPTION : SERVICES_DESCRIPTION_ENG)[ this.activeIndex];   
    var map = new Map(Object.entries(this.currentLang === 'es' ? SERVICES_DESCRIPTION : SERVICES_DESCRIPTION_ENG)); 
    map.forEach((element) => {
      element.services.forEach((service: any) => {
        service.active = false;
      });      
    });
    
    this.activeService = this.currentLang === 'es' ? SERVICES_DESCRIPTION[stringAlliance  as keyof typeof SERVICES_DESCRIPTION] : this.SERVICES_TRANSLATED[stringAlliance  as keyof typeof SERVICES_DESCRIPTION_ENG];
    this.setActiveSlideAlianzas(0);
    this.changeDetectorRef.detectChanges();
  }
  /**
   *Funcion para cambiar de slide y actualizar indice activo
   *
   * @memberof AlliancesComponent
   */
 
  goToHome() {
    this.zone.run(() => {  this.router.navigate(['/']); });
  }



//funciones para carrusel de allianzas

 /**
   *Funcion para cambiar de slide y actualizar indice activo
   *
   * @memberof AlliancesComponent
   */
   setActiveSlideAlianzas(index: any) {
    
    this.activeIndexAliaza = index;
    let stringAlliance  = Object.keys(this.currentLang === 'es' ? ALLIANCES : ALLIANCES_ENG)[this.activeIndexAliaza];
    var map = new Map(Object.entries(this.currentLang === 'es' ? ALLIANCES : ALLIANCES_ENG)); 
    map.forEach((element) => {
      element.services.forEach((service: any) => {
        service.active = false;
      });      
    });
    this.activeAlliance = this.currentLang !== 'es' ? this.ALLIANCES_TRANSLATED[stringAlliance  as keyof typeof ALLIANCES_ENG] : ALLIANCES[stringAlliance  as keyof typeof ALLIANCES]; 
    this.changeDetectorRef.detectChanges(); 
  }
  serviceAtcive(index: any) {
    this.activeAlliance.services.forEach((elem:any, idx: any) => {
      if (index === idx) {
        elem.active = !elem.active;
      } else {
        elem.active = false;
      }
  
    });
    this.changeDetectorRef.detectChanges(); 
  }

  initServiceActive() {
   this.activeAlliance = this.ALLIANCES_TRANSLATED?.AWS;
    this.activeIndexAliaza = 0;
    this.swiperAuxAlianza?.slideToLoop(this.activeIndexAliaza);
  }
}



