<div class="container padding-mobile" style="margin-top: 90px;" id="{{IDS.serviceTower}}">
    <div class="row separate-back">
        <div class="col-12 body-text ps-md-0">
            <div (click)="goToHome()" class="d-flex align-items-center" style="cursor: pointer;width: fit-content;">
                <span class="icon-Regresar fs-18 me-2"></span> <span class="d-none d-md-block fw-regular">{{ 'back.btn' | translate }} </span>
            </div>

        </div>
    </div>
    <div class="row mb-5">
        <div class="col-12 col-md-6 text-center ">
            <video loop autoplay muted playsinline oncanplay="this.play()" class="w-100" onloadedmetadata="this.muted = true">
            <source src="https://2bcore-site.s3.us-east-2.amazonaws.com/public/servicios.mp4"  type="video/mp4" />
        </video>
        </div>
        <div
            class="col-12 col-md-6 d-flex mb-5 mb-md-0 pe-md-0  flex-column text-center text-md-end order-first order-md-last margin-section">
            <p class="title-text text-center text-lg-end">
                {{ 'services.title' | translate }}
            </p>
            <p class="body-text ps-0 ps-md-4
            ">
                {{ 'services.text' | translate }}
            </p>
        </div>
    </div>
    <div class="row py-5">
        <div class="col-12 col-md-8 m-auto">
            <ng-container *ngFor="let serviceId of servicesIds">
                <span [id]="serviceId.toString()"></span>
            </ng-container>
        
            <swiper [config]="swiperConfig" class="h-100" (slideChange)="onSlideChange($event)">
                <ng-template *ngFor="let slide of towerIcons; index as i"
                    class="d-flex justify-content-center justify-content-md-start idSlide{{i}}" let-data swiperSlide>
                    <span [innerHTML]="slide.inactiveIcon" style="font-size: 56px;" id="idSlide{{i}}" *ngIf="!slide.active"
                        [ngClass]="slide.active ? 'slide-active': 'slide-inactive'"></span>
                        <img [src]="slide.activeIcon" alt="active-icon"  *ngIf="slide.active" style="width: 86px; height: 86px;" >
                </ng-template>
            </swiper>
            <div class="col-12 d-flex py-4 m-auto justify-content-center justify-content-md-start">
                <div class="icon-up fs-32 me-4 icon-service" (click)="prevSlide()">
                </div>
                <div class="icon-down fs-32 icon-service" id="icon-down"  (click)="nextSlide()">
                </div>
            </div>
        </div>
    </div>

    <div class="row pb-5">
        <div class="col-12 px-md-0">
            <span class="icon-search-active"></span>
            <p class="title-text text-center text-md-start" style="color: #0075E2;">{{activeService.title}}</p>
            <p class="body-text text-justify" [innerHTML]="activeService.description">
            </p>
            <br>
          
            
            <div class="accordion" id="accordionAlliances" *ngIf="activeService.title !== 'Enterprise Solutions'">
                <div class="accordion-item mb-1" *ngFor="let service of activeService?.services; index as i">
                    <h2 class="accordion-header py-3" id="headingOne{{i}}"
                        [ngClass]="service.active ? 'header-active': 'header-inactive'">
                        <a class="body-text d-flex" type="button" href="#collapseOne{{i}}" aria-label="Abrir collapse"  data-bs-toggle="collapse"
                            (click)="changeActive(i)" aria-expanded="false">
                            <span class="ms-1 accord-header "
                                [style]="service.active ? 'color: #0075E2' : ''">{{service.name}}</span> <span
                                class="icon-Menos ms-auto fs-24" *ngIf="!service.active"></span><span class="icon-Mas ms-auto fs-24"
                                *ngIf="service.active"></span>
                        </a>
                    </h2>
                    <div id="collapseOne{{i}}" class="accordion-collapse collapse" data-bs-parent="#accordionAlliances">
                        <div class="accordion-body px-0">
                            <p [innerHTML]="service.description" class="accord-body">

                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" mt-5" *ngIf="activeService.title === 'Solutions​ Development​​'">
                <div class="metodologia" id="metodologia">
                  <div class=" path">
                        <h2 class="body-subtitle margin-custom text-center text-md-start" style="color: #0075E2;">
              
                          {{ "aboutus.metodology.title" | translate }}
                        </h2>
                      <div class="icons">
                        <div *ngFor="let icon of 'header.icons' | translate; let i = index">
                          <div>
                            <div class="box-icon d-flex justify-content-center align-items-center w-100">
                              <div class="{{ icon.classIcon }}">
                                <img src="{{ icon.icon }}" class="{{ icon.classIcon }}" alt="icons-header" [ngStyle]="{
                                    width: i !== 4 && i !== 0 ? '55px' : '',
                                    height: i !== 4 ? '55px' : ''
                                  }" />
                                <!-- <span class="{{icon.icon}}" [innerHTML]="i == 4 ? icon.icon : '' " ></span> -->
                                <div class="content-tool">
                                  <div class="tool">
                                    <p [style]="icon.color" class="letters">
                                      {{ icon.text }}
                                    </p>
                                    <p class="text">{{ icon.texTool }}</p>
                                  </div>
                                </div>
                              </div>
                              <div class="content-circle" [ngClass]="i === 5 ? 'circleYellow' : ''" *ngIf="i !== 4">
                                <div [class]="'blob circle-element' + i"></div>
                              </div>
                            </div>
                            <p [style]="icon.color" class="letters">{{ icon.text }}</p>
                            <div class="text-responsive">
                              <p class="text-tool">{{ icon.texTool }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
        </div>
            <!-- Apartado carrusel alianzas -->
            <div class="row mb-5" *ngIf="activeService.title === 'Enterprise Solutions'" style="min-height: 800px;">
                <div class="col-1 d-flex justify-content-center align-items-center flex-column d-none d-md-flex "
                    style="height: 400px;">
                    <span class="icon-up-alliances fs-32  icon-alliance-up"style="transform: none; color: black; cursor: pointer; margin-bottom: 77px;"
                        ></span>
                    <span class="icon-down-alliances fs-32 icon-alliance-down" style="transform: none; color: black; cursor: pointer;" ></span>
        
                </div>
                <div class="col-12 col-md-4">
                    <ng-container *ngFor="let allianceId of alliancesIds">
                        <span [id]="allianceId.toString()"></span>
                    </ng-container>
        
                    <swiper [config]="swiperConfigAlianza" (slideChange)="onSlideChangeAlianza($event)" class="heightCarousel d-none d-md-block" *ngIf="isDesktop">
        
                        <ng-template *ngFor="let slide of carouselData; index as i"
                            class="d-flex align-items-center justify-content-center justify-content-md-start" swiperSlide
                            let-data>
                            <div class="swiper slide-container m-auto  ms-md-0">
                                <span id="slide{{i}}" [innerHTML]="data.isActive ? slide.icon : slide.icon_gray"
                                     [ngClass]="[data.isActive  ? slide.classActive: slide.classInactive]"></span>
                            </div>
                        </ng-template>
        
                    </swiper>
                    <swiper [config]="swiperConfigMobile" (slideChange)="onSlideChangeAlianza($event)" class="heightCarousel d-md-none" *ngIf="!isDesktop">
        
                        <ng-template *ngFor="let slide of carouselData; index as i"
                            class="d-flex align-items-center justify-content-center justify-content-md-start" swiperSlide
                            let-data>
                            <div class="swiper slide-container m-auto  ms-md-0">
                                <span id="slide{{i}}" [innerHTML]="data.isActive ? slide.icon : slide.icon_gray"
                                     [ngClass]="[data.isActive  ? slide.classActive: slide.classInactive]"></span>
                            </div>
                        </ng-template>
        
                    </swiper>
                </div>
                <div class="col-12 d-flex py-4 m-auto d-flex d-md-none justify-content-center justify-content-md-start">
                    <div class="icon-up-alliances up-mobile fs-32 me-4 icon-alliance-up"  >
                    </div>
                    <div class="icon-down-alliances down-mobile fs-32 icon-alliance-down" >
                    </div>
                </div>
                <div class="col-12 col-md-7" id="allianceBlock">
                    <span class="icon-search-active"></span>
                    <p class="title-text">{{activeAlliance?.name}}</p>
                    <p class="body-text">
                        {{activeAlliance?.description}}
                    </p>
                    <br>
                    <p class="subtitle-text">
                        {{ 'alliances.services' | translate }}
                    </p>
                    <div *ngIf="activeAlliance?.services.length === 1">
                        <p class="body-text">
                            {{activeAlliance?.services[0].name}}
                        </p>
                        <p *ngFor="let service of activeAlliance?.services[0]?.description" class="body-text-service d-flex">
                            <span class="icon-bullet" style="margin-right: 10px;margin-top: 4px;"><span class="path1"></span><span
                                    class="path2"></span></span> <span>{{service}}</span>
                        </p>
        
                    </div>
                    <div class="accordion" id="accordionAlliances" *ngIf="activeAlliance?.services.length !== 1">
                        <div class="accordion-item mb-4" *ngFor="let service of activeAlliance?.services; index as i">
                            <h2 class="accordion-header" id="headingOne{{i}}">
                                <a class="body-text d-flex" type="button" href="#collapseOne{{i}}" data-bs-toggle="collapse" aria-label="Cambiar servicio active"
                                    (click)="serviceAtcive(i)" aria-expanded="false">
                                    <div class="transition" [ngClass]="activeAlliance?.services[i].active ? 'active-collapse' : ''"><span
                                            class="icon-list_expanded fs-11 fs-md-16 "></span> </div> <span
                                        class="ms-1">{{service.name}}</span>
                                </a>
                            </h2>
                            <div id="collapseOne{{i}}" class="accordion-collapse collapse" data-bs-parent="#accordionAlliances">
                                <div class="accordion-body px-0">
                                    <p *ngFor="let service of service.description" class="d-flex body-text-service ">
                                        <span class="icon-bullet fs-16" style="margin-right: 10px;margin-top: 4px;"><span class="path1"></span><span
                                                class="path2"></span></span> <span>{{service}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
              <!-- Apartado carrusel alianzas -->
        </div>
    </div>
</div>