import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TowersService } from 'src/app/services/towers.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Router,NavigationStart, Event as NavigationEvent } from '@angular/router';
import { LoaderService } from 'src/app/services/loader/loader.service';

 declare var window: any;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {

  successModal: any;
  isContact: any;
  constructor(
    private FormBuilder: FormBuilder,
    private towerService: TowersService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private loaderService: LoaderService,
    private router: Router
  ) { }

  formContact: FormGroup = this.FormBuilder.group({
    name:  ['', Validators.compose([Validators.min(1),Validators.required,Validators.pattern('^[a-zA-Z ]{2,100}$')])],
    email: ['', Validators.compose([Validators.required, Validators.email,  Validators.min(5), Validators.maxLength(100)])],
    phone: ['', Validators.compose([Validators.pattern('^[\+]?[(]?[\+]?[0-9]{2}[)]?[-\s\.]?[0-9]{0,3}[-\s\.]?[0-9]{7}$'), Validators.required])],
    message: ['', Validators.maxLength(300)],
    recaptchaReactive: ['']
  });
  goToHome(){
    this.router.navigate(['/inicio'])
 }
  ngOnInit(): void {
    this.loaderService.isLoading.next(true);
    setTimeout(() => {
      this.loaderService.isLoading.next(false);
    }, 1000);
    this.successModal = new window.bootstrap.Modal(
      document.getElementById("modalForm")
    );
  }

  public send(): void {

    if (this.formContact.invalid) {
      for (const control of Object.keys(this.formContact.controls)) {
        this.formContact.controls[control].markAsTouched();
      }
      
    } else {
      this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
        });
      const dataEmail: any = {
        email: this.formContact.value.email,
        message: this.formContact.value.message,
        name: this.formContact.value.name,
        phone: this.formContact.value.phone
      }
     this.towerService.sendEmail(dataEmail);
     this.successModal.show();
    }
    


  }

  formComplete() {
    this.formContact.reset('')
  }

}
