import { Component, OnInit, HostListener, AfterViewChecked, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import { take, Observable, scan, Subject } from 'rxjs';
import { ChatService, Message } from '../../services/chat/chat.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
    selector: 'chat-bot',
    templateUrl: './chatbot.component.html',
    styleUrls: ['./chatbot.component.scss'],

})

export class ChatbotComponent implements OnInit, AfterViewChecked {
    @ViewChild('scrollMeBottom') private myScrollContainer!: ElementRef
    // titulo de pagina
    title = 'chatbot-2bc-front';
    progressFile: any = 0;
    kbFile: any;
    nameFile: any;
    //Bandera oara mostrar/ocultar cuadro de emojis
    displayEmojis: boolean = false;
    // display chatbot
    displayChatbot: boolean = false;
    // Control para input de texto de entrada
    textEmoji: string = '';

    loading: boolean = false
    carga: boolean = false
    filePath: any;

    // arregglo con historial de mensajes
    messagesHistory: any = [];
    typing: boolean = false;
    userTyping: boolean = false
    showAlert: boolean = false;
    showUpload: boolean = false
    showCurso: boolean = true
    //sound
    soundOff: boolean = false
    soundImg: string = 'https://d3aiotmb6js9zr.cloudfront.net/assets/sonido_on@3x.svg'
    uploadImg: any;
    //Lets declare Record OBJ
    record: any;
    //Will use this flag for toggeling recording
    recording = false;
    error: any;
    url: any;
    safeUrl: any;
    // typing: boolean = true
    succesFile: boolean = false
    pause: boolean = false
    audioRecorded: boolean = false

    messages: Observable<Message> | undefined;

    suggestionsBol: boolean = false;
    suggestionsValues: Array<string> = [];
    time: any;
    sessionExist: boolean = false;

    timeoutId: any;
    timeoutCloseId: any;
    userInactive: Subject<any> = new Subject();
    userInactiveClose: Subject<any> = new Subject();

    enableConv: boolean = false;
    enableSurvey: boolean = false;
    endEncuesta: boolean = false;
    firstTime: boolean = false;

    enableFiles: boolean = false;
    programCard: boolean = false;
    programValue: string = '';

    uploadText: string = 'Cargar'
    sender: string = 'Tú'

    messageInput: string = 'Escribe un mensaje aquí…'
    lang: string = ''

    programUrl: string = ''

    closeTimer: any;

    constructor(private chatService: ChatService, private domSanitizer: DomSanitizer) {

        this.userInactive.subscribe((message) => {
            this.chatService.converse(message, true);
        });
        this.userInactiveClose.subscribe((message) => {
            this.chatService.converse(message, true);
        });
        this.chatService.firstTime.asObservable().subscribe((status) => {
            this.firstTime = status
            if (status == false) {
                this.chatService.changeEnableConv(true);
            }
        });
    }

    // Metodo para impedir que recargue la pagina, desactivado de momento para permitir la recarga rapida de cambios
    @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
        if (this.sessionExist == false) {
            return true
        } else {
            return event.returnValue = false;
        }
    }
    ngOnInit() {

        this.scrollBottom()
        this.messages = this.chatService.conversation.asObservable();
        this.messages?.forEach((message) => {
            if (message.typeUser != '') {
                this.messagesHistory.push(message);
            }
        })
        this.chatService.suggestionsBol.asObservable().subscribe((res) => {
            this.suggestionsBol = res
            if (this.suggestionsBol) {
                this.messageInput = 'Elige una opcion…'
                this.textEmoji = ''
                this.userTyping = false
            } else {
                this.messageInput = 'Escribe un mensaje aquí…'
            }
        })
        this.chatService.suggestionsVals.asObservable().subscribe((res) => {
            this.suggestionsValues = res
        })
        this.chatService.soundOff.asObservable().subscribe((res) => {
            this.soundOff = res
        })
        this.chatService.sessionId.asObservable().subscribe((res) => {
            if (res != '') {
                this.sessionExist = true
            }
        })
        this.chatService.enableSurvey.asObservable().subscribe((res) => {
            this.enableSurvey = res
        })
        this.chatService.enableConv.asObservable().subscribe((res) => {
            this.enableConv = res
        })
        this.chatService.writing.asObservable().subscribe((res) => {
            this.typing = res;
            // if (this.typing == false && this._inputElement != undefined) {
            //   setTimeout(() => {
            //     if (this._inputElement != undefined) {
            //       this._inputElement.nativeElement.focus();
            //     }
            //   }, 10);
            // }
        })
        this.chatService.enableFiles.asObservable().subscribe((res) => {
            this.enableFiles = res
        })
        this.chatService.showCurse.asObservable().subscribe((res) => {
            this.showCurso = res
        })
        this.chatService.endEncuesta.asObservable().subscribe((res) => {
            this.endEncuesta = res
            if (this.endEncuesta == true) {
                clearTimeout(this.timeoutId);
                clearTimeout(this.timeoutCloseId);
                this.closeTimer = setTimeout(() => {
                    this.closeConversation()
                }, 6000);
            }
        })
        this.chatService.programCard.asObservable().subscribe((res) => {
            this.programCard = res
        })
        this.chatService.programValue.asObservable().subscribe((res) => {
            this.programValue = res
        })
        this.chatService.langChatbot.asObservable().subscribe((res) => {
            this.lang = res;
        })
    }
    @HostListener('window:click', ['$event']) onClick(event: any) {
        //becarios
        if (event.srcElement.id == 'scholars') {
            if (this.displayChatbot == false) {
                if (this.lang == 'es') {
                    this.toggleChatBot('Becarios')
                } else if (this.lang == 'en') {
                    this.toggleChatBot('Scholarship holders')
                }
            } else {
                if (this.lang == 'es') {
                    this.chatService.converse('Becarios', false)
                } else if (this.lang == 'en') {
                    this.chatService.converse('Scholarship holders', false)
                }
            }
        } else if (event.srcElement.id == 'graduates') {
            //egresados
            if (this.displayChatbot == false) {
                if (this.lang == 'es') {
                    this.toggleChatBot('Recien Egresados')
                } else if (this.lang == 'en') {
                    this.toggleChatBot('Recent graduate')
                }
            } else {
                if (this.lang == 'es') {
                    this.chatService.converse('Recien Egresados', false)
                } else if (this.lang == 'en') {
                    this.chatService.converse('Recent graduate', false)
                }
            }
        } else if (event.srcElement.id == 'professionals') {
            //profesionales
            if (this.displayChatbot == false) {
                if (this.lang == 'es') {
                    this.toggleChatBot('Profesionales')
                } else if (this.lang == 'en') {
                    this.toggleChatBot('Program for professionals')
                }
            } else {
                if (this.lang == 'es') {
                    this.chatService.converse('Profesionales', false)
                } else if (this.lang == 'en') {
                    this.chatService.converse('Program for professionals', false)
                }
            }
        } else if (event.srcElement.id == 'socialService') {
            //Servicio social
            if (this.displayChatbot == false) {
                if (this.lang == 'es') {
                    this.toggleChatBot('Servicio Social')
                } else if (this.lang == 'en') {
                    this.toggleChatBot('Social Service')
                }
            } else {
                if (this.lang == 'es') {
                    this.chatService.converse('Servicio Social', false)
                } else if (this.lang == 'en') {
                    this.chatService.converse('Social Service', false)
                }
            }
        } else if (event.srcElement.id == 'spanish') {
            this.chatService.changeLang('es')
            this.uploadText = 'Cargar'
            this.sender = 'Tú'
        } else if (event.srcElement.id == 'english') {
            this.chatService.changeLang('en')
            this.uploadText = 'Upload'
            this.sender = 'You'
        }
    }
    ngAfterViewChecked(): void {
        this.scrollBottom()
    }

    scrollBottom(): void {
        try {
            this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight
        } catch (err) { }
    }

    checkTimeOut() {
        this.timeoutId = setTimeout(
            () => {
                if (this.enableConv || this.enableSurvey) {
                    if (this.lang == "es") {
                        this.userInactive.next("¡Psss psss!😴¿Hay algo más que pueda hacer por ti?")
                    } else if (this.lang == "en") {
                        this.userInactive.next("¡Psss psss!😴 Is there anything else I can do for you?")
                    }
                    clearTimeout(this.timeoutId);
                }
            }, 1000 * 60 * 2);
        this.timeoutCloseId = setTimeout(
            () => {
                if (this.enableConv || this.enableSurvey) {
                    if (this.lang == "es") {
                        this.userInactive.next("Esta conversación se cerrará automáticamente en 5 segundos")
                    } else if (this.lang == "en") {
                        this.userInactive.next("This conversation will be closed automatically in 5 seconds 👋")
                    }
                    this.chatService.changeEnableConv(false);
                    clearTimeout(this.timeoutCloseId);
                    this.closeTimer = setTimeout(() => {
                        this.closeConversation()
                    }, 6000);
                }
            }, 1000 * 60 * 5);
    }

    end() {
        clearTimeout(this.timeoutId);
        clearTimeout(this.timeoutCloseId);
        this.chatService.changeEnableConv(false);
    }



    showPreview(event: Event) {
        let selectedFile = (event.target as HTMLInputElement).files?.item(0);
        if (selectedFile) {
            //console.log(selectedFile);
            let fileReader = new FileReader();
            this.kbFile = selectedFile.size / 1024;
            this.kbFile = (Math.round(this.kbFile * 100) / 100);
            fileReader.readAsDataURL(selectedFile);
            this.nameFile = selectedFile.name;
            if (["image/jpeg", "image/png", "image/svg+xml", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(selectedFile.type)
                && this.kbFile <= 10240) {
                setTimeout(() => {
                    this.showUpload = true
                }, 2000)
                let fileReader = new FileReader();
                fileReader.readAsDataURL(selectedFile);
                fileReader.addEventListener('progress', (e: any) => {
                    this.progressFile = (e.loaded / e.total) * 100;
                    //console.log(this.progressFile);
                })
                fileReader.addEventListener('load', (event) => {
                    this.playAudio()
                    this.filePath = event.target?.result;
                    //console.log(event)
                    if (selectedFile?.type == "application/pdf" || selectedFile?.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                        const dataBot = {
                            typeUser: 'user',
                            typeDescription: this.sender,
                            typeData: 'file',
                            time: this.chatService.convTime("time"),
                            filePath: event.target?.result,
                            succesFile: true,
                        };
                        this.messagesHistory.push(dataBot);
                    } else {
                        const dataBot = {
                            typeUser: 'user',
                            typeDescription: this.sender,
                            typeData: 'image',
                            time: this.chatService.convTime("time"),
                            filePath: event.target?.result,
                            succesFile: true,
                        };
                        this.messagesHistory.push(dataBot);
                    }
                    this.chatService.converseSendFile(`filename=${selectedFile?.name}`);
                    let file = selectedFile || '';
                    const formData = new FormData();
                    formData.append('archivo', file);
                    formData.append('correo', this.chatService.emailParameter);
                    this.chatService.uploadFile(formData);
                    setTimeout(() => {
                        this.showUpload = false
                    }, 5000)

                })

            } else {
                this.playAudio()
                let text = (this.lang == 'es') ? 'Oops! Lo siento, algo no salió bien. Por favor, verifica el archivo que has adjuntado e inténtalo de nuevo' : 'Oops, sorry, something went wrong. Please check the file you have attached and try again'
                const dataBot = {
                    typeUser: 'user',
                    typeDescription: this.sender,
                    typeData: 'file',
                    time: this.chatService.convTime("time"),
                    succesFile: false,
                };
                this.messagesHistory.push(dataBot);
                this.userInactive.next(text)
            }
        }
    }

    initiateRecording() {
        if (this.typing !== true && this.suggestionsBol !== true) {
            this.recording = !this.recording;
            let mediaConstraints = {
                video: false,
                audio: true,
            }
            navigator.mediaDevices
                .getUserMedia(mediaConstraints)
                .then(this.successCallback.bind(this), this.errorCallback.bind(this));
            this.audioRecorded = !this.audioRecorded
        }
    }

    /**
     * Process Error.
     */
    errorCallback(error: any) {
        this.error = 'Can not play audio in your browser';
    }

    /**
     * Will be called automatically.
     */
    successCallback(stream: any) {
        var options: any = {
            mimeType: 'audio/wav',
            numberOfAudioChannels: 1,
            desiredSampRate: 16000,
        };
        //Start Actuall Recording
        var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
        this.record = new StereoAudioRecorder(stream, options);
        this.record.record();
    }

    /**
     * Stop recording.
     */
    stopRecording() {
        // this.recording = !this.recording;
        if (this.pause == false) {
            this.pause = true
            this.record.pause();
        } else {
            this.pause = false;
            this.record.resume()
        }
        // this.audioRecorded = !this.audioRecorded
    }
    /**
     * processRecording Do what ever you want with blob
     * @param  {any} blob Blog
     */
    processRecording(blob: any) {
        const formData = new FormData();
        this.url = URL.createObjectURL(blob);
        const dataUser: any = {
            typeUser: 'user',
            typeDescription: this.sender,
            typeData: 'audio',
            time: this.chatService.convTime("time"),
            url: this.url
        };
        this.messagesHistory?.push(dataUser)
        formData.append('audio', blob);
        // formData.append('sessionId', this.chatService.sessionId);
        this.chatService.upload(formData);
        //window.open(this.url)
        this.record.clearRecordedData()
        // console.log(formData)
    }
    erraseRecording() {
        this.record.clearRecordedData()
        this.recording = false
    }

    /**
     * Sound On / Off
     */
    silence() {
        this.chatService.changeSound(!this.soundOff)
        if (this.soundOff == false) {
            this.soundImg = 'https://d3aiotmb6js9zr.cloudfront.net/assets/sonido_on@3x.svg'
        } else {
            this.soundImg = 'https://d3aiotmb6js9zr.cloudfront.net/assets/silencio@3x.svg'
        }
    }



    playAudio() {
        let audio = new Audio();
        audio.src = "https://d1c4i3m455m2f2.cloudfront.net/chatbot/assets/sounds/messageSound.mp3";
        audio.load();
        if (this.soundOff == false) {
            audio.play();
        }
    }


    /**
     * Funcion para deteccion de dispositivo
     */
    deviceType() {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return 'tablet';
        } else if (
            /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                ua
            )
        ) {
            return 'mobile';
        }
        return 'desktop';
    }

    /**
     *Agrega emoji a texto de input
     *
     * @param {*} event
     * @memberof AppComponent
     */
    addEmoji(event: any) {
        if (
            event.emoji.shortName === 'hankey' ||
            event.emoji.shortName === 'fist' ||
            event.emoji.shortName === 'middle_finger'
        ) {
            this.showAlert = true;
            setTimeout(() => {
                this.showAlert = false
            }, 3000)
        } else {
            this.textEmoji += event.emoji.native;
            this.displayEmojis = false
            this.showAlert = false;
        }
    }


    sendMessage(e: any) {
        e.preventDefault();
        if (this.typing === true || this.suggestionsBol === true || (this.textEmoji === '') || (this.textEmoji === ' ')) {
        } else {
            this.chatService.converse(this.textEmoji, false);
            if (this.textEmoji !== '') {
                this.textEmoji = '';
            }
            clearTimeout(this.timeoutId);
            clearTimeout(this.timeoutCloseId);
            this.checkTimeOut();
        }
    }

    sendAudio(e: any) {
        e.preventDefault();
        if (this.audioRecorded = true) {
            this.record.stop(this.processRecording.bind(this))
            this.recording = false
        }
    }

    handleKeyDown(e: any) {
        if (this.textEmoji != '') {
            this.userTyping = true;
        } else {
            this.userTyping = false;
        }
        if (e.keyCode === 13) {
            if (this.typing === true || this.suggestionsBol === true || (this.textEmoji === '') || (this.textEmoji === ' ')) {
            } else {
                this.sendMessage(e);
            }
            this.userTyping = false;
        }
    }

    /**
    *Controla show/hidde cuadro de emojis
    * @memberof AppComponent
    */
    toggleEmojis() {
        if (this.enableFiles == false) {
            this.displayEmojis = !this.displayEmojis;
            this.showAlert = false;
        }

    }

    inputTextClick() {
        if (this.displayEmojis == true) {
            this.displayEmojis = !this.displayEmojis;
        }
    }

    /**
     *Controla show/hidde cuadro de emojis
     *
     * @memberof AppComponent
     */
    toggleChatBot(msgSite?: string) {
        clearTimeout(this.closeTimer);
        this.displayChatbot = !this.displayChatbot;
        this.textEmoji = ''
        this.time = this.chatService.convTime("time");
        if (this.firstTime) {
            clearTimeout(this.timeoutId);
            clearTimeout(this.timeoutCloseId);
            this.checkTimeOut()
            this.chatService.changeFirstTime(false);
        }
        this.displayEmojis = false;
        this.showAlert = false;
        if (this.displayChatbot) {
            if (this.lang == 'es') {
                if (msgSite) {
                    this.chatService.converseStart(msgSite)
                } else {
                    this.chatService.converseStart("hola")
                }
            } else if (this.lang == 'en') {
                if (msgSite) {
                    this.chatService.converseStart(msgSite)
                } else {
                    this.chatService.converseStart("hi")
                }
            }
        } else {
            this.closeConversation()
        }
    }

    openModal() {
        let modal = window.document.getElementById('myModal');

        modal?.setAttribute('style', 'display: block;');
    }

    closeModal() {
        let modal = window.document.getElementById('myModal');

        modal?.setAttribute('style', 'display: none;');
    }

    sanitize(url: string) {
        return this.domSanitizer.bypassSecurityTrustUrl(url);
    }

    suggestionResponse(msg: string) {
        this.chatService.playAudio();
        this.chatService.converse(msg, false);
        this.chatService.changeSuggestionsBol(!this.suggestionsBol)
        this.chatService.changeSuggestionsValues([])
        clearTimeout(this.timeoutId);
        clearTimeout(this.timeoutCloseId);
        this.checkTimeOut();
    }

    closeConversation() {
        if (this.displayChatbot) {
            this.toggleChatBot();
            this.chatService.changeSuggestionsBol(!this.suggestionsBol)
            this.chatService.changeSuggestionsValues([])
        }
        clearTimeout(this.timeoutId);
        clearTimeout(this.timeoutCloseId);
        clearTimeout(this.closeTimer)
        this.chatService.changeCurrentSession("");
        this.chatService.changeEnableSurvey(false);
        this.chatService.changeEnableConv(false);
        this.chatService.changeFirstTime(true);
        this.messagesHistory = [];
    }


}
