export const environment = {
  production: true,
  //baseUrl: 'https://pre-chatbot.2bcore.com.mx',
  siteUrl: 'https://2bcore.com',
  //siteUrl: 'https://pre-site.2bcore.com.mx',
  baseUrl: 'https://api.2bcore.com',
  recaptcha: {
    siteKey: '6LdZ474hAAAAAGar3vDBzDbDsZeD6GinbD-Rkp7Z',
  },
};
