import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {  catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TowersService {
  public activeTowerService = new BehaviorSubject<string | null>('');
  public activeAlliance= new BehaviorSubject<string | null>('');
   public activeRoute= new BehaviorSubject<string | null>('');
  constructor(
    private httpClient: HttpClient,
  ) { }


sendEmail(dataEmail: any) {
//return this.httpClient.post<any>('https://pre-chatbot.2bcore.com.mx/site/sendContactEmail', {data: dataEmail}).subscribe(data => {
return this.httpClient.post<any>('https://api.2bcore.com/site/sendContactEmail', {data: dataEmail}).subscribe(data => {
 //console.log(data);
 });
}
}
