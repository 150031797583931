import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { observe } from 'rxjs-observe';
import { Angulartics2 } from 'angulartics2';
import { HttpClient } from '@angular/common/http';

const URL_BASE = 'http://localhost:3000';

@Injectable({
	providedIn: 'root'
})
export class TranslationService {

	currentLang;
	platform: any;

	constructor(
		public translate: TranslateService,
		private angulartics2: Angulartics2,
		private httpClient: HttpClient,
	) {
		translate.addLangs(['es', 'en']);
		translate.setDefaultLang('es');

		const language = translate.getBrowserLang() ?? 'es';

        translate.use(language);

        //translate.use(translate.getBrowserLang().match(/es|en/) ? translate.getBrowserLang() : 'es');

		this.currentLang = observe({ lang: this.translate.currentLang });

	}

	/**
	 * Cambia el lenguaje del sitio web
	 *
	 * @param {string} language
	 * @memberof TranslationService
	 */
	async changeLanguage(language: string) {
		this.translate.use(language);
		this.currentLang.proxy.lang = language;
		if (this.platform?.isBrowser) {
			// (<any>window).ga('send', 'event', 'Language', 'change', language, 5);
			this.angulartics2.eventTrack.next({
				action: 'change',
				properties: {
					category: 'Language',
					value: 10,
					label: 'Changed to ' + language === 'es' ? 'Spanish' : 'English'
				}
			});
		}

		await this.notifyChangeLanguage(language)
	}

	async notifyChangeLanguage(language: string) {
		const data = { language }
		return this.httpClient.put(`${URL_BASE}/general/language`, { data }).subscribe(console.log);
	}
}
