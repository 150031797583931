import { ALLIANCES_ID } from "./alliances.const"
import { TOWER_SERVICES_ID } from "./home-services.const"

// Identificador de las diferentes secciones del sitio. ej. id="#customers"
export enum CAT_FRAGMENT_ID {
    // HOME_PAGE
    'customers' = 'customers',
    'sedes' = 'sedes',
    'contact' = 'contact',
    // SERVICIOS
    'serviceTower' = 'serviceTower',
    // NOSOTROS
    'aboutUs' = 'aboutUs',
    'metodology' = 'metodology',
    'certifications' = 'certifications',
    // ALIANZAS
    'alliances' = 'alliances',
    // CARRERAS
    'careers' = 'careers',
    'collaborators' = 'collaborators',
    'programs' = 'programs',
    // FOOTER
    // COOKIES
    'footer' = 'footer',
}

// Identificador para los pricipales componentes
export const enum CAT_COMPONENT {
    'HOME_PAGE',
    'SERVICIOS',
    'NOSOTROS',
    'ALIANZAS',
    'CARRERAS',
    'COOKIES',
    'FOOTER',
}

// Relacion de segmentos a nivel de informacion con 
// las secciones reales del sitio (ej. Clientes : #customers)
export const enum CAT_SECTION {
    // HOME_PAGE
    'Clientes' = CAT_FRAGMENT_ID.customers,
    'NuestrasSedes' = CAT_FRAGMENT_ID.sedes,
    'Contacto' = CAT_FRAGMENT_ID.contact,

    // SERVICIOS
    'TorresDeServicio' = CAT_FRAGMENT_ID.serviceTower,
    'SolutionsDevelopment' = TOWER_SERVICES_ID.SOLUTIONS_DEVELOPMENT,
    'SpecializedServices' = TOWER_SERVICES_ID.SPECIALIZED_SERVICES,
    'NormalizationProcesses' = TOWER_SERVICES_ID.NORMALIZATTION_PROCESS,
    'Institute' = TOWER_SERVICES_ID.INSTITUTE,
    'Talents' = TOWER_SERVICES_ID.TALENTS,

    // NOSOTROS
    'QuienesSomos' = CAT_FRAGMENT_ID.aboutUs,
    'NuestraMetodologia' = CAT_FRAGMENT_ID.metodology,
    'Certificaciones' = CAT_FRAGMENT_ID.certifications,

    // ALIANZAS
    'Alianzas' = CAT_FRAGMENT_ID.alliances,
    'AWS' = ALLIANCES_ID.AWS,
    'SAP' = ALLIANCES_ID.SAP,
    'Salesforce' = ALLIANCES_ID.SALESFORCE,
    'IBMSterling' = ALLIANCES_ID.IBM,
    'RedHat' = ALLIANCES_ID.REDHAT,
    'MicrosoftAzure' = ALLIANCES_ID.AZURE,
    'Terraform' = ALLIANCES_ID.TERRAFORM,
    'Redis' = ALLIANCES_ID.REDIS,
    'NYCE' = ALLIANCES_ID.NYCE,
    'Ingram' = ALLIANCES_ID.INGRAM,

    // CARRERAS
    'Carrera' = CAT_FRAGMENT_ID.careers,
    'Testimonios' = CAT_FRAGMENT_ID.collaborators,
    'Programas' = CAT_FRAGMENT_ID.programs,
    'Becarios' = CAT_FRAGMENT_ID.programs,
    'RecienEgresados' = CAT_FRAGMENT_ID.programs,
    'Profesionales' = CAT_FRAGMENT_ID.programs,
    'ServicioSocialOPracticasProfesionales' = CAT_FRAGMENT_ID.programs,

    // COOKIES
    'PoliticaDeCookies' = CAT_FRAGMENT_ID.footer,

    // FOOTER
    'AvisoDePrivacidad' = CAT_FRAGMENT_ID.footer,
    'PoliticaDeSeguridadDeLaInformacion' = CAT_FRAGMENT_ID.footer,
}


export const CAROUSEL_DATA = [
    {
       view: CAT_COMPONENT.HOME_PAGE,
       sections: [
           {
               id: CAT_SECTION.Clientes,
               name: 'Clientes',
               keyWords: [
                   'Clientes'
               ]
           },
           {
            id: CAT_SECTION.NuestrasSedes,
            name: 'Nuestras sedes',
            keyWords: [
                'Nuestras sedes',
                'sedes',
                'ubicaciones',
                'mapa',
                'dirección',
                'CDMX',
                'Houston',
                'Querétaro',
                'Guadalajara',
                'Monterrey',
                'Oficinas'
                ]
            },
            {
                id: CAT_SECTION.Contacto,
                name: 'Contacto',
                keyWords: [
                    'Contacto',
                    'preguntas', 
                    'asesoría',
                    'correo',
                    'dudas',
                    'información',
                    'presupuestos']
                }
       ]
    },
    {
        view: CAT_COMPONENT.SERVICIOS,
        sections: [
            {
                id: CAT_SECTION.TorresDeServicio,
                name: 'Torres de servicio',
                keyWords: [
                'Torres de servicio',
                 'nube',
                  'Desarrollo app',
                   'aplicaciones',
                    'web',
                     'ios',
                      'Servicios',
                       'Soluciones empresariales',
                        'Resultados',
                         'Sistemas de negocio']
            },
            {
             id: CAT_SECTION.SolutionsDevelopment,
             name: 'Solutions​ Development​',
             keyWords: [
                 'Solutions​ Development​', 
                 'CX & UX Services', 
                 'Web Portal', 
                 'Mobile solutions​']
             },
             {
                id: CAT_SECTION.SpecializedServices,
                name: 'Specialized​ Services​',
                 keyWords: [
                'Specialized​ Services​', 
                 'Specialized IT Services​​', 
                 'Staff Augmentation']
             },
            {
            id: CAT_SECTION.NormalizationProcesses,
            name: 'Normativity & Processes',
            keyWords: [
                'Normativity & Processes​', 
                'Process Analysis & Improvement​', 
                'ISO Certification​​', 
                'Compliance Consulting']
            },
            {
                id: CAT_SECTION.Institute,
                name: '2BInstitute​',
                keyWords:[
                '2BInstitute', 
                'LMS Platform', 
                'Cursos', 
                'Training and incubation', 
                'Certifications and diplomas', 
                'Coaching', 
                'plataforma de estudio', 
                'diplomas', 
                'certificado', 
                'plataforma educativa', 
                'incubación de talento',
                'desarrollo de habilidades técnicas',
                'desarrollo de habilidades profesionales', 
                'Capacitación']
                },
                {
                    id: CAT_SECTION.Talents,
                    name: '2BTalents',
                    keyWords:[
                    '2BTalents',
                     'RH', 
                     'RRHH',
                     'Recursos humanos', 
                     'Nom-035', 
                     'buzón de quejas y sugerencias', 
                     'comunicación interna', 
                     'guías', 
                     'pruebas psicométricas',
                      'estudios socioeconómicos', 
                      'clima laboral y riesgo',
                       'Digitalización de RRHH', 
                       'Dashboard', 
                       'Evaluación de Desempeño', 
                       'Encuesta de clima', 
                       'Plataforma de RRHH']
                    },
        ]
     },
     {
        view: CAT_COMPONENT.NOSOTROS,
        sections: [
            {
                id: CAT_SECTION.QuienesSomos,
                name: '¿Quiénes somos?',
                keyWords: [
                    '2BCORE', 
                    'Innovación tecnológica', 
                    'Integración de sistemas', 
                    'Objetivo', 
                    'Impulsar',
                     'Logro de metas', 
                     'Misón', 
                     'Soluciones tecnológicas', 
                     'Objetivos estratégicos', 
                     'Visión', 
                     'Planes de negocio', 
                     'Soluciones de valor', 
                     'Ética', 
                     'Mejores prácticas']
            },
            {
             id: CAT_SECTION.NuestraMetodologia,
             name: 'Nuestra metodología',
             keyWords: [
                 'Metodo', 
                 'Metodología', 
                 'Orientada al usuario', 
                 'Estandares de calidad', 
                 'Valor agregado', 
                 'Empatizar',
                  'Necesidades', 
                  'Definir', 
                  'Problemas', 
                  'Idear', 
                  'Crea ideas', 
                  'Prototipar', 
                  'Soluciones mediante prototipos', 
                  'DevOps', 
                  'Desarrollo de software', 
                  'Operaciones de TI',
                   'Test and learn', 
                   'Prueba tus soluciones']
             },
             {
                 id: CAT_SECTION.Certificaciones,
                 name: 'Certificaciones',
                 keyWords: [
                     'Certificacion', 
                     'Certificaciones', 
                     'Norma NMX-i-27001-NYCE-2015', 
                     'ISO/IEC 27001:2015']
                 }
        ]
     },
     {
        view: CAT_COMPONENT.ALIANZAS,
        sections: [
            {
                id: CAT_SECTION.Alianzas,
                name: 'Alianzas',
                keyWords:[
                    'Alianzas', 
                    'Aliados',
                    'Empresas asociadas', 
                    'Asociados', 
                    'Desarrollo rápido y eficiente']
            },
            {
                id: CAT_SECTION.AWS,
                name: 'AWS',
                keyWords: [
                    'AWS', 
                    'Cloud Computing', 
                    'Bases de datos', 
                    'Redes virtuales', 
                    'Aplicaciones empresariales',
                    'Almacenamiento y gestores de contenido',
                    'Inteligencia de negocio',
                    'Gestión de aplicaciones móviles',
                    'Internet de las cosas',
                    'Herramientas para desarrolladores',
                    'IaaS',
                    'PaaS',
                    'SaaS',
                    'IoT',
                    'Seguridad y control de acceso']
           },
            {
             id: CAT_SECTION.SAP,
             name: 'SAP',
             keyWords: [
                 'SAP', 
                 'Desarrollo ABAP', 
                 'ABAP', 
                 'ABAP OO Custom', 
                 'Integraciones SAP', 
                 'Integraciones no SAP', 
                 'Webservices', 
                 'Odata services con SAP Gateway', 
                 'Customizaciones de código', 
                 'ABAP S/4 HANA']
             },
             {
                 id: CAT_SECTION.Salesforce,
                 name: 'Salesforce',
                 keyWords: [
                     'Salesforce', 
                     'Soporte y AMS​', 
                     'Assesment​', 
                     'Implementación desde 0', 
                     'Desarrollador Salesforce']
            },
            {
                id: CAT_SECTION.IBMSterling,
                name: 'IBM Sterling',
                keyWords: ['IBM Sterling', 
                'Suite IBM Sterling', 
                'HealthCheck y afinación', 
                'Soporte a la producción']
           },
           {
            id: CAT_SECTION.RedHat,
            name: 'Red Hat',
            keyWords: ['Red Hat', 'Open Source']
        },
           {
            id: CAT_SECTION.MicrosoftAzure,
            name: 'Microsoft Azure',
            keyWords: ['Microsoft Azure', 
            'Computación en la nube', 
            'SaaS', 
            'PaaS', 
            'IaaS', 
            'Azure', 
            'Azure Virtual Machines Linux', 
            'Azure Virtual Machines Windows', 
            'Azure SQL Database', 
            'Azure Blob Storage', 
            'Azure Cosmos DB', 
            'Azure Fuctions', 
            'Azure Event Grid']
             }
             ,
           {
            id: CAT_SECTION.Terraform,
            name: 'Terraform',
            keyWords: [
                'Terraform', 
                'HashiCorp', 
                'Kubernetes', 
                'Automatize Infraestructura de red', 
                'código en ambientes multi-nube']
            }
            ,
           {
            id: CAT_SECTION.Redis,
            name: 'Redis',
            keyWords:[
                'Redis', 
                'TCO atractivo', 
                'migrar a la nube', 
                'Azure Cosmos DBServicios']
            },
            {
                id: CAT_SECTION.NYCE,
                name: 'NYCE',
                keyWords: ['NYCE', 
                'ONN', 
                'Normalización', 
                'NYCE Laboratorios', 
                'NYCE Certificación e Inspección', 
                'NYCE Asia']
            }
            ,
            {
                id: CAT_SECTION.Ingram,
                name: 'Ingram',
                keyWords: [
                    'Ingram', 
                    'cadena de suministros',
                     'Ingram Micro Financial Solutions', 
                     'Digital performance tool', 'Alta web', 
                     'Ingram Micro Seguros', 
                     'E-Commerce', 
                     'Servicios electrónicos', 
                     'Condiciones comerciales y legales']
            }
           
        ]
     },
     {
        view: CAT_COMPONENT.CARRERAS,
        sections: [
            {
                id: CAT_SECTION.Carrera,
                name: 'Carrera',
                keyWords: [
                    'Carrera', 
                    'programa', 
                    'desarrollo humano', 
                    'talento', 
                    'colaboradores', 
                    'trabajo', 
                    'vacante', 
                    'curriculum']
            },
            {
             id: CAT_SECTION.Testimonios,
             name: 'Testimonios',
             keyWords: [
                 'Testimonios', 
                 'reseñas', 
                 'Valoración']
             },
             {
                 id: CAT_SECTION.Programas,
                 name: 'Programas',
                 keyWords: [
                     'Programas', 
                     'Empleo', 
                     'Trabajo', 
                     'postulate', 
                     'Desarrollo de potencial', 
                     'Desarrollo de competencias']
            }
            ,
             {
                 id: CAT_SECTION.Becarios,
                 name: 'Becarios',
                 keyWords: ['Becarios', 'Estudiantes de carreras TI', 'Beca económica', 'seguro de gastos médicos menores']
            }
            ,
             {
                 id: CAT_SECTION.RecienEgresados,
                 name: 'Recién egresados',
                 keyWords: ['Recién egresados', 'egresado', 'Plan de carrera']
            }
            ,
             {
                 id: CAT_SECTION.Profesionales,
                 name: 'Profesionales',
                 keyWords: ['Profesionales', 'Prestaciones superiores', 'Días de descanso adicionales', 'Horarios híbridos', 'movilidad nacional e internacional', 'Apoyo oftalmológico y odontológico.']
            },
            {
                id: CAT_SECTION.ServicioSocialOPracticasProfesionales,
                name: 'Servicio social o prácticas profesionales',
                keyWords: [
                    'Servicio social o prácticas profesionales',]
           }
        ]
     },
     {
        view: CAT_COMPONENT.COOKIES,
        sections: [
            {
                id: CAT_SECTION.PoliticaDeCookies,
                name: 'Política de cookies',
                keyWords: ['Política de cookies', 'Cookies', 'Estrictamente necesarias', 'Rendimiento', 'Funcionales', 'Analíticas', 'Cookies de sesión', 'Cookies permanentes', 'Controlar cookies']
            }
        ]
     },
     {
        view: CAT_COMPONENT.FOOTER,
        sections: [
            {
                id: CAT_SECTION.AvisoDePrivacidad,
                name: 'Aviso de privacidad',
                keyWords: ['Aviso de privacidad', 'Aviso', 'Privacidad']
            },
            {
                id: CAT_SECTION.PoliticaDeSeguridadDeLaInformacion,
                name: 'Politica de seguridad de la información',
                keyWords: ['Politica de seguridad de la información']
            }
        ]
     },
]


export const CAROUSEL_DATA_ENG = [
    {
       view: CAT_COMPONENT.HOME_PAGE,
       sections: [
           {
               id: CAT_SECTION.Clientes,
               name: 'Clients',
               keyWords: [
                   'Clients'
               ]
           },
           {
            id: CAT_SECTION.NuestrasSedes,
            name: 'Our locations',
            keyWords: [
                'Our locations',
                'sites',
                'locations',
                'map',
                'direction',
                'Mexico City',
                'Houston',
                'Queretaro',
                'Guadalajara',
                'Monterrey',
                'Offices'
                ]
            },
            {
                id: CAT_SECTION.Contacto,
                name: 'Contact',
                keyWords: [
                    'Contact',
                    'Questions', 
                    'consultancy',
                    'e-mail',
                    'mail',
                    'questions',
                    'information',
                    'budgets']
                }
       ]
    },
    {
        view: CAT_COMPONENT.SERVICIOS,
        sections: [
            {
                id: CAT_SECTION.TorresDeServicio,
                name: 'Services',
                keyWords: [
                'Service Towers',
                 'Cloud',
                  'app development',
                   'aplicaciones',
                    'apps',
                    'web',
                     'ios',
                      'services',
                       'Enterprise Solutions',
                        'Results',
                         'Business Systems']
            },
            {
             id: CAT_SECTION.SolutionsDevelopment,
             name: 'Solutions​ Development​',
             keyWords: [
                 'Solutions​ Development​', 
                 'CX & UX Services', 
                 'Web Portal', 
                 'Mobile solutions​']
             },
             {
                id: CAT_SECTION.SpecializedServices,
                name: 'Specialized​ Services​',
                 keyWords: [
                'Specialized​ Services​', 
                 'Specialized IT Services​​', 
                 'Staff Augmentation']
             },
            {
            id: CAT_SECTION.NormalizationProcesses,
            name: 'Normativity & Processes​',
            keyWords: [
                'Normativity & Processes​', 
                'Process Analysis & Improvement​', 
                'ISO Certification​​', 
                'Compliance Consulting']
            },
            {
                id: CAT_SECTION.Institute,
                name: '2BInstitute​',
                keyWords:[
                '2BInstitute', 
                'LMS Platform', 
                'Courses', 
                'Training and incubation', 
                'Certifications and diplomas', 
                'Coaching', 
                'Study plataforma', 
                'diplomas', 
                'Certificate', 
                'Education platfomr', 
                'Talen incubation',
                'Technical skills development',
                'Professional skil development', 
                'Training']
                },
                {
                    id: CAT_SECTION.Talents,
                    name: '2BTalents',
                    keyWords:[
                    '2BTalents',
                     'HR',
                     'HHRR', 
                     'Human Resources', 
                     'Nom-035', 
                     'Complaints and suggestions mailbox', 
                     'Internal communication', 
                     'guides', 
                     'psychometric tests',
'socio-economic studies',
'work environment and risk',
'HR Digitalization',
'Dashboard',
'Performance Evaluation',
'Climate survey',
'HR Platform']
                    },
        ]
     },
     {
        view: CAT_COMPONENT.NOSOTROS,
        sections: [
            {
                id: CAT_SECTION.QuienesSomos,
                name: 'About Us',
                keyWords: [
                    'Technology innovation',
'System integration',
'Objective',
'Promote',
'Goal Achievement',
'Mision',
'Technological solutions',
'Strategic objectives',
'Vision',
'Business plan',
'Value solutions',
'Ethics',
'Best practices']
            },
            {
             id: CAT_SECTION.NuestraMetodologia,
             name: 'Methodology',
             keyWords: [
                'Method',
                'Methodology',
                'User oriented',
                'Quality standards',
                'Added value',
                'Empathize',
                'Needs',
                'Define',
                'Problems',
                'Devise',
                'Create ideas',
                'Prototyping',
                'Prototype solutions',
                'DevOps',
                'Software development',
                'IT Operations',
                'Test and learn',
                'Test your solutions']
             },
             {
                 id: CAT_SECTION.Certificaciones,
                 name: 'Certifications',
                 keyWords: [
                     'Certification', 
                     'Certifications', 
                     'Norm NMX-i-27001-NYCE-2015', 
                     'ISO/IEC 27001:2015']
                 }
        ]
     },
     {
        view: CAT_COMPONENT.ALIANZAS,
        sections: [
            {
                id: CAT_SECTION.Alianzas,
                name: 'Partners',
                keyWords:[
                    'Partners', 
                    'Alliances',
                    'Associated companies',
'Associates',
'Fast and efficient development']
            },
            {
                id: CAT_SECTION.AWS,
                name: 'AWS',
                keyWords: [
                    'AWS', 
                    'Cloud Computing', 
                    'Databases', 
                    'Virtual networks', 
                    'Aplicaciones empresariales',
                    'Business applications',
                    'Business Intelligence',
                    'Internet of things',
                    'Developer Tools',
                    'IaaS',
                    'PaaS',
                    'SaaS',
                    'IoT',
                    'Security and access control']
           },
            {
             id: CAT_SECTION.SAP,
             name: 'SAP',
             keyWords: [
                 'SAP', 
                 'ABAP development',
                 'ABAP',
                 'ABAP OO Custom',
                 'SAP Integration',
                 'Non SAP integrations',
                 'Webservices',
                 'Odata services con SAP Gateway',
                 'Code customization',
                 'ABAP S/4 HANA']
             },
             {
                 id: CAT_SECTION.Salesforce,
                 name: 'Salesforce',
                 keyWords: [
                     'Salesforce', 
                     'AMS and Support',
'Assesment',​
'Implementation from 0',
'Salesforce Developer']
            },
            {
                id: CAT_SECTION.IBMSterling,
                name: 'IBM Sterling',
                keyWords: ['IBM Sterling', 
                'Suite IBM Sterling', 
'HealthCheck and setup',
'Production support']
           },
           {
            id: CAT_SECTION.RedHat,
            name: 'Red Hat',
            keyWords: ['Red Hat', 'Open Source']
        },
           {
            id: CAT_SECTION.MicrosoftAzure,
            name: 'Microsoft Azure',
            keyWords: ['Microsoft Azure', 
            'Computación en la nube', 
            'SaaS', 
            'PaaS', 
            'IaaS', 
            'Azure', 
            'Azure Virtual Machines Linux', 
            'Azure Virtual Machines Windows', 
            'Azure SQL Database', 
            'Azure Blob Storage', 
            'Azure Cosmos DB', 
            'Azure Fuctions', 
            'Azure Event Grid']
             }
             ,
           {
            id: CAT_SECTION.Terraform,
            name: 'Terraform',
            keyWords: [
                'Terraform', 
                'HashiCorp', 
                'Kubernetes', 
                'Automatize Network Infrastructure',
'code in multi-cloud environments']
            }
            ,
           {
            id: CAT_SECTION.Redis,
            name: 'Redis',
            keyWords:[
                'Redis', 
                'TCO atractivo', 
                'Cloud migration', 
                'Azure Cosmos DBServices']
            },
            {
                id: CAT_SECTION.NYCE,
                name: 'NYCE',
                keyWords: ['NYCE', 
                'ONN', 
                'Normatividad', 
                'NYCE Laboratories', 
                'NYCE Certification and inspection', 
                'NYCE Asia']
            }
            ,
            {
                id: CAT_SECTION.Ingram,
                name: 'Ingram',
                keyWords: [
                    'Ingram', 
                    'supply chain',
'Ingram Micro Financial Solutions',
'Digital performance tool',
'Web registration',
'Ingram Micro Insurance',
'E-Commerce',
'Electronic services',
'Commercial and legal conditions']
            }
            ,
            
        ]
     },
     {
        view: CAT_COMPONENT.CARRERAS,
        sections: [
            {
                id: CAT_SECTION.Carrera,
                name: 'Careers',
                keyWords: [
                    'Professionals',
'Higher benefits',
'Additional days off',
'Hybrid schedules',
'domestic and international mobility',
'Ophthalmology and dental support',
'Social service or professional internship']
            },
            {
             id: CAT_SECTION.Testimonios,
             name: 'Testimonials',
             keyWords: [
                 'Testimonials', 
                 'reviewa', 
                 'Assessment']
             },
             {
                 id: CAT_SECTION.Programas,
                 name: 'Programs',
                 keyWords: [
                     'Programs', 
                     'Employee', 
                     'Work', 
                     'Join us', 
                     'potential development', 
                     'skills development']
            }
            ,
             {
                 id: CAT_SECTION.Becarios,
                 name: 'Scolars',
                 keyWords: ['Scolars', 'IT career students', 'economic scholarship', 'minor medical expenses insurance']
            }
            ,
             {
                 id: CAT_SECTION.RecienEgresados,
                 name: 'Recently graduated',
                 keyWords: ['Recently graduated', 'graduated', 'career plan']
            }
            ,
             {
                 id: CAT_SECTION.Profesionales,
                 name: 'Professionals',
                 keyWords: ['Professionals', 'superior performance', 'Additional rest days', 'hybrid schedules', 'national and international mobility', 'Ophthalmological and dental support']
            },
            {
                id: CAT_SECTION.ServicioSocialOPracticasProfesionales,
                name: 'Social servicer or professional internship',
                keyWords: [
                    'Social servicer or professional internship',]
           }
        ]
     },
     {
        view: CAT_COMPONENT.COOKIES,
        sections: [
            {
                id: CAT_SECTION.PoliticaDeCookies,
                name: 'Cookie policy',
                keyWords: ['Cookies',
                'Strictly necessary',
                'Performance',
                'Functions',
                'Analytics',
                'Session Cookies',
                'Permanent Cookies',
                'Control cookies']
            }
        ]
     },
     {
        view: CAT_COMPONENT.FOOTER,
        sections: [
            {
                id: CAT_SECTION.AvisoDePrivacidad,
                name: 'Privacy',
                keyWords: ['Privacy', 'Notice', 'Privacy']
            },
            {
                id: CAT_SECTION.PoliticaDeSeguridadDeLaInformacion,
                name: 'Information security policy',
                keyWords: ['Information of the security policy, Information security policy']
            }
        ]
     },
]



