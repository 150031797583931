import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CAROUSEL_ALLLIANCES, ALLIANCES, ALLIANCES_ENG, ALLIANCES_SECTIONS } from 'src/app/constants/alliances.const';

import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { Router } from '@angular/router';
import { TowersService } from 'src/app/services/towers.service';
import { TranslationService } from 'src/app/services/translate/translation.service';
import { Angulartics2 } from 'angulartics2';
import { DimentionService } from 'src/app/services/dimention/dimention.service';
import { CAT_FRAGMENT_ID } from 'src/app/constants/search-word.const';
import { LoaderService } from 'src/app/services/loader/loader.service';
SwiperCore.use([ Navigation, Autoplay ]);
@Component({
  selector: 'app-alliances',
  templateUrl: './alliances.component.html',
  styleUrls: ['./alliances.component.scss']
})

export class AlliancesComponent implements OnInit {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  readonly IDS = CAT_FRAGMENT_ID;
  readonly alliancesIds = ALLIANCES_SECTIONS;

  // Constantes de iconos para carrusel
  carouselData = CAROUSEL_ALLLIANCES;
  // Alianza activa
  activeAlliance: any;
  isDesktop: any;
  // indice de alianza activa
  activeIndex = 0;
  ALLIANCES_TRANSLATED : any;
  currentLang: string | any;
  // Configuracion general de carrusel de alianzas
  swiperConfig: any = {
    loopAdditionalSlides: 0,
    allowTouchMove: true,
    slideToClickedSlide: true,
    initialSlide: 0,
    loopedSlides: 7,
    loop : true,
    centeredSlides: true,
    slidesPerView: 3,
    on: { 
      realIndexChange: (index: any) => { 
       this.setActiveSlide(index.realIndex);
      }, 
    },
    navigation: {
      nextEl: '.icon-down',
      prevEl: '.icon-up',
    },
    direction: 'vertical',
  }
  swiperConfigMobile: any = {
    loopAdditionalSlides: 0,
    allowTouchMove: true,
    slideToClickedSlide: true,
    initialSlide: 0,
    loopedSlides: 7,
    loop : true,
    centeredSlides: true,
    slidesPerView: 1.8,
    on: { 
      realIndexChange: (index: any) => { 
       this.setActiveSlide(index.realIndex);
      }, 
    },
    navigation: {
      nextEl: '.icon-down',
      prevEl: '.icon-up',
    },
    direction: 'horizontal',
  }
  swiperAux?: SwiperCore;
  constructor(
    private router: Router,
    private towerService: TowersService,
    public translation: TranslationService,
    private angulartics2: Angulartics2,
    private loaderService: LoaderService,
    private changeDetectorRef: ChangeDetectorRef,
    private dimention: DimentionService
  ) { }

  ngOnInit(): void {
     this.loaderService.isLoading.next(true);
    setTimeout(() => {
      this.loaderService.isLoading.next(false);
    }, 1000);
    this.dimention.widthSize.subscribe((resp: any) => {      
      this.isDesktop = resp < 768 ? false : true;
    });
    this.translation.currentLang.observables.lang.subscribe((lang : any) => 
    {
      this.currentLang = lang;
      
      this.ALLIANCES_TRANSLATED =  this.currentLang === 'es' ? ALLIANCES : ALLIANCES_ENG;
      this.setActiveSlide(this.activeIndex);
      
    }
  );
  this.activeAlliance.services.forEach((elem:any, idx: any) => {
    elem.active = false;
  });
  this.initServiceActive();
 
    
  }
  
  /**
   *Funcion para cambiar de slide y actualizar indice activo
   *
   * @memberof AlliancesComponent
   */
  setActiveSlide(index: any) {
    
    this.activeIndex = index;
    let stringAlliance  = Object.keys(this.currentLang === 'es' ? ALLIANCES : ALLIANCES_ENG)[this.activeIndex];
    var map = new Map(Object.entries(this.currentLang === 'es' ? ALLIANCES : ALLIANCES_ENG)); 
    map.forEach((element) => {
      element.services.forEach((service: any) => {
        service.active = false;
      });      
    });
    this.activeAlliance = this.currentLang !== 'es' ? this.ALLIANCES_TRANSLATED[stringAlliance  as keyof typeof ALLIANCES_ENG] : ALLIANCES[stringAlliance  as keyof typeof ALLIANCES]; 
    this.changeDetectorRef.detectChanges(); 
  }
  
  serviceAtcive(index: any) {
    this.activeAlliance.services.forEach((elem:any, idx: any) => {
      if (index === idx) {
        elem.active = !elem.active;
      } else {
        elem.active = false;
      }
  
    });
    this.changeDetectorRef.detectChanges(); 
  }
  onSlideChange(e: SwiperCore[]) {
    this.swiperAux = e[0];
    }
  
  initServiceActive() {
    this.towerService.activeAlliance.subscribe((tower:any) => {  
      switch (tower) {
        case 'AWS':
          this.activeAlliance = this.ALLIANCES_TRANSLATED.AWS;
          this.activeIndex = 0;
          this.swiperAux?.slideToLoop(this.activeIndex);
          break;

        case 'SAP':
          this.activeAlliance = this.ALLIANCES_TRANSLATED.SAP;
          this.activeIndex = 7;
          this.swiperAux?.slideToLoop(this.activeIndex);
          break;

        case 'SALESFORCE':
          this.activeAlliance = this.ALLIANCES_TRANSLATED.SALESFORCE;
          this.activeIndex = 6;
          this.swiperAux?.slideToLoop(this.activeIndex);
             this.changeDetectorRef.detectChanges(); 
          break;

        case 'IBM':
          this.activeAlliance = this.ALLIANCES_TRANSLATED.IBM;
          this.activeIndex = 2;
          this.swiperAux?.slideToLoop(this.activeIndex);
          break;

        case 'REDHAT':
          this.activeAlliance = this.ALLIANCES_TRANSLATED.REDHAT;
          this.activeIndex = 5;
          this.swiperAux?.slideToLoop(this.activeIndex);
          break;

        case 'AZURE':
          this.activeAlliance = this.ALLIANCES_TRANSLATED.AZURE;
          this.activeIndex = 1;
          this.swiperAux?.slideToLoop(this.activeIndex);
          break;

         case 'TERRAFORM':
          this.activeAlliance = this.ALLIANCES_TRANSLATED.TERRAFORM;
          this.activeIndex = 8;
            this.swiperAux?.slideToLoop(this.activeIndex);
          break;

          case 'REDIS':
            this.activeAlliance = this.ALLIANCES_TRANSLATED.REDIS;
            this.activeIndex = 4;
            this.swiperAux?.slideToLoop(this.activeIndex);
            break;

          case 'NYCE':
              this.activeAlliance = this.ALLIANCES_TRANSLATED.NYCE;
              this.activeIndex = 3;
              this.swiperAux?.slideToLoop(this.activeIndex);
              break;


          default:
            this.activeAlliance = this.ALLIANCES_TRANSLATED.AWS;
            this.activeIndex = 0;
            this.swiperAux?.slideToLoop(this.activeIndex);
            break;
      }
    });
  }
 

  goToHome() {
    this.router.navigate(['/']);
  }




}
