<div class="bg-blue-dark" style="margin-top: 90px;">
  <div class="container px-md-0" id="contact">
    <div class="row separate-back">
      <div class="col-12 col-lg-6 padding-contact d-flex align-items-center" (click)="goToHome()" style="cursor: pointer; line-height: 24px;">
          <span class="icon-Regresar me-2 fs-18" style="cursor: pointer;"></span> <span class="d-none d-lg-block" (click)="goToHome()"> {{ 'back.btn' | translate }}</span>
      </div>
</div>
    <div class="row">
      <div class="col-12 col-lg-6 padding-contact">
        <div class="contacto-resp">
          <p class="fs-md-36 fs-24 text-end title-text" >{{ "contact.title" | translate }}</p>
          <p class="fs-md-18 fs-16 text-end body-text">{{ "contact.subtitle" | translate }}</p>
        </div>
        <div class="card card-contact">
          <form [formGroup]="formContact">
            <div >
              <label for="name" class="mb-2 fs-18 fw-regular text-label">{{ "contact.name" | translate }}</label>
              <input type="text" class="form-control fw-regular" id="name" formControlName="name"
                [ngClass]="(formContact.controls['name'].dirty || formContact.controls['name'].touched) && formContact.controls['name'].invalid ? 'error-input': ''"
                placeholder="{{'contact.p-name' | translate}}">
              <label class="error-text fw-regular">
                <span *ngIf="((formContact.controls['name'].dirty || formContact.controls['name'].touched) && formContact.controls['name'].invalid) && formContact.get('name')?.errors?.['required']"> {{ "contact.obligatory" | translate }} </span>
                <span *ngIf="((formContact.controls['name'].dirty || formContact.controls['name'].touched) && formContact.controls['name'].invalid) && formContact.get('name')?.errors?.['pattern']"> {{ "contact.error" | translate }}</span>
              </label>
            </div>

            <div >
              <label for="mail" class="mb-2 fs-18 fw-regular text-label">{{ "contact.mail" | translate }}</label>
              <input type="text" class="form-control fw-regular" id="mail" formControlName="email"
                [ngClass]="(formContact.controls['email'].dirty || formContact.controls['email'].touched) && (formContact.controls['email'].invalid || formContact.get('email')?.errors?.['email']) ? 'error-input': ''"
                placeholder="{{'contact.p-mail' | translate}}">
              <label class="error-text fw-regular">
                <span *ngIf="((formContact.controls['email'].dirty || formContact.controls['email'].touched) && (formContact.controls['email'].invalid || formContact.get('email')?.errors?.['email'])) && formContact.get('email')?.errors?.['required']"> {{ "contact.obligatory" | translate }} </span>
                <span *ngIf="((formContact.controls['email'].dirty || formContact.controls['email'].touched) && (formContact.controls['email'].invalid || formContact.get('email')?.errors?.['email'])) && formContact.get('email')?.errors?.['email']"> {{ "contact.error" | translate }}</span>
              </label>
            </div>

            <div >
              <label for="phone" class="mb-2 fs-18 fw-regular text-label">{{ "contact.phone" | translate }}</label>
              <input type="text" class="form-control fw-regular" id="phone" formControlName="phone"
                [ngClass]="(formContact.controls['phone'].dirty || formContact.controls['phone'].touched) && formContact.controls['phone'].invalid ? 'error-input': ''"
                placeholder=" {{'contact.p-phone' | translate}} ">
              <label class="error-text fw-regular">
                <span *ngIf="((formContact.controls['phone'].dirty || formContact.controls['phone'].touched) ) && formContact.get('phone')?.errors?.['required']">{{ "contact.obligatory" | translate }}</span>
                <span *ngIf="((formContact.controls['phone'].dirty || formContact.controls['phone'].touched) ) && formContact.get('phone')?.errors?.['pattern']">{{ "contact.error" | translate }}</span>
              </label>
            </div>

            <div >
              <label for="message" class="mb-2 fs-18 fw-regular text-label">{{ "contact.message" | translate }}</label><br>
              <textarea name="message" id="message" rows="3" class="w-100 form-control fw-regular"
                formControlName="message" placeholder="{{'contact.p-message' | translate}}"></textarea>
              <label class="error-text fw-regular"
             >
                <span    *ngIf="formContact.controls['message'].invalid">{{ "contact.max" | translate }}</span>
              </label>
            </div>


            <div class="captcha text-center mb-4">
              <p class="mb-0">{{ "contact.captcha-t" | translate }}</p>
              <a class="link" href="https://policies.google.com/privacy?hl=es" aria-label="ver politicas" 
                target="_blank">{{ "contact.captcha-p" | translate }}</a> -
              <a class="link" href="https://policies.google.com/terms?hl=es" aria-label="ver terminos" 
                target="_blank">{{ "contact.captcha-c" | translate }}</a>
            </div>

            <div class="d-flex justify-content-center">
              <button class="" [ngClass]="formContact.invalid ? 'btn-send-disabled' :'btn-bubbleWhite'" aria-label="Enviar formulario"
                (click)="send()">{{ "contact.btn-send" | translate }}</button>
            </div>
          </form>
        </div>
      </div>

      <div class="col-12 col-lg-6 padding-contact">
        <div class="contacto">
          <p class="fs-32 text-end fw-bold" style=" color: #132046; margin-bottom: 32px;">{{ "contact.title" | translate }}</p>
          <p class="fs-18 text-end"  style=" color: #132046;">{{ "contact.subtitle" | translate }}.</p>
        </div>
        <div class="img-contact">
          <div class="contact-row">
            <div class="contact-col move-left ">
              <div class="img-box box-lg">
                <img src="../../../assets/images/svg/contact/1/burbuja.svg" alt="buble-one" class="bubble bubble-one" />
                <img src="../../../assets/images/svg/contact/1/brazo1.svg" alt="arm-1" class="arm first-arm-one" />
                <img src="../../../assets/images/svg/contact/1/brazo2.svg" alt="arm-2" class="arm first-arm-two" />
              </div>
              <div class="img-box box-sm">
                <img src="../../../assets/images/svg/contact/2/burbuja.svg" alt="buble-two" class="bubble bubble-two" />
                <img src="../../../assets/images/svg/contact/2/brazo1.svg" alt="arm2" class="arm second-arm-one" />
                <img src="../../../assets/images/svg/contact/2/brazo2.svg" alt="arm-3" class="arm second-arm-two" />
              </div>
            </div>
            <div class="contact-col">
              <div class="img-box box-sm">
                <img src="../../../assets/images/svg/contact/3/burbuja.svg" alt="arm-3" class="bubble bubble-three" />
                <img src="../../../assets/images/svg/contact/3/brazo1.svg" alt="arm-3" class="arm third-arm-one" />
                <img src="../../../assets/images/svg/contact/3/brazo2.svg" alt="arm-3" class="arm third-arm-two" />
              </div>
              <div class="img-box box-lg">
                <img src="../../../assets/images/svg/contact/4/burbuja.svg" alt="arm-3" class="bubble bubble-four" />
                <img src="../../../assets/images/svg/contact/4/brazo1.svg" alt="arm-3" class="arm fourth-arm-one" />
              </div>
            </div>
          </div>
          <div class="contact-row move-left" style="justify-self: start;">
            <div class="img-box">
              <img src="../../../assets/images/svg/contact/silla/silla.svg" alt="arm-3" class="chair-img" />
              <img src="../../../assets/images/svg/contact/silla/brazo.svg" alt="arm-3" class="arm five-arm-one" />
              <img src="../../../assets/images/svg/contact/silla/cabeza.svg" alt="arm-3" class="arm five-arm-two" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Formulario -->
<div class="modal fade" id="modalForm" tabindex="-1" aria-labelledby="modalFormLabel" aria-hidden="false">
  <div class="d-flex align-items-center h-100">
    <div class="modal-dialog modal-body">
      <div class="modal-content">
        <div class="content text-center">
          <span class="icon-Success" style="font-size: 48px"><span class="path1"></span><span
              class="path2"></span></span>
          <p class="fs-28 fw-semi mb-4">{{ "contact.message-sent" | translate }}</p>
          <p class="fs-14">{{ "contact.data" | translate }}</p>
          <button class="btn-bubbleGray" [disabled] data-bs-dismiss="modal" aria-label="Cerrar modal"
            (click)="formComplete()">{{ "contact.btn" | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>